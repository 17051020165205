<span style="width: 100%">
  <mat-form-field *ngIf="!inputVal" style="width: 100%">
    <mat-select
    [disabled]="disabled"
      msInfiniteScroll
      (infiniteScroll)="fetchData()"
      [formControl]="ctrl"
      [placeholder]="searchPlaceHolder"
      (valueChange)="onChange($event)"
    >
      <mat-option>
        <ngx-mat-select-search
          [formControl]="selectSearchCtrl"
          placeholderLabel="search"
          [disableScrollToActiveOnOptionsChanged]="true"
        >
           <span ngxMatSelectNoEntriesFound *ngIf="!addNewOnNoEntriesFound">
             {{messageNoEntriesFound}}
           </span>
          <span ngxMatSelectNoEntriesFound *ngIf="addNewOnNoEntriesFound">
            <div class="add_customer_btn" (click)="createRecord()">
              <img
                src="../../../../../assets/img/add_customer_icon.svg"
                alt=""
                class="icon"
                width="20"
                height="20"
              />
              {{ addButtonLabel }}
            </div>
          </span>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of filteredResult" [value]="option">
        {{ option[labelKey] }}
      </mat-option>
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="form-row" *ngIf="inputVal">
    <div class="form-group col-12">
      <div class="input-group">
        <input
          type="text"
          [readonly]="true"
          class="form-control"
          [(ngModel)]="inputVal"
        />
        <div class="clear-icon" *ngIf="!disabled">
          <i class="fa fa-times" (click)="clearInput()"></i>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="hasError()" style="font-size: 0.875em; margin-top: -12px">
    <small class="text-danger"> This field is required </small>
  </div>
</span>
