import {Component, Input, OnInit} from '@angular/core';
import {SharedDataService} from "../shared.data.service";
import {first} from "rxjs/operators";
import {SharedService} from "../shared.service";

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

  @Input() fromGroup : any;
  @Input() field : any;
  @Input() placeholder : any;

  constructor(
    private sharedDataService: SharedDataService,
    public sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }

  onChangeState = (event: any) => {
    this.fromGroup.get(this.field).setValue(event.value);
  }
}
