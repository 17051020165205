<div class="popup-convert-proposal" [formGroup]="sectionForm">
  <div class="row m-auto popup-header-new align-items-center">
    <div class="col-md-9 align-items-center">
      <h4>Export To PDF</h4>

    </div>
    <div class="col-md-3 text-right align-items-center">
            <span class="cp close-icon" mat-dialog-close>
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32">
                  <g id="Group_5952" data-name="Group 5952" transform="translate(-0.001)">
                    <rect id="Rectangle_865" data-name="Rectangle 865" width="34" height="32" rx="1" transform="translate(0.001)" fill="#f82825"/>
                    <path id="Path_10" data-name="Path 10" d="M12.146,6.246a.685.685,0,0,1-.72.46c-1.52-.007-3.04,0-4.56,0-.162,0-.162,0-.162.163,0,1.52,0,3.04,0,4.56a.685.685,0,0,1-.46.72H5.914a.685.685,0,0,1-.46-.72c.007-1.52,0-3.04,0-4.56,0-.163,0-.164-.161-.164H.665a.632.632,0,0,1-.624-.4.622.622,0,0,1,.576-.842c.347-.005.695,0,1.042,0H5.295c.162,0,.163,0,.163-.162q0-2.321,0-4.643a.629.629,0,0,1,.4-.612A.622.622,0,0,1,6.7.618c.005.355,0,.711,0,1.066q0,1.806,0,3.612c0,.161,0,.161.164.161,1.52,0,3.04,0,4.56,0a.684.684,0,0,1,.72.46Z" transform="translate(8.612 16.2) rotate(-45)" fill="#f1f1f1" stroke="#f1f1f1" stroke-width="1.5"/>
                  </g>
                </svg>
              </span>
    </div>
  </div>

  <div class="row m-auto popup-body popup-convert-wrapper popup-revision-wrrapper popup-sent-wrapper align-items-center" >
    <div class="col-md-12">
      <h5>Templates</h5>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <!--          <label for="templates" class="custom_label">Primary Contact Name</label>-->
        <ng-select
          id="scopes"
          [items]="templates"
          placeholder="Select templates"
          [closeOnSelect]="true"
          [multiple]="true"
          formControlName="templates"
          bindLabel="template_name"
        >
        </ng-select>

        <app-validation-error [formGroup]="sectionForm" [controlName]="'templates'"></app-validation-error>
      </div>
    </div>

    <div class="d-none col-md-12 pdf-checbox-wrapper">
      <mat-checkbox class="example-margin">Customer Template 1</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Template 2</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Services Tempalte</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Milestone Tempaltes</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Tasks Tempalte</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Notes Tempalte</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Tickets Tempalte</mat-checkbox>
      <mat-checkbox class="example-margin">Customer Time Logos</mat-checkbox>
      <mat-checkbox class="example-margin">Customer ExpenseTempalte</mat-checkbox>
    </div>


    <div  class="col-md-6">
      <button  class="btn btn-convert" >

        <svg xmlns="http://www.w3.org/2000/svg" id="Group_6336" data-name="Group 6336" width="20.254" height="14.905" viewBox="0 0 20.254 14.905">
          <g id="Group_6335" data-name="Group 6335">
            <path id="Path_17711" data-name="Path 17711" d="M-1199.2,224.6V213.686c.024-.089.046-.178.072-.267a2.374,2.374,0,0,1,2.256-1.725q7.8,0,15.6,0a2.251,2.251,0,0,1,1.148.315,2.447,2.447,0,0,1,1.179,1.678V224.6c-.024.089-.046.178-.072.267a2.374,2.374,0,0,1-2.255,1.725q-7.8,0-15.6,0a2.251,2.251,0,0,1-1.148-.315A2.446,2.446,0,0,1-1199.2,224.6Zm10.117.38h7.437c.086,0,.172,0,.257,0a.8.8,0,0,0,.845-.849c0-.059,0-.119,0-.178q0-4.529,0-9.058c0-.04,0-.079,0-.119,0-.11-.025-.158-.131-.075-.036.028-.074.055-.111.083q-4.03,3-8.058,6a.32.32,0,0,1-.459,0q-3.691-2.759-7.391-5.5c-.243-.181-.486-.362-.731-.541-.045-.033-.089-.1-.15-.07s-.021.109-.028.166a.48.48,0,0,0,0,.059q0,4.539,0,9.078c0,.059,0,.119,0,.178a.8.8,0,0,0,.824.83c.079,0,.158,0,.237,0Zm7.535-11.661h-15.038c.076.061.12.1.167.134q3.563,2.65,7.123,5.3a.311.311,0,0,0,.446,0q2.945-2.2,5.9-4.395Z" transform="translate(1199.2 -211.693)" fill="#fff"/>
          </g>
        </svg>
        Send Email </button>
    </div>
    <div  class="col-md-6">
      <button  class="btn btn-convert" >

        <svg xmlns="http://www.w3.org/2000/svg" id="Group_6341" data-name="Group 6341" width="22.129" height="22.142" viewBox="0 0 22.129 22.142">
          <path id="Path_17712" data-name="Path 17712" d="M-631.5,684.147V671.663c.02-.091.042-.182.059-.274a3.508,3.508,0,0,1,3.45-2.818c2.649.028,5.3.005,7.947.009a3.453,3.453,0,0,1,3.409,3.2c.015.225.1.275.3.274,1.109-.007,2.217,0,3.326,0a3.824,3.824,0,0,1,1.456.261,3.505,3.505,0,0,1,2.176,3.332q0,3.553,0,7.105c0,.079,0,.159,0,.237a.906.906,0,0,1-.849.855.89.89,0,0,1-.912-.755,2.19,2.19,0,0,1-.024-.387c0-2.4,0-4.809-.006-7.213a1.665,1.665,0,0,0-1.637-1.648c-1.2-.01-2.39,0-3.585-.006-.178,0-.24.064-.239.24.006.792.008,1.584,0,2.376,0,.2.068.265.264.263.705-.008,1.411,0,2.116,0a1.644,1.644,0,0,1,.407.041.882.882,0,0,1,.632,1.07.891.891,0,0,1-.913.677c-.749,0-1.5.007-2.246,0-.2,0-.266.069-.26.265.012.36,0,.72,0,1.08,0,.32,0,.321.328.321.727,0,1.454-.007,2.181,0a.891.891,0,0,1,.891,1.171.914.914,0,0,1-.922.614c-.727.005-1.454,0-2.181,0-.288,0-.3.008-.3.287q0,.54,0,1.08c0,.287.007.294.288.294.749,0,1.5-.006,2.246,0a.885.885,0,0,1,.871,1.151.915.915,0,0,1-.953.631c-.857.006-1.713.008-2.57,0a.455.455,0,0,0-.434.239,3.365,3.365,0,0,1-2.745,1.594c-.877.047-1.756.017-2.634.026-.35,0-.4.067-.308.416a1.707,1.707,0,0,0,1.684,1.293c.741.007,1.483,0,2.224,0q3,0,6,0a1.7,1.7,0,0,0,1.788-1.663,1.231,1.231,0,0,1,.072-.358.884.884,0,0,1,.989-.555.888.888,0,0,1,.725.919,3.451,3.451,0,0,1-1.127,2.51,3.714,3.714,0,0,1-1.817.893h-9.417l-.313-.077a3.481,3.481,0,0,1-2.628-3.092c-.016-.229-.093-.293-.31-.291q-1.467.012-2.933,0A3.474,3.474,0,0,1-631,685.581,3.744,3.744,0,0,1-631.5,684.147Zm1.78-6.244c0,1.951,0,3.9,0,5.851a1.671,1.671,0,0,0,1.724,1.716q3.93,0,7.859,0a1.8,1.8,0,0,0,.51-.073,1.739,1.739,0,0,0,1.236-1.816q0-5.657,0-11.314a2.688,2.688,0,0,0-.062-.621,1.715,1.715,0,0,0-1.738-1.306c-2.562,0-5.125.011-7.687-.006a1.777,1.777,0,0,0-1.857,1.847C-629.695,674.088-629.721,676-629.72,677.9Z" transform="translate(631.5 -668.571)" fill="#fff"/>
          <path id="Path_17713" data-name="Path 17713" d="M-566.993,751.031c-1.021,0-2.043.006-3.064,0a.878.878,0,0,1-.848-1.177.838.838,0,0,1,.692-.577,2.448,2.448,0,0,1,.386-.027q2.816,0,5.632,0a2.508,2.508,0,0,1,.344.022.872.872,0,0,1,.78.925.874.874,0,0,1-.88.834C-564.965,751.036-565.979,751.031-566.993,751.031Z" transform="translate(574.446 -744.6)" fill="#fff"/>
          <path id="Path_17714" data-name="Path 17714" d="M-566.948,810.928c-1,0-2,0-3,0a.891.891,0,0,1-.914-.674.882.882,0,0,1,.627-1.072,1.636,1.636,0,0,1,.406-.042h5.761a1.425,1.425,0,0,1,.468.062.885.885,0,0,1,.586.97.887.887,0,0,1-.893.754c-.892.009-1.784,0-2.676,0Z" transform="translate(574.381 -801.042)" fill="#fff"/>
          <path id="Path_17715" data-name="Path 17715" d="M-568.736,869.057c.431,0,.863-.009,1.293,0a.892.892,0,0,1,.863.658.889.889,0,0,1-.361.966.973.973,0,0,1-.551.16c-.834,0-1.667.007-2.5,0a.908.908,0,0,1-.96-.9.912.912,0,0,1,.966-.89c.417,0,.834,0,1.25,0Z" transform="translate(574.44 -857.506)" fill="#fff"/>
        </svg>
        Save In Documents </button>
    </div>
    <div class="col-md-6">
      <button  class="btn btn-convert" (click)="downloadPdf()">
        <svg xmlns="http://www.w3.org/2000/svg" id="Group_6344" data-name="Group 6344" width="16.034" height="20.197" viewBox="0 0 16.034 20.197">
          <path id="Path_17716" data-name="Path 17716" d="M-1053.493,436.851h-12.06l-.133-.036a2.379,2.379,0,0,1-1.853-2.384q0-7.7,0-15.39a2.363,2.363,0,0,1,2.373-2.383q3.911,0,7.823,0a.513.513,0,0,1,.4.164q2.636,2.659,5.28,5.31a.5.5,0,0,1,.158.381q-.005,6,0,12a2.3,2.3,0,0,1-.387,1.282A2.45,2.45,0,0,1-1053.493,436.851Zm-12.437-10.088q0,3.782,0,7.565a.82.82,0,0,0,.934.931h10.953a.816.816,0,0,0,.926-.92q0-5.329,0-10.658c0-.247-.008-.254-.257-.254-.847,0-1.694,0-2.541,0a2.359,2.359,0,0,1-2.362-2.371c0-.808,0-1.615,0-2.423,0-.368,0-.369-.359-.369h-5.24c-.4,0-.8,0-1.2,0a.794.794,0,0,0-.851.815c0,.079,0,.158,0,.236Zm11.606-4.948-2.336-2.312c0,.521-.024,1.078.008,1.631a.7.7,0,0,0,.681.675C-1055.423,421.834-1054.873,421.815-1054.324,421.815Z" transform="translate(1067.539 -416.654)" fill="#fff"/>
          <path id="Path_17717" data-name="Path 17717" d="M-975.46,583.713a.444.444,0,0,0,.032-.241q0-2.245,0-4.49c0-.275,0-.276.268-.277h1c.335,0,.336,0,.336.327q0,2.216,0,4.431v.215c.089.021.114-.047.151-.084.364-.36.729-.72,1.085-1.087.109-.113.182-.108.288,0,.282.293.569.582.864.863.117.111.1.184-.007.288q-1.522,1.514-3.035,3.036c-.118.119-.192.106-.3-.006q-1.506-1.516-3.023-3.021c-.127-.126-.116-.2.006-.317q.421-.4.821-.822c.121-.128.2-.139.331,0C-976.259,582.924-975.865,583.308-975.46,583.713Z" transform="translate(982.601 -570.189)" fill="#fff"/>
        </svg>
        Download File </button>
    </div>
    <div  class="col-md-6">
      <button  class="btn btn-preview" (click)="previewPdf()">
        <svg xmlns="http://www.w3.org/2000/svg" id="Group_6346" data-name="Group 6346" width="21.368" height="17.881" viewBox="0 0 21.368 17.881">
          <path id="Path_17718" data-name="Path 17718" d="M-1441.5-130.657a2.865,2.865,0,0,1,.967-1.636,2.712,2.712,0,0,1,1.689-.618c.437-.012.875-.01,1.313,0a.925.925,0,0,1,.9.625.907.907,0,0,1-.284,1,1.029,1.029,0,0,1-.68.218c-.4,0-.806,0-1.208,0a.849.849,0,0,0-.888.894c0,.4,0,.806,0,1.209a.925.925,0,0,1-.709.975.922.922,0,0,1-1.053-.551c-.013-.025-.028-.048-.042-.072Z" transform="translate(1441.5 132.919)" fill="#fff"/>
          <path id="Path_17719" data-name="Path 17719" d="M-1441.5,100.129a.941.941,0,0,1,.887-.64.9.9,0,0,1,.914.887c.009.43,0,.861,0,1.292a.853.853,0,0,0,.908.916c.417,0,.834-.006,1.25,0a.912.912,0,0,1,.859.525.866.866,0,0,1-.095.943.83.83,0,0,1-.655.359,8.33,8.33,0,0,1-1.87-.042,2.639,2.639,0,0,1-2.146-2c-.019-.066-.038-.133-.057-.2Z" transform="translate(1441.5 -86.565)" fill="#fff"/>
          <path id="Path_17720" data-name="Path 17720" d="M-1370.536-69.945a7.475,7.475,0,0,1,5.444,2.355,6.455,6.455,0,0,1,1.551,2.8,1.051,1.051,0,0,1,0,.573,6.716,6.716,0,0,1-3.268,4.141,7.323,7.323,0,0,1-4.036.986,7.237,7.237,0,0,1-6.24-3.582,6.11,6.11,0,0,1-.64-1.556,1.035,1.035,0,0,1,0-.573,6.751,6.751,0,0,1,3.581-4.307A7.93,7.93,0,0,1-1370.536-69.945Zm-2.752,5.423a2.651,2.651,0,0,0,2.639,2.654,2.62,2.62,0,0,0,2.659-2.646,2.62,2.62,0,0,0-2.641-2.644A2.652,2.652,0,0,0-1373.288-64.522Z" transform="translate(1381.311 73.447)" fill="#fff"/>
          <path id="Path_17721" data-name="Path 17721" d="M-1141-129.6c0,.243,0,.486,0,.729a.885.885,0,0,1-.858.914.9.9,0,0,1-.956-.887c-.013-.43,0-.861,0-1.292a.852.852,0,0,0-.906-.916c-.417,0-.834.009-1.25,0a.915.915,0,0,1-.814-1.4.883.883,0,0,1,.784-.438,15.36,15.36,0,0,1,1.623.023,2.681,2.681,0,0,1,2.382,2.6C-1141-130.041-1141-129.819-1141-129.6Z" transform="translate(1162.365 132.897)" fill="#fff"/>
          <path id="Path_17722" data-name="Path 17722" d="M-1141.045,101.228a6.959,6.959,0,0,1-.043,1.04,2.706,2.706,0,0,1-2.345,2.139,13.785,13.785,0,0,1-1.6.023.927.927,0,0,1-.944-.917.938.938,0,0,1,.965-.918c.416-.01.833,0,1.25,0a.85.85,0,0,0,.9-.9c0-.431-.006-.861,0-1.292a.9.9,0,0,1,.942-.9.884.884,0,0,1,.873.92C-1141.044,100.667-1141.045,100.917-1141.045,101.228Z" transform="translate(1162.413 -86.573)" fill="#fff"/>
        </svg>
        Preview </button>
    </div>

  </div>
</div>

