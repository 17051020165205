import { Injectable } from '@angular/core';
import {SharedService} from "../../shared/shared.service"
import {ContactTypeDataService} from "./contact-type.data.service";
import {first} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ContactTypeService {

  public scopes: any;

  constructor(
    public contactTypeDataService: ContactTypeDataService,
  ) {}

  getContactTypes = () => {
    return this.contactTypeDataService.list();
  }

}
