import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CustomerComponentsCommunicationServiceService {
  private customerJobSiteValue = new Subject<any>();

  constructor() { }

  setCustomerJobSiteValue(data: any) {
    this.customerJobSiteValue.next(data);
  }

  getCustomerJobSiteValue(): Observable<any> {
    return this.customerJobSiteValue.asObservable();
  }
}
