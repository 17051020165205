import { Directive ,HostListener} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][phoneMask]'
})
export class PhoneMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event:any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event:any) {
    this.onInputChange(event.target.value, true);
  }


  @HostListener('keypress', ['$event'])
  keypress(event:any) {
    const k = event.keyCode || event.which;
    const flag = (k >= 48 && k <= 57) ; // Allow numbers, backspace, and space || k == 8 || k == 32
    // if (flag){
    //   this.onInputChange(event, false);
    // }
    return flag;
  }

  onInputChange(event: any, backspace: any) {
    let newVal = event.replace(/\D/g, '');
    console.log('newVal', newVal);
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) - $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) - $2 - $3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) - $2 - $3');
    }
    this.ngControl?.valueAccessor?.writeValue(newVal);
    this.ngControl?.control?.patchValue(newVal, {emitModelToViewChange: false});

    // this.ngControl?.control?.updateValueAndValidity();
  }


}
