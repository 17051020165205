import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setItem(key: any, data: any){
    localStorage.setItem(key, data);
  }

  setStringifyItem(key: any, data: any) {
    this.setItem(key, JSON.stringify(data));
  }

  getItem(key: any) {
    return localStorage.getItem(key);
  }

  getStringifyItem(key: any) {
   //return JSON.parse(this.getItem(key));
  }

  hasKey(key: any) {
    if (this.getItem(key) === null) {
      return false;
    }

    return true;
  }

  removeItem(key: any) {
    localStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }

  }
