import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from "@angular/forms";


@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBoxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectBoxComponent),
      multi: true
    }
  ]
})
export class SelectBoxComponent implements OnInit, OnChanges {

  @Input() name: any;
  @Input() model: any = {};
  @Input() items: any[] = [];
  @Input() selectedItem: any = null;
  @Input() placeholder = 'Select';
  @Input() multiple = false;
  @Input() maxSelectedItems = 1000;
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() isRequired: boolean = false;

  @Output() callback = new EventEmitter<any>(true);
  public selectBoxForm: UntypedFormGroup = new UntypedFormGroup({});
  public selectedItems:any;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedItem = changes?.selectedItem?.currentValue;
  }

  ngOnInit(): void {
    this.selectBoxForm.addControl(this.name, new UntypedFormControl((this.selectedItem) ? this.selectedItem : null, [Validators.required]));
    this.selectBoxForm.addControl(`${this.name}_selected`, new UntypedFormControl((this.selectedItem) ? this.selectedItem : null));
    this.updateFormValidatorOpts();
  }

  onChangeItem = (item:any) => {
    this.selectedItems = item;
    this.selectBoxForm.controls[`${this.name}_selected`].setValue(item);
  }

  updateFormValidatorOpts = () => {
    if (this.isRequired) {
      this.selectBoxForm.controls[this.name].setValidators([Validators.required]);
    } else {
      this.selectBoxForm.controls[this.name].clearValidators();
    }
    this.selectBoxForm.controls[this.name].updateValueAndValidity();
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.selectBoxForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.selectBoxForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.selectBoxForm.disable() : this.selectBoxForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.selectBoxForm.valid ? null : { invalidForm: {valid: false, message: "Select Box are invalid"}};
  }

}
