<div class="list-group-item">
  <div class="no_padding_right col-12">
    <div class="vendorContainer">
      <div class="iconContainer col-1">
        <app-icon [icon]="icon" [isFontAwesome]="isFontAwesome"></app-icon>
      </div>
      <div class="iconContainer pad-left col-11">
        <div class="text-muted fw-600">
          <h5 class="marginZero detail-list-heading">{{heading}}</h5>
        </div>
        <div class="text-overline">{{title}}</div>
      </div>
    </div>
  </div>
</div>
