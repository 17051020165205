import { Injectable } from '@angular/core';
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ConfirmationPopupComponent} from "../../modules/shared/confirmation-popup/confirmation-popup.component";
import {MatDialogRef} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})

export class DialogService {

  private dialogRef:any;

  /*private config: any = {
    width: '500px',
    animation: {
      to: 'left',
      incomingOptions: {
        keyframeAnimationOptions: { duration: 700, easing: 'steps(100, end)' }
      }
    },
    position: { rowStart: '0' }
  };*/

  public config: any = {
    width: '900px',
    data: {},
    animation: {
      to: 'left',
      incomingOptions: {
        keyframeAnimationOptions: { duration: 700, easing: 'steps(100, end)' }
      }
    },
    position: { rowStart: '0' }
  };

  constructor(
    public dialog: NgDialogAnimationService,
  ) { }

  getDialogConfigs = () => {
    return this.config;
  }

  public onOpenMediumDialog = (component:any, data:any = {}) => {
    this.onOpenFromRightDialog(component, data, 700);
  }

  public onOpenFromRightDialog = (component:any, data:any = {}, width:number = 900) => {
     this.dialogRef = this.dialog.open(component, {
       disableClose: true,
       width: `${width}px`,
       height: '100%',
       data: data,
       autoFocus: false,
       animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { duration: 700, easing: 'steps(100, end)' }
        }
      },
      position: { rowStart: '0' }
    });

    /* const sub = dialogRef?.componentInstance?.onAdd.subscribe((data: any) => {
      //this.getData();
      //this.closeDialog();
    });*/

    /*this.dialogRef?.afterClosed().subscribe((result: any) => {
      console.log(result, 'Inside Here');
     // sub.unsubscribe();
    });*/
    return this.dialogRef;
  }

  public onOpenPopUpDialog = (component: any, data: any = {}, width: number = 950 ,height: number = 900) => {
    this.dialogRef = this.dialog.open(component, {
      disableClose: true,
      width: `${width}px`,
      height: `${height}px`,
      data: data,
      autoFocus: false,
      position: { top: '4%', left: '30%' } // Center the dialog
    });
  };

  public onOpenStaffTerminatePopUpDialog = (component: any, data: any = {}, width: number = 700 ,height: number = 450) => {
    this.dialogRef = this.dialog.open(component, {
      disableClose: true,
      width: `${width}px`,
      height: `${height}px`,
      data: data,
      autoFocus: false,
      position: { top: '15%', left: '30%' } // Center the dialog
    });
  };

  public onOpenStaffChangePassPopUpDialog = (component: any, data: any = {}, width: number = 700 ,height: number = 580) => {
    this.dialogRef = this.dialog.open(component, {
      disableClose: true,
      width: `${width}px`,
      height: `${height}px`,
      data: data,
      autoFocus: false,
      position: { top: '15%', left: '30%' } // Center the dialog
    });
  };

  public onOpenDialog = (component:any, data:any = {}, width:number = 900) => {
    this.dialogRef =  this.dialog.open(component, {
      disableClose: true,
      width: `${width}px`,
      maxHeight: '98vh',
      autoFocus: false,
      data: data,
      position: { rowStart: '0' },
    });

    /* const sub = dialogRef?.componentInstance?.onAdd.subscribe((data: any) => {
      //this.getData();
      //this.closeDialog();
    });*/

    /*this.dialogRef?.afterClosed().subscribe((result: any) => {
      console.log(result, 'Inside Here');
     // sub.unsubscribe();
    });*/
  }

  onClose = (data:any) => {
    this.dialogRef?.close(data);
  }
  getComponentInstance = () => {
    return this.dialogRef?.componentInstance;
  }

  afterClose = () => {
    return this.dialogRef?.afterClosed();
  }

  onConfirmation(): void {
    this.dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '300px',
      disableClose: true,
      data: {}
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if(result) {
       // this.onDelete();
      }
    });*/
  }
}
