import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import {HashService} from './hash.service';
import {JsonService} from "./json.service";

export enum UserConst {
  TOKEN = '_token',
  IS_LOGGED_IN = '_isLoggedIn',
  USER_ID = '_useId',
  TENANT_ID = '_tenantId',
  FIRST_NAME = '_firstName',
  LAST_NAME = '_lastName',
  EMAIL = '_email',
  STATUS = '_status',
  ROLE = '_role',
  FULL_NAME = '_fullName',

}
@Injectable({
  providedIn: 'root'
})

export class UserService {

  private salt = '123EWORIUE9347(#|#4#+_%4408#8972489;AWLRKGA;DFLGDSFEWERQWERQWER456$#@$^@1ERF';
  constructor(
    private localStorageService: LocalStorageService,
    private hashService: HashService,
    private jsonService: JsonService,
  ) {}

  setID = (data: any) => {
    this.localStorageService.setItem(UserConst.USER_ID, data);
  }

  getID = () => {
    return this.localStorageService.getItem(UserConst.USER_ID);
  }

  setTenantID = (data: any) => {
    this.localStorageService.setItem(UserConst.TENANT_ID, data);
  }

  getTenantID = () => {
    return this.localStorageService.getItem(UserConst.TENANT_ID);
  }

  setFirstName = (data: any) => {
    this.localStorageService.setItem(UserConst.FIRST_NAME, data);
  }

  getFirstName = () => {
    return this.localStorageService.getItem(UserConst.FIRST_NAME);
  }

  setLastName = (data: any) => {
    this.localStorageService.setItem(UserConst.LAST_NAME, data);
  }

  getLastName = () => {
    return this.localStorageService.getItem(UserConst.LAST_NAME);
  }

  getFullName = () => {
    return `${this.localStorageService.getItem(UserConst.FIRST_NAME)} ${this.localStorageService.getItem(UserConst.LAST_NAME)}`;
  }

  setEmail = (data: any) => {
    this.localStorageService.setItem(UserConst.EMAIL, data);
  }

  getEmail = () => {
    return this.localStorageService.getItem(UserConst.EMAIL);
  }

  setStatus = (data: any) => {
    this.localStorageService.setItem(UserConst.STATUS, data);
  }

  getStatus = () => {
    return this.localStorageService.getItem(UserConst.STATUS);
  }

  setRole = (data: any) => {
    this.localStorageService.setItem(UserConst.ROLE, data);
  }

  getRole = () => {
    return this.localStorageService.getItem(UserConst.ROLE);
  }

  setToken = (token: any) => {
    this.localStorageService.setItem(UserConst.TOKEN, token);
  }
  getToken = () => {
    return this.localStorageService.getItem(UserConst.TOKEN);
  }

  setIsLoggedIn = (data: any) => {
    this.localStorageService.setItem(UserConst.IS_LOGGED_IN, data);
  }

  getIsLoggedIn = () => {
    return this.localStorageService.getItem(UserConst.IS_LOGGED_IN);
  }

  isLoggedIn() {
    if ((this.localStorageService.hasKey(UserConst.IS_LOGGED_IN)) && (this.localStorageService.getItem(UserConst.IS_LOGGED_IN) === 'true') ) {
      return true;
    }
    return false;
  }

  getNameIntial = () => {
    return 'C';
  }

  flushAll = () => {
    this.localStorageService.clearAll();
  }

  setAll = (user: any) => {
    this.setIsLoggedIn(true);
    this.setToken(user.token);
    this.setID(user.id);
    this.setTenantID(user.tenant_id);
    this.setEmail(user.email);
    this.setFirstName(user.first_name);
    this.setLastName(user.last_name);
    this.setStatus(user.status);
    this.setRole(user.role);
  }

}
