import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const JobApiResources = {
  list:  createUrl('jobs'),
  create:  createUrl('jobs/create'),
  assingJob:  createUrl('jobs/assign/people'),
  assingPeople:createUrl('jobs/assign/people'),
  removeFromJob:  createUrl('jobs/remove/people'),
  statusList:  createUrl('jobs/statuses'),
  taskTypeList:  createUrl('task-types'),
  changeStatus:  createUrl('jobs/change/status'),
  detail:  createUrl('jobs'),
  update:  createUrl('jobs'),
  delete:  createUrl('jobs'),

};
