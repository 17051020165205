<div class="container">
  <div class="row">
    <div class="form-group w-100">
      <div class="custom-file">
        <!--id = 'customFile' if face an erro please add this id to input field-->
        <input
          type="file"
          class="custom-file-input"
          id="{{name}}"
          [multiple] = "isMultiple"
          name="image"
          (change)="fileProgress($event)"
        />
        <label class="custom-file-label lab-height" for="{{name}}">Choose file</label>
      </div>
    </div>
    <div *ngIf="fileUploadProgress">
      Upload progress: {{ fileUploadProgress }}
    </div>

    <div class="image-preview mb-3" *ngIf="previewUrl && isPreviewShow" >
      <div
        *ngFor='let url of previewUrl;  let i = index'
        style="display: inline-block" class="imageHolder">

        <a
          href="javascript:void(0);"
          (click)="remove(i)"
          class="up-img-cross"
        >
          <mat-icon>cancel</mat-icon>
        </a>
        <!--<img class="up-img" *ngIf="url.type==='video'" alt="" src="assets/images/videoIcon.png">
        <img *ngIf="url.type==='image'" [src]="url.path" height="50" class="up-img">
        <img class="abc" *ngIf="url.type==='csv'" alt="" src="assets/images/csv-file.png">
        <img class="abc" *ngIf="url.type==='word'" alt="" src="assets/images/word-file.png">
        <img class="abc" *ngIf="url.type==='pdf'" alt="" src="assets/images/pdf.png">
        <img class="abc" *ngIf="url.type==='ppt'" alt="" src="assets/images/ppt.png">-->

        <mat-icon *ngIf="url.type===fileService.FILE_TYPE_IMG">image</mat-icon>
        <mat-icon *ngIf="url.type===fileService.FILE_TYPE_PDF">picture_as_pdf</mat-icon>

        <span style="padding-left: 10px;" *ngIf="isShowFileName">{{orignalFileName}}</span>
        <span
          *ngIf="url.type!=='image'
            && url.type!=='csv'
            && url.type!=='word'
            && url.type!=='pdf'
            && url.type!=='ppt'
            && url.type!=='video'"
        > {{url.type}} </span>
      </div>
    </div>

    <div class="mb-3" *ngIf="uploadedFilePath">
      {{uploadedFilePath}}
    </div>
  </div>
</div>
