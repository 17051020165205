import {Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { DialogService } from 'src/app/services/core/dialog.service';
import { ToasterService } from 'src/app/services/core/toaster.service';
import { SharedDataService } from '../../shared/shared.data.service';
import { SharedService } from '../../shared/shared.service';
import { CustomerContactDataService } from '../customer.contact.data.service';
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-create-edit-customer-contact',
  templateUrl: './create-edit-customer-contact.component.html',
  styleUrls: ['./create-edit-customer-contact.component.scss']
})
export class CreateEditCustomerContactComponent implements OnInit {

  @Input() isEdit : boolean = false;
  @Output() onAdd = new EventEmitter<any>(true);

  public submitted = false;
  public model:any = {};
  public customerForm!: UntypedFormGroup;
  public customerDetail: any = '';
  public step:any;
  markScrollAbleSearchSelectTouched = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public fb: UntypedFormBuilder,
    public customerContactDataService: CustomerContactDataService,
    public toasterService: ToasterService,
    public sharedDataService: SharedDataService,
    public sharedService: SharedService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.model = this.data?.model;
    this.isEdit = this.data?.isEdit;
    this.customerDetail = this.data?.customerDetail;
    // this.model?.customer?.id
  }

  ngOnInit() {
    this.customerForm = new UntypedFormGroup({
      step01: new UntypedFormGroup({
        contact: new UntypedFormControl(null)
      }),
      step02: new UntypedFormGroup({
        preview: new UntypedFormControl(null)
      }),
    });
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  onStepChanged = (event:any, stepper:any) => {
    this.step = {event:event, stepper:stepper};
  }

  onSubmit() {
    if (this.customerForm.invalid) {
      return;
    }
    const params = this.getFormData();
    console.log(params);
    if (this.isEdit) {
      delete params.password;
      this.onUpdate(params);
      return;
    }
    this.onSave(params);
  }
  changedHandler = (event:any) => {
    /*this.model = event?.model;
    this.isEdit = event?.isEdit;*/
  }

  close = (contact:any = null) => {
    this.dialogService.onClose(contact);
  }

  onSave = (params:any) => {
    this.customerContactDataService.create(params)
      .pipe(first())
      .subscribe(
        data => {
          this.toasterService.showSuccessMessage(data?.data.message);
          this.close(data?.data?.contact);
        });
  }

  onUpdate = (params:any) => {
    this.customerContactDataService.update(this.model?.id, params)
      .pipe(first())
      .subscribe(
        data => {
          this.toasterService.showSuccessMessage(data?.data.message);
          this.close();
        });
  }
  initChildForm(setp: string, formControlName: string, value:any){
    this.customerForm.get(setp)?.get(formControlName)?.setValue(value);
  }
  getFormData = () => {
    return {
      customer_id: this.customerForm?.value?.step01?.contact?.customer?.id,
      first_name: this.customerForm?.value?.step01?.contact?.first_name,
      last_name: this.customerForm?.value?.step01?.contact?.last_name,
      email: this.customerForm?.value?.step01?.contact?.email,
      phone: this.customerForm?.value?.step01?.contact?.phone,
      mobile: this.customerForm?.value?.step01?.contact?.mobile,
      contact_authority: this.customerForm?.value?.step01?.contact?.contact_authority,
      username: this.customerForm?.value?.step01?.contact?.username,
      password: this.customerForm?.value?.step01?.contact?.password,
      notes: this.customerForm?.value?.step01?.contact?.notes,
      has_portal_access: this.customerForm?.value?.step01?.contact?.has_portal_access,
      is_primary: this.customerForm?.value?.step01?.contact?.is_primary,
      status:1
    }
  }

  touchFormControls(formControlName: string, secondFormControlName: any = null){
    this.markScrollAbleSearchSelectTouched = true;
    let customerForm = this.customerForm.get(formControlName);
    console.log('customerForm', customerForm);
    // @ts-ignore
    customerForm = secondFormControlName ? customerForm?.get(secondFormControlName) : customerForm;
    customerForm?.markAsTouched()
    customerForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
