import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendApiService} from "../../../../services/backend-api.service";
import {StaffService} from "../../../../services/staff/staff.service";

@Component({
  selector: 'app-ticket-user-popup',
  templateUrl: './ticket-user-popup.component.html',
  styleUrls: ['./ticket-user-popup.component.scss']
})
export class TicketUserPopupComponent implements OnInit {
  public type: any;
  public index: any;
  usersList: any = [];
  vendorsList: any = [];
  customersList: any = [];
  subcontractorsList: any = [];
  attachAbleUser: any = ['Users','Vendors','Customers','Subcontractors'];
  selectedIndex: any = 0;

  TYPE_USER: any = 'Users';
  TYPE_VENDORS: any = 'Vendors';
  TYPE_CUSTOMERS: any = 'Customers';
  TYPE_SUBCONTRACTORS: any = 'Subcontractors';

  constructor(
    public dialogRef: MatDialogRef<TicketUserPopupComponent>,
    public backendApiService: BackendApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public staffService: StaffService,
  ) {
    this.type = this.data?.type;
    this.index = this.data?.index
    if (this.data?.attachAbleUser){
      this.attachAbleUser = this.data?.attachAbleUser;
    }
  }

  ngOnInit(): void {

  }

  getUsersList(event: any) {

    this.backendApiService.getApi(`staff/search?search=${event?.target?.value}`)
      .subscribe((response: any) => {
        this.usersList = this.staffService.processStaff(response.data);
      })
  }

  getVendorsList(event: any) {
    this.backendApiService.postApi(`vendors/search?search=${event?.target?.value}`,'')
      .subscribe((response: any) => {
        this.vendorsList = response.data;
      })
  }

  getCustomersList(event: any) {
    this.backendApiService.postApi(`customers/search?search=${event?.target?.value}`,'')
      .subscribe((response: any) => {
        this.customersList = response.data;
      })
  }

  getSubcontractorsList(event: any) {
    this.backendApiService.postApi(`subcontractors/search?search=${event?.target?.value}`,'')
      .subscribe((response: any) => {
        this.subcontractorsList = response.data;
      })
  }


  close(data: any = '') {
    this.dialogRef.close(data);
  }

  getActiveTab(event: any){
    this.selectedIndex = event;
  }

  getTabNameUsingTabIndex(index: any) {
    let value = '';
    switch (index) {
      case 0:
        value = this.TYPE_USER;
        break;
      case 1:
        value = this.TYPE_VENDORS;
        break;
      case 2:
        value = this.TYPE_CUSTOMERS
        break;
      case 3:
        value = this.TYPE_SUBCONTRACTORS;
        break;
      default:
    }
    return value;
  }

  checkAttachAbleUser(type: string){
    return this.attachAbleUser.includes(type);
  }

}
