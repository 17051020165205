import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const CustomerApiResources = {
  list:  createUrl('customers'),
  search:  createUrl('customers/search'),
  create:  createUrl('customers/create'),
  detail:  createUrl('customers'),
  update:  createUrl('customers'),
  delete:  createUrl('customers'),
  onhold:  createUrl('customers/onhold'),
  changeStatus:  createUrl('customers/change-status'),
  import:  createUrl('customers/import'),
};
