import { AbstractControl, ValidatorFn } from '@angular/forms';

export function PhoneNumberValidator(): ValidatorFn {
  const phoneNumberPattern = /^\(\d{3}\)\s*-\s*\d{3}\s*-\s*\d{4}$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    // Return null if the field is empty
    if (!value) {
      return null;
    }

    // Check if input matches the phone number pattern
    const isValid = phoneNumberPattern.test(value);
    return isValid ? null : { 'invalidPhoneNumber': true };
  };
}
