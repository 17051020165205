import {environment} from '../../../environments/environment';

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const TenantApiResources = {
  list:  createUrl('tenants'),
  search:  createUrl('tenants/'),
  create:  createUrl('tenants/create'),
  detail:  createUrl('tenants'),
  update:  createUrl('tenants'),
  staff:  createUrl('staff/search'),
};
