import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator, Validators
} from "@angular/forms";
import {pairwise, startWith} from "rxjs/operators";
import {Subscription} from "rxjs";
import { NotOnlySpaces } from '../../../shared/CustomValidators/CustomNotOnlySpacesValidator';

@Component({
  selector: 'app-company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyNameComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyNameComponent),
      multi: true
    }
  ]
})
export class CompanyNameComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() model: any = {};
  @Input() form: any = {};
  @Output() callback = new EventEmitter<any>(true);
  @Output() onInitForm = new EventEmitter<any>(true);

  public onModelValueChanged:any = {};
  //@ts-ignore
  public companyFormSubscription: Subscription;

  public companyNameForm: UntypedFormGroup = this.initForm();

  constructor(
    private el: ElementRef
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
    this.onModelValueChanged = this.model.model;

  }

  ngOnInit(): void {
    this.initSubscriptions();
    this.companyNameForm = this.initForm(this.onModelValueChanged);
    this.onInitForm.emit(this.companyNameForm);
  }
  preventSpace(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.trim() === '' && event.key === ' ') {
      event.preventDefault();
    }
  }
  initForm(value: any = ''){
   return  new UntypedFormGroup({
      name: new UntypedFormControl(value? value?.company_name : '',[Validators.required,NotOnlySpaces()])
    });
  }
  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.companyNameForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.companyNameForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.companyNameForm.disable() : this.companyNameForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.companyNameForm.valid ? null : { invalidForm: {valid: false, message: "Company name fields are invalid"}};
  }

  initSubscriptions(){
    if (this.form){
      this.companyFormSubscription = this.form.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.form.touched){
            this.touchFormControls();
          }
        });
    }

  }
  touchFormControls(){
    Object.keys(this.companyNameForm.controls).forEach(key => {
      this.companyNameForm.get(key)?.markAsTouched();
    });
  }

  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.companyFormSubscription?.unsubscribe();
  }
}
