import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService {

  private scrumBoardData = new Subject<any>();
  private searchInput = new Subject<string>();

  constructor() { }

  setScrumBoardData(data: any) {
    this.scrumBoardData.next(data);
  }

  getScrumBoardData(): Observable<any> {
    return this.scrumBoardData.asObservable();
  }

  setSearchInput(data: any) {
    this.searchInput.next(data);
  }

  getSearchInput(): Observable<any> {
    return this.searchInput.asObservable();
  }
}
