import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProposalComponentsCommunicationService } from '../../sale/proposal/services/proposal-components-communication.service';
import { ChangeOrderDataService } from '../../sale/change-order/change-order.data.service';
import { BackendApiService } from '../../../../app/services/backend-api.service';
import { HelperService } from '../../../../app/services/helper.service';
import { ToasterService } from '../../../../app/services/core/toaster.service';

@Component({
  selector: 'app-pdf-display-option',
  templateUrl: './pdf-display-option.component.html',
  styleUrls: ['./pdf-display-option.component.scss'],
})
export class PDFDisplayOptionsComponent implements OnInit {
  @Input() changeOrderID: string = '';
  @Input() changeOrderSettings: any = '';
  @Input() model: any = '';
  @Input() statusChange: any = '';
  @Output() onchangeOrderStatusChange = new EventEmitter<any>(true);
  @Output() onChangeDisplayOptions = new EventEmitter<any>(true);
  // @ts-ignore
  statusSubscription: Subscription;
  public changeOrderScopeOptionsForm: UntypedFormGroup = this.getForm();
  constructor(
    private proposalComponentsCommunicationService: ProposalComponentsCommunicationService,
    private changeOrderDataService: ChangeOrderDataService,
    public backendApiService: BackendApiService,
    private helperService: HelperService,
    public toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    console.log('this.proposalSettings', this.statusChange);
    this.changeOrderScopeOptionsForm
      .get('proposal_status')
      ?.setValue(this.statusChange);
    this.initFormValues();
    this.initValuesSubscription();
  }
  initFormValues() {
    this.changeOrderScopeOptionsForm = this.getForm(this.model);
    (async () => {
      await this.helperService.delay(4000);
      this.onChangeDisplayOptionsFn('', false);
    })();
  }

  getForm(proposalData: any = '') {
    const displayOptions = proposalData?.display_options;
    return new UntypedFormGroup({
      proposal_status: new UntypedFormControl(proposalData.status?.id),
      displayOptions: new UntypedFormGroup({
        product: new UntypedFormControl(displayOptions?.product ? true : false),
        service: new UntypedFormControl(displayOptions?.service ? true : false),
        labour: new UntypedFormControl(displayOptions?.labour ? true : false),
        shipping: new UntypedFormControl(
          displayOptions?.shipping ? true : false
        ),
        unitSellingPrice: new UntypedFormControl(
          displayOptions?.unitSellingPrice ? true : false
        ),
        signature: new UntypedFormControl(
          displayOptions?.signature ? true : false
        ),
        totalPrice: new UntypedFormControl(
          displayOptions?.totalPrice ? true : false
        ),
        providedServices: new UntypedFormControl(
          displayOptions?.providedServices ? true : false
        ),
        tax: new UntypedFormControl(displayOptions?.tax ? true : false),
        qualifyingNotes: new UntypedFormControl(
          displayOptions?.qualifyingNotes ? true : false
        ),
        scopeNotes: new UntypedFormControl(
          displayOptions?.scopeNotes ? true : false
        ),
      }),
    });
  }

  proposalStatusUpdate() {
    this.onchangeOrderStatusChange.emit(
      this.changeOrderScopeOptionsForm.get('proposal_status')?.value
    );
    this.updateProposalNotes();
  }

  updateProposalNotes() {
    const data = {
      status: this.changeOrderScopeOptionsForm.get('proposal_status')?.value,
    };
    this.backendApiService
      .postApi('proposals/options/' + this.changeOrderID, data, false)
      .subscribe((response: any) => {});
  }
  initValuesSubscription() {
    // @ts-ignore
    this.statusSubscription = this.proposalComponentsCommunicationService
      .getProposalDetailStateChange()
      .subscribe((data) => {
        this.changeOrderScopeOptionsForm
          .get('proposal_status')
          ?.setValue(data?.id);
        this.proposalStatusUpdate();
      });
  }

  onChangeDisplayOptionsFn(event: any = '', updateOnBackEnd = true) {
    const displayOptions = this.changeOrderScopeOptionsForm
      .get('displayOptions')
      ?.getRawValue();
    this.proposalComponentsCommunicationService.setDisplayOptions(
      displayOptions
    );
    if (updateOnBackEnd) {
      this.updateDisplayOptions();
    }
  }

  updateDisplayOptions() {
    const data = this.changeOrderScopeOptionsForm
      .get('displayOptions')
      ?.getRawValue();
    this.changeOrderDataService
      .updateDisplayOptions(this.changeOrderID, data)
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response.data?.message);
      });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    // @ts-ignore
    this.statusSubscription.unsubscribe();
  }
}
