import { Injectable } from '@angular/core';
import {VendorGroupDataService} from "./vendor.group.data.service";

@Injectable({
  providedIn: 'root'
})
export class VendorGroupService {

  public groups: any;

  constructor(
    public vendorGroupDataService: VendorGroupDataService,
  ) {}


  getGroups = () => {
    return this.vendorGroupDataService.list();
  }

}
