<div class="card dt-pro-main m-b-30 mt-4" *ngIf="isShowTemplate">
    <div class="card-body">
        <div class="row row-mrg">
            <div class="col-md-2 no_padding_right">
                <img src="../../../../assets/img/logo.jpg" class="logo">
            </div>
            <div class="col-md-7">

            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-12 zero-padd m-b-5"><span class="new-bid">New Bid</span></div>
                    <div class="col-md-6 top-right-box-border no-right-border top-right-box-bg">
                        <p class="top-right-box-text">PRO#</p>
                    </div>
                    <div class="col-md-6 top-right-box-border top-right-box-bg">
                        <p class="top-right-box-text"></p>
                    </div>
                    <div class="col-md-12 top-right-box-border no-top-border top-right-box-bg">
                        <p class="top-right-box-text">PROPOSAL</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-mrg mt-060">
            <div class="col-md-5 line-container zero-padd">
                <hr class="line">
                <div class="line-bottom-container">
                    <strong>To:</strong>
                    <strong class="line-bottom-label-1">DUMMY CC</strong>
                    <p class="line-bottom-text">123 PRIVATE RD ST 123 TOMS RVER,NJ 123 </p>
                    <strong class="line-bottom-label-2">ATT: JACK</strong>
                </div>
            </div>
            <div class="col-md-2 zero-padd">
                <img src="../../../../assets/img/center-logo.jpg" class="center-logo">
            </div>
            <div class="col-md-5 line-container zero-padd">
                <span class="date f-b">05-03-2021</span>
                <hr class="line">
                <div class="line-bottom-container">
                    <strong>To:</strong>
                    <strong class="line-bottom-label-1">Nexus Properties Development, LLC</strong>
                    <p class="line-bottom-text"></p>
                </div>
            </div>
        </div>
        <div class="row row-mrg">
            <div class="col-md-6 mt-10 top-right-box-border no-right-border m-t-30">
                <p class="m-b-0 text-left f-b padd-5">SALES REP: VINCE@ 10-SPEC.COM</p>
            </div>
            <div class="col-md-6 mt-10 top-right-box-border m-t-30">
                <p class="m-b-0 text-right f-b padd-5" style="text-align: right;">SALES REP: </p>
            </div>
        </div>
        <div class="row row-mrg">
            <div class="col-md-12 m-t-20">
                <h4 class="f-b m-b-0 text-under">Summary:</h4>
            </div>
        </div>
        <div class="row m-t-20 m-l-30">
            <div class="col-md-1 m-t-5">
                <span>1</span>
            </div>
            <div class="col-md-3 m-t-5">
                <span>Product 003</span>
            </div>
            <div class="col-md-4 m-t-5">
                <span>Product</span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b"> $17,261.50 </span>
            </div>
            <div class="col-md-1 m-t-5">
                <span>1</span>
            </div>
            <div class="col-md-3 m-t-5">
                <span>Product 002</span>
            </div>
            <div class="col-md-4 m-t-5">
                <span>Product</span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b"> $110.00 </span>
            </div>
            <div class="col-md-1 m-t-5"></div>
            <div class="col-md-3 m-t-5"></div>
            <div class="col-md-4 m-t-5 no_padding_left control-overflow">
                <span>TAX <span class="f-b">................................................................</span>
                </span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b">$0.00</span>
            </div>
            <div class="col-md-1 m-t-5"></div>
            <div class="col-md-3 m-t-5"></div>
            <div class="col-md-4 m-t-10 no_padding_left control-overflow">
                <span class="f-b">Furnished and Installed ................................................................</span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b"></span>
            </div>
            <div class="col-md-1 m-t-5"></div>
        </div>
        <div class="row row-mrg">
            <div class="col-md-12 m-t-20">
                <h4 class="f-b m-b-0 text-under">Scope 2:</h4>
            </div>
        </div>
        <div class="row m-t-20 m-l-30">
            <div class="col-md-1 m-t-5">
                <span>1</span>
            </div>
            <div class="col-md-3 m-t-5">
                <span>Product 002</span>
            </div>
            <div class="col-md-4 m-t-5">
                <span>Product</span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b"> $6.60 </span>
            </div>
            <div class="col-md-1 m-t-5">
                <span>1</span>
            </div>
            <div class="col-md-3 m-t-5">
                <span>Product 002</span>
            </div>
            <div class="col-md-4 m-t-5">
                <span>Product</span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b"> $22.00 </span>
            </div>
            <div class="col-md-1 m-t-5"></div>
            <div class="col-md-3 m-t-5">

            </div>
            <div class="col-md-4 m-t-5 no_padding_left control-overflow">
                <span>TAX <span class="f-b">................................................................</span>
                </span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b">$0.00</span>
            </div>
            <div class="col-md-1 m-t-5"></div>
            <div class="col-md-3 m-t-5"></div>
            <div class="col-md-4 m-t-10 no_padding_left control-overflow">
                <span class="f-b">Furnished and Installed ................................................................</span>
            </div>
            <div class="col-md-4 m-t-5 no_padding_right">
                <span class="f-b"></span>
            </div>
            <div class="col-md-1 m-t-5">

            </div>
        </div>
        <div class="row row-mrg sign-main">
            <div class="col-md-12 m-t-20">
                <h4 class="f-b mb-2">ACCEPTED</h4>
            </div>
            <div class="col-md-4 m-t-20">
                <strong>Signature</strong>
                <input type="text" class="signature w-85">
            </div>
            <div class="col-md-4 m-t-20">
                <strong>To</strong>
                <input type="text" class="signature">
            </div>
            <div class="col-md-4 m-t-20">
                <strong>Date</strong>
                <input type="text" class="signature">
            </div>
        </div>
        <div class="row row-mrg mt-3">
            <div class="col-md-12 m-t-0">
                <h5 class="f-b m-b-0 text-center">
                    Authorized Signature Required to Procees - All Terms are Accepted
                </h5>
            </div>
            <div class="col-md-12 m-t-0">
                <h5 class="m-b-0 text-center">
                    400 Authorized Signature Required to Procees - All Terms are Accepted Signature Required to Procees
                </h5>
            </div>
        </div>
    </div>
</div>