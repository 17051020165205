<ng-container [formGroup]="companyNameForm">
  <div class="card-body">
    <h2>Create Customer</h2>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="name" class="custom_label">Company Name *</label>
        <input type="text" id="name" formControlName="name" class="form-control"  placeholder="Company Name" name="name">
        <app-validation-error [formGroup]="companyNameForm" [controlName]="'name'"></app-validation-error>
      </div>
      </div>
  </div>
</ng-container>
