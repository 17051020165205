import {Component, EventEmitter, Inject, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {CustomerDataService} from "../customer.data.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToasterService} from "../../../services/core/toaster.service";
import {SharedDataService} from "../../shared/shared.data.service";
import {first} from "rxjs/operators";
import {SharedService} from "../../shared/shared.service";
import {DialogService} from "../../../services/core/dialog.service";
import {Router} from "@angular/router";
import {BackendApiService} from "../../../services/backend-api.service";
import {
  CustomerComponentsCommunicationServiceService
} from "../service/customer-components-communication-service.service";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-customer-create-edit',
  templateUrl: './customer-create-edit.component.html',
  styleUrls: ['./customer-create-edit.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ]
})
export class CustomerCreateEditComponent implements OnInit, OnChanges {

  @Output() onAdd = new EventEmitter<any>(true);
  public submitted = false;
  public model:any = {};
  public customerForm!: UntypedFormGroup;
  public step:any;
  public id: string = '';
  currentTab: string = 'Contact Details';
  public company_name: any;

  constructor(

    private _formBuilder: UntypedFormBuilder,
    public fb: UntypedFormBuilder,
    public customerDataService: CustomerDataService,
    private router: Router,
    public toasterService: ToasterService,
    public sharedDataService: SharedDataService,
    public sharedService: SharedService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backendApiService: BackendApiService,
    public customerComponentCommunicationService: CustomerComponentsCommunicationServiceService
  ) {
    this.model = this.data;
    this.id = this.data?.model?.id;
  }
  goBack(stepper: MatStepper) {
      stepper.previous();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit() {
    this.customerForm = new UntypedFormGroup({
      step01: new UntypedFormGroup({
        company: new UntypedFormControl(null)
      }),
      step02: new UntypedFormGroup({
        detail: new UntypedFormControl(null)
      }),
      step03: new UntypedFormGroup({
        contact: new UntypedFormControl(null)
      }),
      step04: new UntypedFormGroup({
        adminInformation: new UntypedFormControl(null)
      }),
      step05: new UntypedFormGroup({
        preview: new UntypedFormControl(null)
      }),
    });
  }

  handleInput(event: KeyboardEvent): void{
    event.stopPropagation();
  }
  onStepChanged = (event:any, stepper:any) => {
    this.step = {event:event, stepper:stepper};
    if(event.previouslySelectedIndex == 0 &&  event.selectedIndex == 1){
     this.company_name = this.customerForm.get('step01')?.value?.company?.name;
    }
  }
  touchFormControls(formControlName: string, secondFormControlName: any = null){
    let customerForm = this.customerForm.get(formControlName);
    // @ts-ignore
    customerForm = secondFormControlName ? customerForm?.get(secondFormControlName) : customerForm;
    customerForm?.markAsTouched()
    customerForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }
  onSubmit() {
    if (this.customerForm.invalid) {
      return;
    }


    const  customerDataService = this.id ?
      this.customerDataService.update(this.id , this.getFormData()) :
      this.customerDataService.create(this.getFormData());

    customerDataService
      .pipe(first())
      .subscribe(
        data => {

          if(this.customerForm?.value?.step02?.detail?.is_jobsite && !this.id) {
            // saves the jobsite
            this.saveJobsite(data);
          }else {

            this.toasterService.showSuccessMessage(data?.data?.message);
            this.close(data?.data?.customer);
          }
        });
  }

  changedHandler = (event:any) => {

  }

  close = (customer:any = null) => {
    this.dialogService.onClose(customer);
  }

  getFormData = () => {
    console.log('model customer',this.model);
    return {
      company_name: this.customerForm?.value?.step01?.company?.name,
      // step 02 form data
      company_title: this.customerForm?.value?.step02?.detail?.company_title,
      company_email: this.customerForm?.value?.step02?.detail?.company_email,
      is_jobsite: this.customerForm?.value?.step02?.detail?.is_jobsite,
      phone: this.customerForm?.value?.step02?.detail?.phone,
      secondary_phone: this.customerForm?.value?.step02?.detail?.secondary_phone,
      fax: this.customerForm?.value?.step02?.detail?.fax,
      website: this.customerForm?.value?.step02?.detail?.website,
      group_id: this.customerForm?.value?.step02?.detail?.group?.id,
      state_id: this.customerForm?.value?.step02?.detail?.main_address?.state_id?.id,
      address_line_1: this.customerForm?.value?.step02?.detail?.main_address?.address_line_1,
      address_line_2: this.customerForm?.value?.step02?.detail?.main_address?.address_line_2,
      city: this.customerForm?.value?.step02?.detail?.main_address?.city,
      zipcode: this.customerForm?.value?.step02?.detail?.main_address?.zipcode,
      billing_state_id: this.customerForm?.value?.step02?.detail?.billing_address?.state_id?.id,
      billing_address_line_1: this.customerForm?.value?.step02?.detail?.billing_address?.address_line_1,
      billing_address_line_2: this.customerForm?.value?.step02?.detail?.billing_address?.address_line_2,
      billing_city: this.customerForm?.value?.step02?.detail?.billing_address?.city,
      billing_zipcode: this.customerForm?.value?.step02?.detail?.billing_address?.zipcode,
      shipping_state_id: this.customerForm?.value?.step02?.detail?.shipping_address?.state_id?.id,
      shipping_address_line_1: this.customerForm?.value?.step02?.detail?.shipping_address?.address_line_1,
      shipping_address_line_2: this.customerForm?.value?.step02?.detail?.shipping_address?.address_line_2,
      shipping_city: this.customerForm?.value?.step02?.detail?.shipping_address?.city,
      shipping_zipcode: this.customerForm?.value?.step02?.detail?.shipping_address?.zipcode,

      // step 03 form data
      first_name: this.customerForm?.value?.step03?.contact?.first_name,
      last_name: this.customerForm?.value?.step03?.contact?.last_name,
      email: this.customerForm?.value?.step03?.contact?.email,
      contact_phone: this.customerForm?.value?.step03?.contact?.phone,
      mobile: this.customerForm?.value?.step03?.contact?.mobile,
      contact_authority: this.customerForm?.value?.step03?.contact?.contact_authority,
      username: this.customerForm?.value?.step03?.contact?.username,
      password: this.customerForm?.value?.step03?.contact?.password,
      notes: this.customerForm?.value?.step03?.contact?.notes,
      has_portal_access: this.customerForm?.value?.step03?.contact?.has_portal_access,
      is_primary: this.customerForm?.value?.step03?.contact?.is_primary,

      // step 04 form data
      tax_exempt: this.customerForm?.value?.step04?.adminInformation?.tax_exempt,
      default_payment_method: this.customerForm?.value?.step04?.adminInformation?.payment_method?.id,
      preferred_markup: this.customerForm?.value?.step04?.adminInformation?.markup,
      lien_period: this.customerForm?.value?.step04?.adminInformation?.lien_period,
      source_id: this.customerForm?.value?.step04?.adminInformation?.source?.id,
      risk: this.customerForm?.value?.step04?.adminInformation?.risk,

      status:this.model?.model?.status?.id ?? 1,
      type:0,
    }
  }

  getJobSiteData = () => {
    return {
      title: this.customerForm?.value?.step02?.detail?.company_title,
      email: this.customerForm?.value?.step02?.detail?.company_email,
      phone: this.customerForm?.value?.step02?.detail?.phone,
      fax: null,
      address: this.customerForm?.value?.step02?.detail?.main_address?.address_line_1,
      address2: this.customerForm?.value?.step02?.detail?.main_address?.address_line_2,
      city: this.customerForm?.value?.step02?.detail?.shipping_address?.city,
      state_id: this.customerForm?.value?.step02?.detail?.main_address?.state_id?.id,
      zipcode: this.customerForm?.value?.step02?.detail?.shipping_address?.zipcode,
      country_id: 231,
    }
  }

  saveJobsite(data: any) {
    this.backendApiService.postApi('jobsites/create',this.getJobSiteData())
      .subscribe((response: any) => {
        console.log('response',response);

        // success message for new customer created
        this.toasterService.showSuccessMessage(data?.data?.message);
        let customer = data?.data?.customer;
        customer['jobsite'] = response?.data?.data;
        this.customerComponentCommunicationService.setCustomerJobSiteValue(customer);
        this.close(customer);
      })
  }

  initChildForm(step: string, formControlName: string, value:any){
    this.customerForm.get(step)?.get(formControlName)?.setValue(value);

  }
}
