import { Injectable } from '@angular/core';
import {SharedService} from "../../shared/shared.service";
import {TenantService} from "../../tenant/tenant.service";
import {SubcontractorDataService} from "./subcontractor.data.service";
import {first} from "rxjs/operators";
import {SkillDataService} from "../../setting/skill/skill.data.service";
import {SkillService} from "../../setting/skill/skill.service";
import {PaymentTermService} from "../../setting/payment-term/payment-term.service";

@Injectable({
  providedIn: 'root'
})
export class SubcontractorService {

  public canWorkOutState: boolean = false;
  public states: any = [];
  public skills: any = [];
  public paymentTerms: any = [];

  constructor(
    public sharedService: SharedService,
    public tenantService: TenantService,
    public subcontractorDataService: SubcontractorDataService,
    public skillService: SkillService,
    public paymentTermService: PaymentTermService
  ) {}

  getSubcontractors = (param: any) => {
    // return this.subcontractorDataService.list({per_page: 100,sort_order: 'desc',});
    return this.subcontractorDataService.list(param);
  }

  onChangeOutStateWork = (event:any) => {
    this.canWorkOutState = event.checked;
  }

  getStates = () => {
    return this.sharedService.getAllStates()
      .pipe(first())
      .subscribe(
        data => {
          this.states = data.data;
        });
  }

  getSkills = () => {
    return this.skillService.getSkills()
      .pipe(first())
      .subscribe(
        data => {
          this.skills = data.data;
        });
  }

  getPaymentTerms = () => {
    return this.paymentTermService.getPaymentTerms()
      .pipe(first())
      .subscribe(
        data => {
          this.paymentTerms = data.data;
        });
  }
  create = (model: any) => {
    return this.subcontractorDataService.create(model)
  }
}
