import {Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {CustomerService} from "../../customer.service";
import {first, pairwise, startWith} from "rxjs/operators";
import {Subscription} from "rxjs";
import { NotOnlySpaces } from '../../../shared/CustomValidators/CustomNotOnlySpacesValidator';

import { EmailValidator } from '../../../shared/CustomValidators/CustomInvalidEmailValidator';
import {WebsiteURLValidator} from '../../../shared/CustomValidators/CustomWebsiteUrlValidator';
import {PhoneNumberValidator} from '../../../shared/CustomValidators/CustomInvalidPhoneNumberValidator';
import {FaxNumberValidator} from '../../../shared/CustomValidators/CustomInvalidFaxNumberValidator';

@Component({
  selector: 'app-company-contact',
  templateUrl: './company-contact.component.html',
  styleUrls: ['./company-contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyContactComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyContactComponent),
      multi: true
    }
  ]
})
export class CompanyContactComponent implements OnInit {

  @Input() model: any = {};
  @Input() form: any = {};
  @Output() callback = new EventEmitter<any>(true);
  @Output() onInitForm = new EventEmitter<any>(true);
  public onModelValueChanged:any = {};
  public authorities:any[]=[];
  //@ts-ignore
  public companyContactFormSubscription: Subscription;
  showPassword:Boolean=false;

  public companyContactForm: UntypedFormGroup = this.initBasicForm();

  constructor(
    public customerService: CustomerService
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
    this.onModelValueChanged = this.model?.model?.contacts[0];
  }


  ngOnInit(): void {
    this.companyContactForm = this.initBasicForm();
    this.initSubscriptions();
    this.getAuthorities();
    this.initFormValues();
  }
  initBasicForm(){
    return new UntypedFormGroup({
      first_name: new UntypedFormControl(null,[Validators.required,NotOnlySpaces()]),
      last_name: new UntypedFormControl(null),

      email:new UntypedFormControl(null,[Validators.required,EmailValidator()]),
      phone:new UntypedFormControl(null,[Validators.required,PhoneNumberValidator()]),
      mobile:new UntypedFormControl(null,[PhoneNumberValidator()]),

      contact_authority:new UntypedFormControl(null),
      username:new UntypedFormControl(null),
      password:new UntypedFormControl(null),
      notes:new UntypedFormControl(null),
      has_portal_access:new UntypedFormControl(0),
      is_primary:new UntypedFormControl(0),
    });
  }
  initFormValues(){
    this.companyContactForm.get('is_primary')?.setValue( this.onModelValueChanged ? (this.onModelValueChanged?.is_primary ? true : false) : true);
    this.companyContactForm.get('first_name')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.first_name : '');
    this.companyContactForm.get('last_name')?.setValue(this.onModelValueChanged?  this.onModelValueChanged?.last_name : '');
    this.companyContactForm.get('email')?.setValue(this.onModelValueChanged?  this.onModelValueChanged?.email : '');
    this.companyContactForm.get('phone')?.setValue(this.onModelValueChanged?  this.onModelValueChanged?.phone : '');
    this.companyContactForm.get('mobile')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.mobile : '');
    this.companyContactForm.get('contact_authority')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.contact_authority : '');
    this.companyContactForm.get('has_portal_access')?.setValue(this.onModelValueChanged?.has_portal_access ? true : false);
    // this.companyContactForm.get('is_primary')?.setValue(this.onModelValueChanged?.is_primary ? true : false);
    this.companyContactForm.get('notes')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.notes : '');
    this.onInitForm.emit(this.companyContactForm);
  }
  omit_special_char(event: KeyboardEvent): boolean {
    const k = event.keyCode || event.which;
    return (k >= 48 && k <= 57) || k == 8 || k == 32; // Allow numbers, backspace, and space
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password');
    if (passwordInput) {
      passwordInput.setAttribute('type', this.showPassword ? 'text' : 'password');
    }
  }
  getAuthorities = () => {
    this.customerService.getContactTypes()
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.authorities = data.data.map((authority:any)=>{
            return {id: authority.id, name: authority.name};
          });
        });
  }
  preventSpace(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    console.log('this.companyContactForm', this.companyContactForm);
    val && this.companyContactForm?.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.companyContactForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.companyContactForm.disable() : this.companyContactForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.companyContactForm.valid ? null : { invalidForm: {valid: false, message: "Company contact fields are invalid"}};
  }

  initSubscriptions(){
    if (this.form){
      this.companyContactFormSubscription = this.form.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.form.touched){
            this.touchFormControls();
          }
        });
    }

  }
  touchFormControls(){
    Object.keys(this.companyContactForm.controls).forEach(key => {
      this.companyContactForm.get(key)?.markAsTouched();
    });
  }
  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.companyContactFormSubscription?.unsubscribe();
  }
}
