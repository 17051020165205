import { AbstractControl, ValidatorFn } from '@angular/forms';

export function WebsiteURLValidator(): ValidatorFn {
  // Modified pattern to allow any top-level domain (TLD)
  // const websitePattern = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  //@ts-ignore
  const websitePattern = /^((https?|ftp|smtp):\/\/)?(?:www\.[a-z0-9]+(\.[a-z]{2,63}){1,3}(\/[a-zA-Z0-9#]*)*(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?(#[a-zA-Z0-9-_]*)?|(?!www)[a-z0-9]+(\.[a-z]{2,63}){1,3})$/;
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;


    if (!value) {
      return null;
    }


    const isValid = websitePattern.test(value);


    return isValid ? null : { 'invalidWebsiteURL': true };
  };
}

