import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {first} from "rxjs/operators";
import {CustomerDataService} from "../../customer/customer.data.service";
import {MatTableDataSource} from "@angular/material/table";


@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit, AfterViewInit {

  public sorting: any = {active: 'created_date', direction: 'desc'};

  show = true;
  displayedColumns: string[] = ['customer_number', 'company_name', 'email', 'balance', 'customer_group', 'phone', 'address', 'country', 'state', 'city', 'zipcode'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: any;
  public searchTerm: any;
  public data: any;

  public columns: any[] = [
    {position: 0, id: 'id', title: 'ID', data: 'id', canHide: false, display: 'id', defaultShow: true, isSortingDisable:false},
    {position: 1, id: 'defect_id', title: 'Defect', data: 'defect_id', canHide: true, display: 'defect_id', defaultShow: true, isSortingDisable:false},
    {position: 2, id: 'description', title: 'Description', data: 'description', canHide: true, display: 'description', defaultShow: true, isSortingDisable:false},
    {position: 3, id: 'tc_id', title: 'Test Case', data: 'tc_id', canHide: true, display: 'tc_name', defaultShow: true, isSortingDisable:false},
    {position: 4, id: 'severity', title: 'Severity', data: 'severity', canHide: true, display: 'severity', defaultShow: true, isSortingDisable:false},
    {position: 5, id: 'bugtype', title: 'Bug Type', data: 'bugtype', canHide: true, display: 'bugtype', defaultShow: true, isSortingDisable:false},
    {position: 6, id: 'status', title: 'Status', data: 'status', canHide: true, display: 'status', defaultShow: true, isSortingDisable:false},
    {position: 7, id: 'priority', title: 'Priority', data: 'priority ', canHide: true, display: 'priority', defaultShow: false, isSortingDisable:false},
    {position: 8, id: 'assignto_username', title: 'Assigned To', data: 'assignto_username', canHide: true, display: 'assignto_username', defaultShow: false, isSortingDisable:false},
    {position: 9, id: 'created_date', title: 'Created Date', data: 'created_on', canHide: true, display: 'created_date', defaultShow: true, isSortingDisable:false},
    {position: 10,id: 'last_updated_on', title: 'Modified Date', data: 'last_updated_on', canHide: true, display: 'last_updated_on', defaultShow: false, isSortingDisable:false},
  ];


  public pageSizeOptions: any;
  public pageSize:number = 20;


  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  //@ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
  pageEvent!: PageEvent;

  constructor(
    public customerDataService: CustomerDataService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  customSort = (event:any) => {
    this.sorting = event;
    this.getData(1, 0, event.active, event.direction);
  }

  detailPage(row:any){
    //this.router.navigate([`customers/detail/${row.id}`]);
  }

  getData = (page: number = 1, perPage: number = 1, start: number = 0, sortBy: string = 'customer_number', sortOrder: string = 'desc') => {
    this.customerDataService.list({
      page: page,
      per_page: perPage,
      sort_by: sortBy,
      sort_order: sortOrder,
      search: this.searchTerm,
      columns: this.columns,
    })
      .pipe(first())
      .subscribe(
        data => {;
          this.data = data;
          this.dataSource = this.data.data;
          console.log(this.dataSource);
        });
  }

  getColumnPosition = (event:any) => {
    let selectedColumn = this.columns.filter((column)=>{
      return column.id === event;
    }).map((column)=>{
      return column.position;
    });
    let length = selectedColumn.length;
    return (selectedColumn && length > 0) ? selectedColumn[length-1] : -1;
  }

  public doFilter = (value: string) => {
    this.searchTerm = value.trim().toLocaleLowerCase();
    this.dataSource.filter = this.searchTerm;
    this.paginator.pageIndex = 0;
    this.getData();
  }

  onPaginateChange(event: PageEvent) {
    let page = event.pageIndex;
    let size = event.pageSize;
    let start = page * size;
    page = page + 1;
    this.pageSize = event.pageSize;
    this.getData(page, start, this.sorting.active, this.sorting.direction);
  }

  onColumnShowHide = ($event:any) => {
    const value = $event.target.value;
    if ($event.target.checked) {
      const index = this.getRemoveElementIndex(value);
      if (index && index !== -1) {
        /*this.displayedColumns.splice(index, 0, value);
        this.displayedColumns.join();*/
        this.getNewDisplayedColumns(value);
      }
    } else {
      const index: number = this.displayedColumns.indexOf(value);
      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
    }

    //this.coreService.onColumnSave('Defects', this.displayedColumns);
  }

  getRemoveElementIndex = (value:any) => {
    const ele = this.columns.find((item) => {
      return (item.id === value);
    });
    return (ele) ? ele.position : -1;
  }

  getNewDisplayedColumns = (value:any) => {
    const defualtColumns = this.pushCustomFields(['id', 'defect_id', 'description', 'tc_id', 'severity', 'bugtype', 'status', 'priority','assignto_username','created_date', 'last_updated_on']);
    let displayedColumns = this.displayedColumns;
    displayedColumns.push(value);
    this.displayedColumns = defualtColumns;
    let newDisplayedColumns:any = [];
    displayedColumns.forEach((column) => {
      const index: number = this.displayedColumns.indexOf(column);
      newDisplayedColumns[index] = column;
    });
    let newDisplayedColumnsToString = newDisplayedColumns.toString();
    this.displayedColumns = newDisplayedColumnsToString.split(',').filter((item:any) => {
      if (item !== '') {
        return item;
      }
    });
  }

  pushCustomFields = (columns:any) => {
    /*if (this.customFields.length > 0) {
      this.customFields.map((field)=>{
        columns.push(field.custom_field_key);
      });
      return columns;
    }*/
    return columns;
  }

}

export interface PeriodicElement {
  position: number;
  name:string;
  group:string;
  address:string;
  balance:number;
  customer:string;
  email:string;
  phone:string;
  country:string;
  state:string;
  city:string;
  zipcode:string;
}

const ELEMENT_DATA: PeriodicElement[] = [];
