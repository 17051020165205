import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'app-devextreme-data-grid',
  templateUrl: './devextreme-data-grid.component.html',
  styleUrls: ['./devextreme-data-grid.component.scss']
})
export class DevextremeDataGridComponent implements OnInit {

  @ViewChild('dxDataGridComponent', { static: false }) dxDataGridComponent?: DxDataGridComponent;
  @Output() eventHandler = new EventEmitter<any>(true);

  @Input() module!: string;
  @Input() dataSource: any[] = [];
  @Input() displayMode: any = 5;
  @Input() allowedPageSizes: any[] = [5, 10, 15];
  @Input() showPageSizeSelector:boolean = true;
  @Input() showInfo:boolean = true;
  @Input() showNavButtons:boolean = true;
  @Input() visible:boolean = true;
  @Input() showColumnLines:boolean = true;
  @Input() showRowLines:boolean = false;
  @Input() showBorders:boolean = false;
  @Input() allowColumnResizing:boolean = true;
  @Input() allowColumnReordering:boolean = true;
  @Input() selectMulitple:boolean = false;
  @Input() columns:any[] = [];
  @Input() remoteOperations=true



  constructor() { }

  ngOnInit(): void {
  }

  onCellClick = (event:any) => {
    this.eventHandler.emit(event);
  }

  refreshDataGrid() {
    this.dxDataGridComponent?.instance.state(null);
  }

  onStateResetClick() {
    this.dxDataGridComponent?.instance.state(null);
  }

  onRefreshClick() {
    window.location.reload();
  }

  onShowColumnChooser = () => {
    this.dxDataGridComponent?.instance?.showColumnChooser();
  }

}
