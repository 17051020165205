<ng-container [formGroup]="datePickerForm">
<!--
<div class="form-group col-11" style="padding-right:0px !important;" >
  <input
    id="start_date"
    class="form-control no-boarder-field js-copy-title"
    formControlName="start_date"
    [ngxMatDatetimePicker]="picker"
    placeholder="" [disabled]="disabled">
  <label class="form-control-placeholder" for="start_date">START DATE*</label>
  <div class="invalid-feedback" *ngIf="!taskForm.controls['start_date'].valid && taskForm.controls['start_date'].touched">
    Start Date is required.
  </div>
</div>

<div style="padding-right:0px !important; padding-left:0px !important;" class="col-1 form-control no-boarder-field js-copy-title">
  <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker
    [showSpinners]="showSpinners"
    [showSeconds]="showSeconds"
    [stepHour]="stepHour"
    [stepMinute]="stepMinute"
    [stepSecond]="stepSecond"
    [touchUi]="touchUi"
    [color]="color"
    [enableMeridian]="enableMeridian">
  </ngx-mat-datetime-picker>
</div>
-->
</ng-container>
