import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteCommunicationService {
  private noteListUpdate = new Subject<any>();

  constructor() { }

  setNoteUpdate(data: any) {
    this.noteListUpdate.next(data);
  }

  getNodeUpdateList(): Observable<any> {
    return this.noteListUpdate.asObservable();
  }
}
