import {
  Directive,
  Input,
  OnInit,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { SharedDataService } from '../../shared.data.service';

@Directive({
  selector: '[appPermission]',
})
export class PermissionsDirective implements OnInit {
  permissions: any;
  @Input('appPermission') requiredPermission!: string;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.updateElement();
  }

  private updateElement() {
    if (this.requiredPermission !== 'DONOTAPPLY') {
      this.permissions = this.sharedDataService.getPermissions();
      const nativeElement = this.el.nativeElement;
      const nodeName = nativeElement.nodeName;
      const isSuperAdmin = this.sharedDataService.getIsCurrentUserSuperAdmin()
      if (!isSuperAdmin){
        if (this.permissions.includes(this.requiredPermission)) {
          this.enableElement(nativeElement,nodeName)
        } else {
          this.disabledElement(nativeElement,nodeName);
        }
      }

      if(this.requiredPermission == 'disable'){
        this.disabledElement(nativeElement,nodeName);
      }

    }
  }

  enableElement(nativeElement: any, nodeName: any){
    this.renderer.removeClass(nativeElement, 'permission-disabled');
    if (this.isInteractiveElement(nodeName)) {
      this.renderer.removeAttribute(nativeElement, 'disabled');
    }
  }
  disabledElement(nativeElement: any, nodeName: any){
    this.renderer.addClass(nativeElement, 'permission-disabled');
    if (this.isInteractiveElement(nodeName)) {
      this.renderer.setAttribute(nativeElement, 'disabled', 'true');
    }
  }

  private isInteractiveElement(nodeName: string): boolean {
    return (
      nodeName === 'BUTTON' ||
      nodeName === 'INPUT' ||
      nodeName === 'SELECT' ||
      nodeName === 'TEXTAREA' ||
      nodeName === 'MAT-SLIDE-TOGGLE' ||
      nodeName === 'MAT-SELECT'
    );
  }
}
