<div *ngIf="isInvalid()">
  <small class="text-danger" *ngIf="hasRequiredError() || hasErrorEmptyInput()">
    {{message}}
  </small>
  <small class="text-danger" *ngIf="hasErrorPattern()">
    {{messagePattern}}
  </small>
  <small class="text-danger" *ngIf="!hasRequiredError() && hasErrorEmail()">
    {{messageEmail}}
  </small>
  <small class="text-danger"  *ngIf="hasErrorWebsitePattern()">
    {{messageWebsiteUrl}}
  </small>
  <small class="text-danger"  *ngIf="hasErrorPhoneNumber()">
    {{messagePhoneNumber}}
  </small>

  <small class="text-danger"  *ngIf="hasErrorZipcode()">
   {{messageZipcode}}
  </small>

  <small class="text-danger"  *ngIf="hasErrorMin()">
    {{minValueMessage}}
  </small>

  <small class="text-danger"  *ngIf="hasErrorMax()">
    {{maxValueMessage}}
  </small>

</div>
