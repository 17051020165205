<mat-accordion [formGroup]="changeOrderScopeOptionsForm">
<!--  <mat-expansion-panel>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Note-->
<!--      </mat-panel-title>-->
<!--    </mat-expansion-panel-header>-->
<!--    <div class="notes">-->
<!--      <p>Notes</p>-->
<!--      <p class="color-FF6600">Description</p>-->
<!--      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in sodales dui. Donec sed massa orci. Sed venenatis risus blandit, elementum eros vel, tempus lectus. </p>-->
<!--    </div>-->
<!--  </mat-expansion-panel>-->

<!--  <mat-expansion-panel>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Chat-->
<!--      </mat-panel-title>-->
<!--      &lt;!&ndash; <mat-panel-description>-->
<!--        Description-->
<!--      </mat-panel-description> &ndash;&gt;-->
<!--    </mat-expansion-panel-header>-->
<!--    Content-->
<!--  </mat-expansion-panel>-->

<!--  <mat-expansion-panel>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Reminder-->
<!--      </mat-panel-title>-->
<!--      &lt;!&ndash; <mat-panel-description>-->
<!--        Description-->
<!--      </mat-panel-description> &ndash;&gt;-->
<!--    </mat-expansion-panel-header>-->
<!--    <div class="notes">-->
<!--      <p>Reminder Details</p>-->
<!--      <div class="d-flex justify-content-between">-->
<!--        <div class="date">-->
<!--          <p class="color-FF6600 mb-0">Date</p>-->
<!--          <p>01-08-23</p>-->
<!--        </div>-->

<!--        <div class="to">-->
<!--          <p class="color-FF6600 mb-0">To</p>-->
<!--          <p>Moe Ali</p>-->
<!--        </div>-->
<!--      </div>-->

<!--      <p class="color-FF6600">Description</p>-->
<!--      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in sodales dui. Donec sed massa orci. Sed venenatis risus blandit, elementum eros vel, tempus lectus. </p>-->
<!--    </div>-->
<!--  </mat-expansion-panel>-->

<!--  <mat-expansion-panel>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Status-->
<!--      </mat-panel-title>-->
<!--      &lt;!&ndash; <mat-panel-description>-->
<!--        Description-->
<!--      </mat-panel-description> &ndash;&gt;-->
<!--    </mat-expansion-panel-header>-->
<!--    <div class="form-group">-->
<!--      <label>Status</label>-->
<!--      <mat-select  formControlName="changeOrder_status" class="select-control" (selectionChange)="proposalStatusUpdate()"-->
<!--                   placeholder="select">-->
<!--        <mat-option *ngFor="let status of changeOrderSettings?.proposal_status" [value]="status.id">{{status.name}}</mat-option>-->
<!--      </mat-select>-->
<!--    </div>-->
<!--  </mat-expansion-panel>-->

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Display Options
      </mat-panel-title>
      <!-- <mat-panel-description>
        Description
      </mat-panel-description> -->

    </mat-expansion-panel-header>
    <ng-container [formGroupName]="'displayOptions'">
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'product'" (change)="onChangeDisplayOptionsFn($event)">Product</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin"[formControlName]="'service'"  (change)="onChangeDisplayOptionsFn($event)">Service</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'labour'"  (change)="onChangeDisplayOptionsFn($event)">Labor</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'shipping'"  (change)="onChangeDisplayOptionsFn($event)">Shipping</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'unitSellingPrice'"  (change)="onChangeDisplayOptionsFn($event)">Unit Selling Price</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'signature'"  (change)="onChangeDisplayOptionsFn($event)">Signature</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'totalPrice'"  (change)="onChangeDisplayOptionsFn($event)">Total Price</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'providedServices'"  (change)="onChangeDisplayOptionsFn($event)">Provided Services</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'tax'"  (change)="onChangeDisplayOptionsFn($event)">Tax</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'qualifyingNotes'"  (change)="onChangeDisplayOptionsFn($event)">Qualifying Notes</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'scopeNotes'"  (change)="onChangeDisplayOptionsFn($event)" >Scope Notes</mat-checkbox>
      </div>
    </ng-container>
  </mat-expansion-panel>


</mat-accordion>
