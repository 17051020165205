import { Injectable } from '@angular/core';
import {CustomerDataService} from "./customer.data.service";
import {CustomerGroupDataService} from "../customer-group/customer.group.data.service";
import {first} from "rxjs/operators";
import {SharedDataService} from "../shared/shared.data.service";
import {ContactTypeService} from "../setting/contact-type/contact-type.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    public customerDataService: CustomerDataService,
    private groupDataService: CustomerGroupDataService,
    private sharedDataService: SharedDataService,
    private contactTypeService: ContactTypeService
  ) {}

  getCustomers = (params: any = {per_page: 9999999}) => {
    return this.customerDataService.list(params);
  }

  searchCustomers = (params: any = {per_page: 15}) => {
    return this.customerDataService.list(params);
  }

  getGroups = () => {
    return this.groupDataService.list();
  }

  getPaymentMethods = () => {
    return this.sharedDataService.getPaymentMethods();
  }

  getTaxAndDiscountClasses = () => {
    return this.sharedDataService.getTaxClass();
  }

  getContactTypes = () => {
    return this.contactTypeService.getContactTypes();
  }

  getStatus = () => {
    return this.sharedDataService.getStatusesByModule('customer');
  }

}
