<div class="overlap_panel_wrapper">
    <div class="overlap_panel_sidebar">
      <div class="side_header">
        <div class="cross_wrap">
          <a href="javascript:void(0);" (click)="close()">
            <img src="../../../../assets/img/cross_icon.svg" alt="" class="icon">
          </a>
        </div>
        <p class="company_name">Company Contact</p>
        <div class="back_wrap">

          <a (click)=" goBack(stepper)" *ngIf="stepper.selectedIndex >0" class="back-button">
          <img src="../../../../assets/img/left_orange_arrow.svg" alt="" class="arrow">
          <span>Back</span>
          </a>
        </div>
      </div>
    </div>
  <div class="overlap_right_panel">
      <form [formGroup]="customerForm" (ngSubmit)="onSubmit()" novalidate>
        <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="onStepChanged($event, stepper)">
          <mat-step formGroupName="step01" [stepControl]="customerForm.get('step01')!">
            <ng-template matStepLabel>Company Contact</ng-template>
            <app-contact (callback)="changedHandler($event)" [markScrollAbleSearchSelectTouched]="markScrollAbleSearchSelectTouched" [form]="customerForm.get('step01')" formControlName="contact" (onInitForm)="initChildForm('step01', 'contact',$event.value)" [customerDetail]="customerDetail" [model]="model"></app-contact>
            <div class="controls">
              <button mat-button type="button" class="btn_transparent" (click)="close()">Cancel</button>
              <button type="button" mat-button matStepperNext (click)="touchFormControls('step01', 'contact')" class="btn_solid_color">Next  <img src="../../../../assets/img/btn_arrow.png" alt="icon" class="icon"></button>
            </div>
          </mat-step>
          <mat-step formGroupName="step02" [stepControl]="customerForm.get('step02')!">
            <ng-template matStepLabel>Preview</ng-template>
            <app-final-preview-contact [isEdit]="isEdit" [model]="model" [form]="customerForm"></app-final-preview-contact>
            <div class="controls">
            <button mat-button type="button" matStepperPrevious class="btn_transparent">Back</button>
            <button type="submit" mat-button matStepperNext class="btn_solid_color">{{isEdit ? 'Update' : 'Create' }} <img src="../../../../assets/img/btn_arrow.png" alt="icon" class="icon"></button>
          </div>
          </mat-step>
        </mat-horizontal-stepper>
      </form>
    </div>
  </div>
