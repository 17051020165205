<div class="task-history">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Created By</th>
      <th scope="col">Update Disposition</th>
      <th scope="col">Update</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let history of ticketHistories">
      <td class="date">{{ history.date | date:'shortDate'}}</td>
      <td>
        <a href="#">{{ history?.created_by?.first_name }} {{ history?.created_by?.last_name }}</a>
      </td>
      <td>
        <span class="comment">{{ history?.update_disposition }}</span>
        <!--<a href="#">#6</a>-->
      </td>
      <td>
        <span class="update">No → Yes</span>
      </td>
    </tr>

    <!--<tr>
      <td class="date">01/04/2024 07:40:21 pm	</td>
      <td>
        <a href="#">Usman</a>
      </td>
      <td>
        <span class="comment">Comment created</span>
        <a href="#">#6</a>
      </td>
      <td>
        <span class="update"></span>
      </td>
    </tr>-->
    </tbody>
  </table>
</div>
