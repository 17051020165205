import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {BackendApiService} from "../../../../services/backend-api.service";
import {ToasterService} from "../../../../services/core/toaster.service";
import {formatDate} from "@angular/common";
import {HelperService} from "../../../../services/helper.service";

@Component({
  selector: 'app-ticket-time-elapsed',
  templateUrl: './ticket-time-elapsed.component.html',
  styleUrls: ['./ticket-time-elapsed.component.scss']
})
export class TicketTimeElapsedComponent implements OnInit, OnChanges {

  @Input() model: any = {};
  public time_elapsed: any = [];
  public sectionForm: UntypedFormGroup = new UntypedFormGroup({
    timeElapsed: new FormArray([])
  })
  constructor(
    private fb: FormBuilder,
    public backendApiService: BackendApiService,
    private helperService: HelperService,
    public toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.backendApiService.getApi('ticket-timer?ticket_id='+this.model?.id)
      .subscribe((response: any) => {
        this.time_elapsed = response.data;
        this.timeElapsed().clear();
        this.time_elapsed?.map((data: any) => {
          this.addNewTimeElapsed(data,true);
        })
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
  }

  timeElapsed(): UntypedFormArray {
    return this.sectionForm.get('timeElapsed') as UntypedFormArray;
  }

  addNewTimeElapsed(item: any = '',saved:boolean = false) {
    this.timeElapsed().push(this.newTimeElapsed(item,saved));
  }

  newTimeElapsed(item: any, saved:boolean = false) {
    return this.fb.group({
      id: [item ? item?.id : null ],
      date: [item ? formatDate(item?.date,'yyyy-MM-dd','en-US') : null ],
      hour: [item ? item?.hour : 1 ],
      minute: [item ? item?.minute : 0 ],
      second: [item ? item?.second : 0 ],
      time_elapsed: [item ? item?.time_elapsed : null ],
      comment: [item ? item?.comment : null ],
      ticket_id: [item ? item?.ticket_id : null ],
      created_by : [item ? item?.created_by : null],
      is_saved: [saved],
    })
  }

  deleteTimeElapsed(index: any) {
    const id = this.timeElapsed().at(index)?.get('id')?.value;
    this.timeElapsed().removeAt(index);

    if(id) {
      this.backendApiService.deleteApi('ticket-timer/'+id)
        .subscribe((response: any) => {
          this.toasterService.showSuccessMessage(response.data.message)
        })
    }
  }
  integerNumberOnly(event: Event , maxValue: any = 0) {
    this.helperService.Integernumber(event);
    if (maxValue){
      this.helperService.validateMaxValue(event, maxValue);
    }
  }
  saveValue(index: any) {

    let validationFailed = false;
    if (!this.timeElapsed().at(index).get('date')?.value){
      validationFailed = true;
      this.toasterService.showWarning('Date field is required.');
    }
    if (!this.timeElapsed().at(index).get('comment')?.value){
      validationFailed = true;
      this.toasterService.showWarning('Comment field is required.');
    }
    if (!this.timeElapsed().at(index).get('hour')?.value)
    {
      this.timeElapsed().at(index).get('hour')?.setValue('00')
    }
    if (!this.timeElapsed().at(index).get('minute')?.value)
    {
      this.timeElapsed().at(index).get('minute')?.setValue('00')
    }
    if (!this.timeElapsed().at(index).get('second')?.value)
    {
      this.timeElapsed().at(index).get('second')?.setValue('00')
    }

    if (!validationFailed) {
      this.timeElapsed().at(index).get('is_saved')?.setValue(true);
      this.saveTimeElapsedAtBackend(index);
    }
  }

  editTimeElapsed(index: any) {
    this.timeElapsed().at(index).get('is_saved')?.setValue(false);
  }

  saveTimeElapsedAtBackend(index: any) {
    const value = this.timeElapsed().at(index)?.value;
    let hour = value?.hour;
    let minute = value?.minute > 60  ? 60 : value?.minute;
    let second = value?.second > 60 ? 60 : value?.second ;

    const data = {
      ticket_id: this.model?.id,
      date: formatDate(value?.date,'yyyy-MM-dd','en-US'),
      hour: hour,
      minute: minute,
      second: second,
      comment: value?.comment,
    };

    const service = value?.id ? this.backendApiService.patchApi('ticket-timer/'+value?.id,data) :
      this.backendApiService.postApi('ticket-timer/create',data)

    service.subscribe((response: any) => {
      this.toasterService.showSuccessMessage(response?.data?.message);
      this.getData();
    })
  }

  checkNumberHasTwoDigits(number: any) {
    if(number/10 < 1 && number/10 > 0){
      return "0" + String(number);
    }
    return number;
  }
}
