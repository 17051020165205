import { Component, inject, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { first, map } from 'rxjs';
import { VendorDataService } from 'src/app/modules/inventory/vendor/vendor.data.service';
import { VendorService } from 'src/app/modules/inventory/vendor/vendor.service';
import { TaskDataService } from 'src/app/modules/sale/task/task.data.service';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { DialogService } from 'src/app/services/core/dialog.service';
import { ToasterService } from 'src/app/services/core/toaster.service';
import { VendorQuoteDataService } from '../vendor-quote-data.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { JobDataService } from 'src/app/modules/sale/job/job.data.service';
import { BidDataService } from 'src/app/modules/sale/bid/bid.data.service';
import { CurrencyMaskOptions } from '../../shared.constants';

@Component({
  selector: 'app-add-edit-vendor-quotes',
  templateUrl: './add-edit-vendor-quotes.component.html',
  styleUrls: ['./add-edit-vendor-quotes.component.scss']
})
export class AddEditVendorQuotesComponent implements OnInit {
  public vendors: any = [];
  public vendorsQuoteList: any = [];
  isVendorQuoteModule: boolean = false
  showSearchInput: boolean = true;
  modulesList: any = [];
  searchParams = {
    type: '',
    module_id: '',
    search: ''
  };

  selectedItem: any = {};
  selectedStage: any = {};
  stages: any = [];
  vendorQuoteId: any
  moduleId: any
  referenceId: any;
  isEditMode: boolean = false
  vendorDetail: any
  vendorId: any
  isVendorModule: boolean = false
  public currencyMaskOptions = CurrencyMaskOptions;
  isVendorDisabled: boolean=false;

  constructor(
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<AddEditVendorQuotesComponent>,
    public backendApiService: BackendApiService,
    public dialog: NgDialogAnimationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toasterService: ToasterService,
    public taskDataService: TaskDataService,
    public vendorDataService: VendorDataService,
    private vendorQuoteDataService: VendorQuoteDataService,
    public jobDataService: JobDataService,
    public bidDataService: BidDataService
  ) {
    this.data = data.item;
    this.moduleId = data.moduleId
    this.referenceId = data.referenceId
    this.isVendorModule = data.isVendorModule
    this.vendorId = data.vendorId
    this.isVendorDisabled=data.isVendorDisabled

    if (data) {
      this.vendorQuoteId = data?.item?.id
      this.isEditMode = data.isEditMode
    }
  }

  public vendorQuoteForm: UntypedFormGroup = new UntypedFormGroup({
    vendor_id: new UntypedFormControl(null, [Validators.required]),
    quotation_reference: new UntypedFormControl(null, [Validators.required]),
    amount: new UntypedFormControl(null, [Validators.required, Validators.min(0.1)]),
    quantity: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(null, [Validators.required]),
    module_id: new UntypedFormControl(null),
    reference_id: new UntypedFormControl(null),
  });



  ngOnInit(): void {
    // If Component Trigger from vendor module then vendor dropdown component hide
    if (this.isVendorModule) {
      this.moduleId = 19
      this.getStagesOfModule(this.moduleId)
    }

    this.initForm(this.data);

  }

  onModuleSelect(event: MatButtonToggleChange) {
    this.moduleId = event.value
    this.getStagesOfModule(this.moduleId)
  }


  getVendorDetails(vendorId: any) {
    return this.vendorDataService.detail(vendorId).pipe(
      first(),
      map((data) => {
        this.vendorDetail = data.data;
        // Once the inner API call completes successfully, return this.bidDetail
        return this.vendorDetail;
      })
    );
  }


  getStagesOfModule(id: any) {
    this.backendApiService.getApi('stages?module_id=' + id)
      .subscribe((response: any) => {
        this.stages = response.data;
      })
  }


  getFormData = () => {
    if (!this.referenceId || !this.moduleId) {
      this.moduleId = null;
      this.referenceId = null
    }
    const formValue = this.vendorQuoteForm.value;
    return {
      vendor_id: formValue.vendor_id,
      quotation_reference: formValue.quotation_reference,
      quantity: formValue.quantity,
      amount: formValue.amount,
      valid_till: formValue.valid_till,
      module_id: this.moduleId,
      reference_id: this.referenceId
    };
  }


  save = () => {
    this.touchFormControls();
    if (this.vendorQuoteForm.invalid) {
      return;
    }
    this.saveBulkVendorPricing();
  }

  initForm = (values: any) => {
    this.vendorQuoteForm.get('quotation_reference')?.setValue(values?.quotation_reference);
    this.vendorQuoteForm.get('vendor_id')?.setValue(this.vendorId ? this.vendorId : values?.vendor?.id);
    this.vendorQuoteForm.get('amount')?.setValue(values?.amount);
    this.vendorQuoteForm.get('quantity')?.setValue(values?.quantity);
    this.vendorQuoteForm.get('module_id')?.setValue(values?.module?.id);
    this.vendorQuoteForm.get('reference_id')?.setValue(values?.reference?.id);
    this.vendorQuoteForm.get('valid_till')?.setValue(values?.valid_till);
    let vendorId = values?.vendor?.id || this.vendorId
    if (vendorId) {
      this.getVendorDetails(vendorId).subscribe();
    }
  }

  saveBulkVendorPricing() {
    const service = this.vendorQuoteId ? this.backendApiService.patchApi(`vendor_quote/${this.vendorQuoteId}`, this.getFormData()) :
      this.backendApiService.postApi('vendor_quote/create', this.getFormData())
    service.subscribe((response: any) => {
      console.log('response', response.data);
      this.toasterService.showSuccessMessage(response.data.message);
      this.close(response.data?.data);
    })
  }


  touchFormControls() {
    Object.keys(this.vendorQuoteForm.controls).forEach(key => {
      this.vendorQuoteForm.get(key)?.markAsTouched();
    });
  }

  close = (data: any = null) => {
    this.vendorQuoteDataService.setVendorQuoteUpdate(true)
    this.dialogRef.close(data);
  }

  selectedValueChange(event: any) {
    console.log('event', event);
    if (event) {
      this.vendorQuoteForm.get('vendor_id')?.setValue(event?.id);
    } else {
      this.vendorQuoteForm.get('vendor_id')?.setValue(null);
    }
  }

  selectedModuleChangeValue(event: any) {
    this.referenceId = event.id
    this.vendorQuoteForm.get('reference_id')?.setValue(event.id);
  }

}
