import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const NoteApiResources = {
  list:  createUrl('notes'),
  create:  createUrl('notes/create'),
  detail:  createUrl('notes'),
  update:  createUrl('notes'),
  delete:  createUrl('notes'),
};
