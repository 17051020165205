import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const CustomerGroupApiResources = {
  list:  createUrl('customer-groups'),
  create:  createUrl('customer-groups'),
  detail:  createUrl('customer-groups'),
  update:  createUrl('customer-groups'),
};
