import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor() { }

  processStaff(staff: any, includeInActiveList: any = []){
    return staff
      .filter((staff : any) => staff.is_terminate != 1 || includeInActiveList.includes(staff.id))
      .map((staff:any)=>{
        if (staff.is_terminate == 1){
          staff.full_name =  `${staff.full_name} (In Active)`;
          // staff.disabled =  true;
        }
        return staff;
      });
  }

  getStaffById(staff: any, id: any){
    return staff.find((x: any) => x.id == id)
  }
}
