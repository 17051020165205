import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ToasterService} from "../../../../services/core/toaster.service";
import {
  ObserverParticipantPopupComponent
} from "../../../sale/task/add-task/add-checklist/observer-participant-popup/observer-participant-popup.component";
import {TicketUserPopupComponent} from "../ticket-user-popup/ticket-user-popup.component";

@Component({
  selector: 'app-ticket-checklist',
  templateUrl: './ticket-checklist.component.html',
  styleUrls: ['./ticket-checklist.component.scss']
})
export class TicketChecklistComponent implements OnInit {
  public isImportant: boolean = false;
  public CONST_OBSERVER_TYPE: string = 'observer';
  public CONST_PARTICIPANT_TYPE: string = 'participant';
  @Output() callback = new EventEmitter();
  @Input() checkLists: any;
  checkListPopulated = false;

  public sectionForm: UntypedFormGroup = new UntypedFormGroup({
    checklists: new UntypedFormArray([]),
  })
  constructor(
    public dialog: NgDialogAnimationService,
    private fb: FormBuilder,
    public toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes',changes);
    if (!this.checkListPopulated){
      if(changes.checkLists?.currentValue?.length > 0) {
        // this.checklists().clear();
        this.checkLists.forEach((item: any) => {
          this.addChecklist(item,true,false);
        })
      }
      this.checkListPopulated = true;
    }
  }

  openObserverParticipantPopup(event: any,type: any,index: any) {
    const position = event.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(TicketUserPopupComponent,{
      width: '400px',
      height: '400px',
      // position: {top:`${position.top +26}px`, left: `${position.left}px`},
      data: {
        attachAbleUser: ['Users'],
        type: type,
        index: index,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result',result);
      if(result) {
        if(result.type == this.CONST_OBSERVER_TYPE) {
          this.addUser(result,'observers')
          // const value = this.checklists().at(result.index)?.get('observers')?.getRawValue();
          // value.push(result.user);
          // this.checklists().at(result.index).get('observers')?.setValue(value);
        } else if(result.type == this.CONST_PARTICIPANT_TYPE) {
          this.addUser(result,'participants')
          // const value = this.checklists().at(result.index)?.get('participants')?.getRawValue();
          // value.push(result.user);
          // this.checklists().at(result.index).get('participants')?.setValue(value);
        }
      }
    })
  }
  addUser(data: any, type:any){
    const usersList = this.checklists().at(data.index)?.get(type)?.getRawValue();
    const alreadyExistCheck = usersList.find((x:any) => x.id == data?.user.id);
    if (!alreadyExistCheck){
      usersList.push(data.user);
      this.checklists().at(data.index)?.get(type)?.setValue(usersList);
    }

  }
  removeCheckList(index: any) {
    this.checklists().removeAt(index);
  }

  isImportantCheckBox(index: any) {
    const value = this.checklists().at(index).get('is_important')?.value;
    if(value == true) {
      this.checklists().at(index).get('is_important')?.setValue(false);
    } else {
      this.checklists().at(index).get('is_important')?.setValue(true);
    }
    // this.isImportant = !this.isImportant;
    this.getDataForParent();
  }

  checklists(): UntypedFormArray {
    return this.sectionForm.get('checklists') as UntypedFormArray;
  }

  addChecklist(checklist: any = '',viewMode: boolean = false,editMode:boolean = true) {
    this.checklists().push(this.newChecklist(checklist,viewMode,editMode));
    this.getDataForParent();
  }


  newChecklist(checklist: any = '',viewMode: boolean = false,editMode:boolean = true) {
    return this.fb.group({
      id: [checklist ? checklist?.id : ''],
      text: [checklist ? checklist?.text : ''],
      observers: [checklist ? checklist?.observers : []],
      participants: [checklist ? checklist?.participants : []],
      is_completed: [checklist ? checklist?.is_completed : false],
      is_important: [checklist ? checklist?.is_important : false],
      view_mode: [viewMode],
      edit_mode: [editMode],
    })
  }

  getDataForParent() {
    const checklists = this.sectionForm.get('checklists')?.value;
    console.log('getDataForParent', checklists);
    this.callback.emit(checklists);
  }

  removeUsers(index: any,dataArray: any) {
    dataArray.splice(index,1);
    this.getDataForParent();
  }

  ediViewChecklist(index: any,viewMode:boolean = false,editMode:boolean = false) {
    const text = this.checklists().at(index).get('text')?.value;
    if(!text) {
      this.checklists().removeAt(index);
      return;
    }
    if(editMode) {
      this.checklists().at(index).get('view_mode')?.setValue(false);
      this.checklists().at(index).get('edit_mode')?.setValue(true);
    }
    if(viewMode) {
      this.checklists().at(index).get('view_mode')?.setValue(true);
      this.checklists().at(index).get('edit_mode')?.setValue(false);
    }

    this.getDataForParent();
  }
  onChangeCheckbox(e: any){
     this.getDataForParent();
  }
  getChipNameUsingType( user: any) {
    let name = '--';
    if (user?.full_name){
      name = user?.full_name;
    }else if (user?.name){
      name = user?.name;
    }
    else if (user?.company_name){
      name = user?.company_name;
    }
    else if (user?.full_title) {
      name = user?.full_title;
    }
    return name;
  }
}
