import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BackendApiService} from "../../../../services/backend-api.service";
import {ToasterService} from "../../../../services/core/toaster.service";
import {ConfirmationPopupComponent} from "../../../shared/confirmation-popup/confirmation-popup.component";

@Component({
  selector: 'app-ticket-comments',
  templateUrl: './ticket-comments.component.html',
  styleUrls: ['./ticket-comments.component.scss']
})
export class TicketCommentsComponent implements OnInit {
  @Input() model: any;
  module_id: any = 8;
  attachedFiles: any = [];
  allowUploadFile: boolean = false;
  multipleUpload: boolean = true;
  allowedFileTypes: any = ['.jpg', '.jpeg', '.gif', '.png','.pdf'];
  statuses: any = [];
  attachmentsArr = [];
  attachmentsToAdd = [];
  comments:any =[];
  ticketID: any = '';
  public sectionForm: UntypedFormGroup = new UntypedFormGroup({
    note: new UntypedFormControl(null,[Validators.required]),
    attached_file: new UntypedFormControl([]),
  })
  constructor(
    public backendApiService: BackendApiService,
    public toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    console.log('this?.model co', this?.model);
    this.ticketID = this?.model?.id;
    this.getTicketCommentsByID();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.model = changes?.model?.currentValue;
  //   if(this.model?.id) {
  //     this.getTicketCommentsByID();
  //   }
  // }

  getTicketCommentsByID() {
    this.backendApiService.postApi('ticket/get-comments/'+this.model?.id,'')
      .subscribe((response: any) => {
        this.comments = response.data?.data;
      })
  }

  getFormData() {
    const form = this.sectionForm?.value;
    return {
      ticket_id: this.model?.id,
      note: form?.note,
      attached_files: this.attachedFiles
    }
  }

  save() {
    if(this.sectionForm.invalid) {
      this.toasterService.showErrorMessage('Please type notes');
      return;
    }
    this.backendApiService.postApi(`ticket/add-comment/${this.ticketID}`,{comment:this.getFormData().note, attachment_ids:this.getFormData().attached_files})
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response?.data?.message);
        // adds new notes to the notes array
        this.allowUploadFile = false;
        this.getTicketCommentsByID();
        this.attachedFiles = [];
        this.attachmentsArr = [];
        this.allowUploadFile = false;
        this.sectionForm.get('note')?.setValue(null);
      })
  }


  fileUpload() {
    this.allowUploadFile = !this.allowUploadFile;
  }

  attachment(event: any) {
    const data  = event?.attachment;
    if(Array.isArray(data)){
      this.attachmentsArr = data as any;
      this.attachmentsToAdd = this.attachmentsArr.map((file: any) => file.attachment_id) as any;
    }
    this.attachedFiles =  this.attachedFiles.concat(this.attachmentsToAdd);
  }

  downloadFile(file: any) {
    console.log('file',file?.url);
  }

  getTicketStatuses() {
    this.backendApiService.getApi('statuses/ticket')
      .subscribe((response: any) => {
        this.statuses = response?.data;
      })
  }

  getNameInitials(name: any) {
    return name.substring(0,2)
  }


  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action (new line)
      this.save();
    }
  }
}
