import { Injectable } from '@angular/core';
import {first} from "rxjs/operators";
import {SharedDataService} from '../shared/shared.data.service';
import {TenantDataService} from "./tenant.data.service";

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  public staffs: any;

  constructor(
    private tenantDataService: TenantDataService
  ) {}

  getStaffs = () => {
   return this.tenantDataService.getStaffs();
  }
}
