import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const ChangeOrderApiResources = {
  list:  createUrl('change/orders'),
  create:  createUrl('change/orders/create'),
  createFromJob:  createUrl('change/orders/create-by-job'),
  detail:  createUrl('change/orders'),
  update:  createUrl('change/orders'),
  delete:  createUrl('change/orders'),
  changeStatus: createUrl('change/orders/update-status'),
  updateDisplayOptions:  createUrl('change/orders/display-options'),
  getSettings:  createUrl('change-order-setting'),
  getCostDrillDown:  createUrl('change/orders/cost-drill-down'),
  accept:  createUrl('change/orders/{id}/accept'),
  reverse:  createUrl('change/orders/{id}/reverse'),
};
