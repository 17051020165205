import { Component, OnInit } from '@angular/core';
import {RightPanelHideShowService} from "../../../services/right-panel-hide-show.service";
import { DataTableConfigurationService } from 'src/app/services/core/datatableConfiguration.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(
    public dataTableConfigurationService: DataTableConfigurationService,
    public panelHideShow: RightPanelHideShowService
  ) { }

  ngOnInit(): void {
    this.panelHideShow.hideRightSidePanel();

    this.dataTableConfigurationService
    .getDataTableConfiguration()
    .subscribe(data => {
        data.data.forEach((item:any) => {
          localStorage.setItem(item.module_name, item.configurations)
        });
    })

  }

}
