import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { startWith, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-final-preview',
  templateUrl: './final-preview.component.html',
  styleUrls: ['./final-preview.component.scss']
})
export class FinalPreviewComponent implements OnInit {
  @Input() model: any = {};
  @Input() form: any = {};
  @Output() callback = new EventEmitter<any>(true);

  public company: any = {};
  public detail: any = {};
  public contact: any = {};
  public adminInfo: any = {};
  showPassword:Boolean=false;


  constructor() { }

  ngOnInit(): void {

    this.model = this.model?.model;
    this.company.name = this.model?.company_name;
    this.detail = this.model?.detail;
    this.contact = this.model?.contacts[0];
    this.adminInfo =  this.model?.admin_info;

    this.form.get('step01').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.company = next ?.company;
      });

    this.form.get('step02').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.detail = next ?.detail;
      });

    this.form.get('step03').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.contact = next ?.contact;
      });

    this.form.get('step04').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.adminInfo = next ?.adminInformation;
        if (this.adminInfo?.risk_level){
          this.adminInfo.risk_level = this.adminInfo?.risk_level?.toString().replace(',','');
          this.adminInfo.risk_level = this.adminInfo?.risk_level?.toString().replace('$','');
        }
      });
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password');
    if (passwordInput) {
      passwordInput.setAttribute('type', this.showPassword ? 'text' : 'password');
    }
  }

}
