import { Component, OnInit, ElementRef, Inject, AfterViewInit } from '@angular/core';
import {UserService} from '../../../services/core/user.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit , AfterViewInit {

  public user: any;
  public isSideBarOpen:boolean = false;

  constructor(
    private currentUser: UserService,
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.user = this.currentUser;
  }

  onMobileToggaleSideBarOpen = () =>{
    this.document.body.classList.add('sidebar-open');
  }

  onToggaleSideBar = () =>{
     if(this.isSideBarOpen){
      this.document.body.classList.remove('sidebar-pinned');
      this.isSideBarOpen = false;
    }
    else{
      this.document.body.classList.add('sidebar-pinned');
      this.isSideBarOpen = true;
    }

  }


  ngAfterViewInit(){}
}
