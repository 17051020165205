import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ToasterService} from "../../../../services/core/toaster.service";
import {BackendApiService} from "../../../../services/backend-api.service";
import {
  ObserverParticipantPopupComponent
} from "../../../sale/task/add-task/add-checklist/observer-participant-popup/observer-participant-popup.component";

@Component({
  selector: 'app-ticket-checklists',
  templateUrl: './ticket-checklists.component.html',
  styleUrls: ['./ticket-checklists.component.scss']
})
export class TicketChecklistsComponent implements OnInit {
  public isImportant: boolean = false;
  public CONST_OBSERVER_TYPE: string = 'observer';
  public CONST_PARTICIPANT_TYPE: string = 'participant';
  @Output() callback = new EventEmitter();
  @Input() checkLists: any;
  @Input() model: any = {};

  public sectionForm: UntypedFormGroup = new UntypedFormGroup({
    checklists: new UntypedFormArray([]),
  })
  constructor(
    public dialog: NgDialogAnimationService,
    private fb: FormBuilder,
    public toasterService: ToasterService,
    public backendApiService: BackendApiService
  ) { }

  ngOnInit(): void {
    console.log('dara onit',this.checkLists);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes',changes);
    this.model = changes.model?.currentValue;
    if(changes.checkLists?.currentValue?.length > 0) {
      this.checklists().clear();
      this.checkLists.forEach((item: any) => {
        this.addChecklist(item,true,false);
      })
    }
  }

  openObserverParticipantPopup(event: any,type: any,index: any) {
    const position = event.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(ObserverParticipantPopupComponent,{
      width: '400px',
      height: '400px',
      // position: {top:`${position.top +26}px`, left: `${position.left}px`},
      data: {
        type: type,
        index: index,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result',result);
      if(result) {
        if(result.type == this.CONST_OBSERVER_TYPE) {
          const value = this.checklists().at(result.index)?.get('observers')?.getRawValue();
          value.push(result.user);
          this.checklists().at(result.index).get('observers')?.setValue(value);
        } else if(result.type == this.CONST_PARTICIPANT_TYPE) {
          const value = this.checklists().at(result.index)?.get('participants')?.getRawValue();
          value.push(result.user);
          this.checklists().at(result.index).get('participants')?.setValue(value);
        }
        this.updateChecklistAtBackend(result.index);
      }
    })
  }

  removeCheckList(index: any) {
    const id = this.checklists().at(index).get('id')?.value;
    this.checklists().removeAt(index);
    this.backendApiService.deleteApi('ticket/checklist/'+id)
      .subscribe((response: any) => {

      })
  }

  isImportantCheckBox(index: any) {
    const value = this.checklists().at(index).get('is_important')?.value;
    if(value == true) {
      this.checklists().at(index).get('is_important')?.setValue(false);
    } else {
      this.checklists().at(index).get('is_important')?.setValue(true);
    }
    // this.isImportant = !this.isImportant;
    this.updateChecklistAtBackend(index);
  }

  checklists(): UntypedFormArray {
    return this.sectionForm.get('checklists') as UntypedFormArray;
  }

  addChecklist(checklist: any = '',viewMode: boolean = false,editMode:boolean = true) {
    this.checklists().push(this.newChecklist(checklist,viewMode,editMode));
  }

  newChecklist(checklist: any = '',viewMode: boolean = false,editMode:boolean = true) {
    return this.fb.group({
      id: [checklist ? checklist?.id : ''],
      text: [checklist ? checklist?.text : 'text'],
      observers: [checklist ? checklist?.observers : []],
      participants: [checklist ? checklist?.participants : []],
      is_completed: [checklist ? checklist?.is_completed : false],
      is_important: [checklist ? checklist?.is_important : false],
      view_mode: [viewMode],
      edit_mode: [editMode],
    })
  }

  getDataForParent() {
    const checklists = this.sectionForm.get('checklists')?.value;
    this.callback.emit(checklists);
  }

  removeUsers(index: any,dataArray: any) {
    dataArray.splice(index,1);
  }

  ediViewChecklist(index: any,viewMode:boolean = false,editMode:boolean = false) {
    const text = this.checklists().at(index).get('text')?.value;
    /*if(!text) {
      this.checklists().removeAt(index);
      return;
    }*/
    if(editMode) {
      this.checklists().at(index).get('edit_mode')?.setValue(true);
      this.checklists().at(index).get('view_mode')?.setValue(false);
    }
    if(viewMode) {
      this.checklists().at(index).get('view_mode')?.setValue(true);
      this.checklists().at(index).get('edit_mode')?.setValue(false);
    }

    if(viewMode) {
      this.getDataForParent();
      this.updateChecklistAtBackend(index);
    }
  }

  onClickAddNewChecklist() {
    this.addChecklist();
    this.saveChecklistAtBackend(this.checklists().length - 1)
  }

  saveChecklistAtBackend(index: any) {
    if(!this.model?.id) {
      this.toasterService.showWarning('ticket Id not Found!');
      return;
    }
    const checklist = this.checklists().at(index)?.value;
    const data = {
      id: checklist?.id,
      text: checklist?.text,
      observers: checklist?.observers,
      participants: checklist?.participants,
      is_completed: checklist?.is_completed,
      is_important: checklist?.is_important,
      ticket_id: this.model?.id,
    }
    this.backendApiService.postApi('ticket/add/checklist',data)
      .subscribe((response: any) => {
        console.log('response',response);
        this.checklists().at(index).get('id')?.setValue(response.data?.data?.id);
      })
  }

  updateChecklistAtBackend(index: any){
    if(!this.model?.id) {
      this.toasterService.showWarning('Ticket Id not Found!');
      return;
    }
    const checklist = this.checklists().at(index)?.value;
    const data = {
      id: checklist?.id,
      text: checklist?.text,
      observers: checklist?.observers,
      participants: checklist?.participants,
      is_completed: checklist?.is_completed,
      is_important: checklist?.is_important,
      // task_id: this.model?.id,
    }
    this.backendApiService.patchApi(`ticket/update/checklist/${checklist?.id}`,data)
      .subscribe((response: any) => {
        console.log('response',response);
      })
  }


}
