import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FormFieldComponentsCommunicationService {

  private newCreatedContactFromBidInfo = new Subject<any>();


  setNewCreatedContactFromBidInfo(data: any) {
    this.newCreatedContactFromBidInfo.next(data);
  }

  getNewCreatedContactFromBidInfo(): Observable<any> {
    return this.newCreatedContactFromBidInfo.asObservable();
  }


}
