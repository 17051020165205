import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl,UntypedFormControl,UntypedFormGroup,NG_VALIDATORS,NG_VALUE_ACCESSOR,ValidationErrors,Validators} from "@angular/forms";
import { CompanyDetailComponent } from '../../../customer/form-part/company-detail/company-detail.component';
import {CoreService} from "../../../../services/core/core.service";
import {SharedService} from "../../shared.service";
import {pairwise, startWith} from "rxjs/operators";
import {Subscription} from "rxjs";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { NotOnlySpaces } from '../../../shared/CustomValidators/CustomNotOnlySpacesValidator';
import { ZipCodeValidators } from '../../../shared/CustomValidators/CustomZipCodeValidation';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})
export class AddressComponent implements OnInit, OnChanges {

  @Input() form?: any = '';
  @Input() model: any = {};
  @Input() isRequired: boolean = false;
//  @Input() isRequiredtrue:boolean=true;
  @Input() populateFormOnChanges: any = false;
  @Output() callback = new EventEmitter<any>(true);

  public randomID:number;
  public staric: string = '*';
  public onModelValueChanged: any;
  // @ts-ignore
  private parentFormSubscription: Subscription;
  public e: any;

  public addressForm: UntypedFormGroup = this.initForm();
  options = {
    types: [],
    componentRestrictions: { country: 'UA' }
  }
  constructor(
    public coreService: CoreService,
    public sharedService: SharedService,
    private http: HttpClient
  ) {
    this.randomID = this.coreService.getRandomInteger();
  }
  @ViewChild("placesRef") placesRef!: GooglePlaceDirective;
  mapOptions:any="{fields: ['address_components', 'geometry'],types: ['address'],}" ;

  ngOnChanges(changes: SimpleChanges) {
    if (this.populateFormOnChanges){
      this.updateFormValues(this.model);
    }
    // this.model = changes?.model?.currentValue;
  }


  ngOnInit(): void {
    this.sharedService.getStates(231);
    this.updateFormValidatorOpts();
    this.updateStaricString();
    this.initSubscriptions();
    this.initFormControlValues(this.model);
    console.log('adreess -- -- --', this.model);
  }



  initFormControlValues(value: any){
    if (value){
      this.addressForm = this.initForm(value);
      this.callback.emit(this.addressForm);
    }
    return this.addressForm;
  }
  omit_special_char(event: KeyboardEvent): boolean {
    const k = event.keyCode || event.which;
    // Allow numbers (0-9), backspace, space, hyphen, and parentheses
    return (k >= 48 && k <= 57) || k == 8 || k == 32 || k == 45 || k == 40 || k == 41;
  }
  initForm(value: any =''){
   return  new UntypedFormGroup({
      address_line_1:new UntypedFormControl(value ? value.address_line_1: null,this.isRequired ? [Validators.required,NotOnlySpaces()] : []),
      address_line_2:new UntypedFormControl(value ? value.address_line_2: null),
      state_id: new UntypedFormControl(value ? value.relations.state: null, this.isRequired ? [Validators.required,NotOnlySpaces()] : []),
      city:new UntypedFormControl(value ? value.city: null, this.isRequired ? [Validators.required,NotOnlySpaces()] : []),
      zipcode:new UntypedFormControl(value ? value.zipcode: null, this.isRequired ? [Validators.required,ZipCodeValidators()] : [ZipCodeValidators()]),

    });
  }
  preventSpace(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
  updateFormValues(value: any =''){
    this.addressForm.get('address_line_1')?.setValue(value ? value.address_line_1: null);
    this.addressForm.get('address_line_2')?.setValue(value ? value.address_line_2: null);
    this.addressForm.get('state_id')?.setValue(value ? value.relations.state: null);
    this.addressForm.get('city')?.setValue(value ? value.city: null);
    this.addressForm.get('zipcode')?.setValue(value ? value.zipcode: null);
    // this.callback.emit(this.addressForm);
  }
  updateStaricString = () => {
    if (this.isRequired) {
      this.staric = '*';
      return;
    }
    this.staric = '';
  }

  updateFormValidatorOpts = () => {
    Object.keys(this.addressForm.controls).map((controlName) => {
      if (this.isRequired) {
        if(this.addressForm.controls[controlName] != this.addressForm.controls['address_line_2']) {
          if (this.addressForm.controls[controlName] == this.addressForm.controls['zipcode']){
            this.addressForm.controls[controlName].setValidators([Validators.required,ZipCodeValidators()]);
          }
          else {
            this.addressForm.controls[controlName].setValidators([Validators.required,NotOnlySpaces()]);
          }
        }
      } else {
        this.addressForm.controls[controlName].clearValidators();

        if (this.addressForm.controls[controlName] == this.addressForm.controls['zipcode']){
          this.addressForm.controls[controlName].setValidators([ZipCodeValidators()]);
        }
      }
      this.addressForm.controls[controlName].updateValueAndValidity();
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.addressForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.addressForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.addressForm.disable() : this.addressForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.addressForm.valid ? null : { invalidForm: {valid: false, message: "Address Form fields are invalid"}};
  }
  initSubscriptions(){
    console.log(this.form);
    if (this.form){
      this.parentFormSubscription = this.form.valueChanges
          .pipe(startWith(null), pairwise())
          .subscribe(([prev, next]: [any, any]) => {
            if (this.form.touched){
              this.touchFormControls();
            }
          });
    }

  }
  touchFormControls(){
    Object.keys(this.addressForm.controls).forEach(key => {
      this.addressForm.get(key)?.markAsTouched();
    });
  }


  public handleAddressChange(event: Address,formControl:any) {
    this.addressForm.reset()
    let address_line_1: string = '';
    let address_line_2: string = '';
    const place = event;
    console.log('place?.formatted_address',place);
    // this.addressForm.get(formControl)?.setValue(place?.formatted_address);

    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
      // console.log('component',componentType);

      switch (componentType) {
        case "street_number": {
          address_line_1 = component.long_name;
          // this.addressForm.get('address_line_1')?.setValue(component.long_name);
          break;
        }
        case "route": {
          address_line_1 = address_line_1 + ' ' + component.long_name;
          this.addressForm.get('address_line_1')?.setValue(address_line_1);
          // this.addressForm.get('address_line_2')?.setValue(component.short_name);
          break;
        }

        case "postal_code": {
          this.addressForm.get('zipcode')?.setValue(component.long_name);
          break;
        }
        case "administrative_area_level_3": {
          this.addressForm.get('city')?.setValue(component.long_name);
          break;
        }
        case "locality": {
          this.addressForm.get('city')?.setValue(component.long_name);
          break;
        }
        case "administrative_area_level_1": {
          const state = this.sharedService.states.find((x:any) => x.name == component.long_name);
          console.log('state',state);
          this.addressForm.get('state_id')?.setValue(state);
          break;
        }
        case "subpremise": {
          address_line_2 = component.long_name
          this.addressForm.get('address_line_2')?.setValue(address_line_2);
          break;
        }
        //
        // case "sublocality_level_2": {
        //   address_line_2 = address_line_2 + ' ' + component.long_name
        //   this.addressForm.get('address_line_2')?.setValue(address_line_2);
        //   break;
        // }
        //
        // case "sublocality_level_1": {
        //   address_line_2 = address_line_2 + ' ' + component.long_name
        //   this.addressForm.get('address_line_2')?.setValue(address_line_2);
        //   break;
        // }
      }


    }
    // this.getCityAndState(place?.place_id)
  }

  getCityAndState(place_id: any) {

    const apiKey = 'AIzaSyAqdhQzXSYIh-m7QT3CMypCwUoYUypvThw';
      const placeId = place_id; // The unique identifier of the place you're interested in

    const apiUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`;
    let header: any= new HttpHeaders();
    this.http.get(apiUrl,{headers:{"Access-Control-Allow-Origin": "*","Access-Control-Allow-Methods": "*",}}).subscribe((response: any) => {
      console.log('response for google api',response);

        const addressComponents = response.result.address_components;
        let city, state, zipCode;

        for (const component of addressComponents) {
          if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.short_name;
          } else if (component.types.includes('postal_code')) {
            zipCode = component.short_name;
          }
        }

        console.log('City:', city);
        console.log('State:', state);
        console.log('Zip Code:', zipCode);

    });
  }



  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.parentFormSubscription?.unsubscribe();
  }

}
