import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements OnInit {

  @Input() model: any = {};
  @Output() callback = new EventEmitter<any>(true);

  public datePickerForm: UntypedFormGroup = new UntypedFormGroup({
    subject: new UntypedFormControl(this.model.subject,[Validators.required]),
  });

  // // for dateTime Start
  public enableMeridian = true;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  public stepSecond = 1;
  public stepMinute = 1;
  public stepHour = 1;
  public showSeconds = false;
  public showSpinners = true;
  public disabled = false;
  // for dateTime End

  constructor() { }

  ngOnInit(): void {
  }

}
