import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploader-preview',
  templateUrl: './uploader-preview.component.html',
  styleUrls: ['./uploader-preview.component.scss']
})
export class UploaderPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
