import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BackendApiService} from "../../../../services/backend-api.service";

@Component({
  selector: 'app-ticket-history',
  templateUrl: './ticket-history.component.html',
  styleUrls: ['./ticket-history.component.scss']
})
export class TicketHistoryComponent implements OnInit,OnChanges {
  @Input() model: any ={};
  ticketHistories: any  = [];
  constructor(
      public backendApiService: BackendApiService
  ) { }

  ngOnInit(): void {
    this.getTaskHistory();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
    if(this.model?.id) {
      this.getTaskHistory();
    }
  }

  getTaskHistory() {
    this.backendApiService.getApi('ticket-history?ticket_id='+this.model?.id)
        .subscribe((response: any) => {
          this.ticketHistories = response.data;
        })
  }
}
