import {Directive, HostListener, Input} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appPositiveValueMask]'
})
export class PositiveValueMaskDirective {
  @Input('minValue') minValue: number = 0;  // Default minimum value is 0
  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value, true);
  }

  @HostListener('keypress', ['$event'])
  keypress(event: any) {
    const k = event.keyCode || event.which;
    const isNumber = (k >= 48 && k <= 57); // Allow numbers only
    if (!isNumber) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    if (!/^\d+$/.test(pastedText)) {
      event.preventDefault();
    }
  }

  onInputChange(event: any, backspace: boolean) {
    let newVal = event;
    if (backspace) {
      newVal = newVal.slice(0, -1);
    }
    // Convert to number and check against the minimum value
    const numericValue = parseInt(newVal, 10);
    if (numericValue < this.minValue) {
      newVal = this.minValue.toString();
    }

    this.ngControl?.valueAccessor?.writeValue(newVal);
    this.ngControl?.control?.patchValue(newVal, {emitModelToViewChange: false});
    // this.ngControl?.control?.setValue(newVal, { emitModelToViewChange: true });
  }

}
