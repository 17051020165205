<div class="task-detail-wrapper" [formGroup]="sectionForm">
  <div class="task-detail">
    <!-- task detail header started -->
    <div class="task-detail-header">
      <div class="row">
        <div class="col-md-1">
          <div class="close-btn" (click)="close()">
            <img src="assets/img/cross_icon.svg">
          </div>
        </div>
        <div class="col-md-7">
          <div class="task-name">
            <p>{{model?.name}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="header-buttons">
            <!--            <div class="mr-2 setting-icon disabled">-->
            <!--              <mat-icon>notifications</mat-icon>-->
            <!--            </div>-->

            <!--            <div class="setting-icon">-->
            <!--              <mat-icon>settings</mat-icon>-->
            <!--            </div>-->
            <div class="mr-2 general_actions_wrapper">
              <!--              <div class="file_export_wrap">-->
              <a class="file_export_wrap" matTooltip="Export to PDF" [appPermission]="exportPDFPermission"
                (click)="generatePDF()">
                <img src="../../../../assets/img/general_icons/pdf_icon.png" alt="icon">
              </a>
              <!--              </div>-->
            </div>

            <div class="mr-2 general_actions_wrapper">
              <button class="status_wrapper" mat-icon-button [matMenuTriggerFor]="statusMenu"
                [appPermission]="changeStatusPermission" aria-label="Example icon-button with a menu">
                <span class="wrap">Status

                  <span class="status"
                    [ngStyle]="{
                    'border': '3px solid '+ ( model?.status_label?.color),'background-color': getBackgroundColor(model?.status_label?.color)}">
                    <span [ngStyle]="{ 'color':  ( model?.status_label?.color)}"> <span class="status_circle"
                        [ngStyle]="{ 'background-color':  model?.status_label?.color}"></span>
                      <span>
                        {{model?.status_label?.name ? (model?.status_label?.name |truncate:10) : '--'}}
                      </span>
                    </span>
                    <span class="status-lock-icon">
                      <mat-icon *ngIf="model?.status_label?.lock_status==1"
                        [ngStyle]="{'color':model?.status_label?.color ||'#808080'}" matTooltip="Status Locked"
                        aria-hidden="false" matTol aria-label="Example home icon" fontIcon="lock"></mat-icon>
                    </span>
                    <img src="../../../../assets/img/general_icons/edit_icon.png" alt=""></span>

                </span>
              </button>

              <mat-menu #statusMenu="matMenu" class="status_wrapper_dropdown">
                <ng-container *ngFor="let status of statuses">
                  <button (click)="onChangeStatus(status)" class="wrap" mat-menu-item>
                    <span class="btn_status_container"
                      [ngStyle]="{'border': '1px solid '+status.color,'background-color': getBackgroundColor(status.color ||'#808080')}">
                      <span class="status_circle" [ngStyle]="{ 'background-color':  status.color}"> <span
                          class="status-label" [ngStyle]="{ 'color':  status.color}"> {{status?.name
                          |truncate:10}}</span> </span>

                      <span *ngIf="status?.lock_status==1" class="status-lock-icon">
                        <mat-icon [ngStyle]="{'color':status.color ||'#808080'}" matTooltip="Status Locked"
                          aria-hidden="false" matTol aria-label="Example home icon" fontIcon="lock"></mat-icon>
                      </span>
                    </span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <div class="templates" [matMenuTriggerFor]="taskTemplates">
              Action
            </div>

            <mat-menu #taskTemplates="matMenu">
              <button mat-menu-item [appPermission]="createPermission" (click)="createTicket()">New Ticket</button>
              <!--              <button mat-menu-item (click)="close()" [routerLink]="['ticket-templates']" >Template list</button>-->
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- task detail header ended -->
    <div class="task-detail-body">
      <div class="row">

        <div class="col-md-9">
          <div class="left-side">
            <div class="card">
              <div class="card-header">
                <div class="title">{{model?.code}} - Pending</div>
                <div class="priority">High Priority
                  <img *ngIf="model?.priority == 3" style="width: 17px;"
                    src="../../../../../assets/img/general_icons/color-fire.png" alt="">
                  <img *ngIf="model?.priority != 3" style="width: 17px;"
                    src="../../../../../assets/img/general_icons/light-fire.png" alt="">
                </div>
              </div>

              <div class="card-body">
                <div class="description">{{model?.description}}</div>
              </div>

              <div class="checklists">
                <app-ticket-checklists [model]="model" [checkLists]="model?.check_list"></app-ticket-checklists>
              </div>

              <div class="task-buttons">
                <!--<button type="button" class="btn task-btn">Start</button>
                <button type="button" class="btn task-btn">Pause</button>-->
                <!--<div class="stages dropdown" [matMenuTriggerFor]="taskStages">
                  {{ this.model?.stage?.name ?? 'Stage' }}
                </div>

                <mat-menu #taskStages="matMenu">
                  <button mat-menu-item *ngFor="let stage of stages" (click)="addStage(stage)">{{ stage.name }}</button>
                </mat-menu>-->

                <div class="stages dropdown" [matMenuTriggerFor]="more">
                  More...
                </div>

                <mat-menu #more="matMenu">
                  <button mat-menu-item [appPermission]="editPermission" (click)="editTask()">Edit Ticket</button>
                  <button mat-menu-item [appPermission]="deletePermission" (click)="onConfirmation()">Delete</button>
                </mat-menu>
              </div>
            </div>

            <div class="tabs bid_tabs_cards">
              <mat-tab-group>
                <mat-tab label="Comment" class="nav-link">
                  <ng-template matTabContent>
                    <div class="tabs-bg">
                      <app-ticket-comments *ngIf="model" [model]="model"></app-ticket-comments>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Attachments" class="nav-link">
                  <ng-template matTabContent>
                    <app-files-preview *ngIf="model" [filesData]="model?.relations?.attach_files"></app-files-preview>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Time elapsed" class="nav-link">
                  <ng-template matTabContent>
                    <div class="tabs-bg">
                      <app-ticket-time-elapsed *ngIf="model" [model]="model"></app-ticket-time-elapsed>
                    </div>
                  </ng-template>
                </mat-tab>

                <mat-tab label="History" class="nav-link">
                  <ng-template matTabContent>
                    <div class="tabs-bg">
                      <app-ticket-history *ngIf="model" [model]="model"></app-ticket-history>
                    </div>
                  </ng-template>
                </mat-tab>




              </mat-tab-group>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="right-side">
            <div class="card">
              <div class="card-header">
                <span class="card-label">Pending</span>
                <!--                <span>since 12/29/2023 9:29 pm</span>-->
              </div>

              <div class="card-body">
                <div class="task-items">
                  <div class="task-item">
                    <span class="item-title">Deadline:</span>
                    <span class="item-value">{{model?.due_date_formatted }}</span>
                  </div>

                  <div class="task-item">
                    <span class="item-title">Created on:</span>
                    <span class="item-value">{{model?.created_on }}</span>
                  </div>

                  <!--<div class="task-item">
                    <span class="item-title">Rating:</span>
                    <span class="item-value">none</span>
                  </div>-->
                </div>

                <!--                <div class="video-call">-->
                <!--                  <span class="video-call-icon"></span>-->
                <!--                  <span class="text"> HD Video Call</span>-->
                <!--                </div>-->

                <div class="task-widgets">

                  <div class="task-widget">
                    <div class="widget-title">Created by:</div>
                    <div class="users-list">
                      <div class="user-detail">
                        <div class="user-avatar-1"></div>
                        <div class="user-name">{{model?.created_by?.full_name}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="task-widget">
                    <div class="widget-title d-flex justify-content-between">
                      <p class="title">Responsible Person:</p>
                      <div class="change" (click)="openUserPopup($event,CONST_RESPONSIBLE_PERSON_TYPE)"> Add</div>
                    </div>
                    <div class="users-list">
                      <div class="user-detail" *ngFor="let person of model?.responsible_persons">
                        <div class="user-avatar">
                          <img src="{{getChipIconUsingType(person?.type)}}" alt="">
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="user-name" *ngIf="person?.attachable?.first_name">
                            {{person?.attachable?.first_name}} {{person?.attachable?.last_name}}</div>
                          <div class="user-name" *ngIf="person?.attachable?.company_name">
                            {{person?.attachable?.company_name}}</div>
                          <div class="user-name" *ngIf="person?.attachable?.name">{{person?.attachable?.name}}</div>
                          <div class="image" (click)="deleteAssociation(person)">
                            <img src="../../../../../assets/img/general_icons/cross1.png" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="task-widget">
                    <div class="widget-title d-flex justify-content-between">
                      <p class="title">Observer:</p>
                      <div class="change" (click)="openUserPopup($event,CONST_OBSERVER_TYPE)"> Add</div>
                    </div>
                    <div class="users-list">
                      <div class="user-detail" *ngFor="let observer of model?.observers">
                        <div class="user-avatar">
                          <img src="{{getChipIconUsingType(observer?.type)}}" alt="">
                        </div>
                        <div class="d-flex justify-content-between">
                          <!--                          <div class="user-name">{{observer?.attachable?.first_name}} {{observer?.attachable?.last_name}}</div>-->
                          <div class="user-name" *ngIf="observer?.attachable?.first_name">
                            {{observer?.attachable?.first_name}} {{observer?.attachable?.last_name}}</div>
                          <div class="user-name" *ngIf="observer?.attachable?.company_name">
                            {{observer?.attachable?.company_name}}</div>
                          <div class="user-name" *ngIf="observer?.attachable?.name">{{observer?.attachable?.name}}</div>
                          <div class="image" (click)="deleteAssociation(observer)">
                            <img src="../../../../../assets/img/general_icons/cross1.png" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="task-widget">
                    <div class="widget-title d-flex justify-content-between">
                      <p class="title">Participant:</p>
                      <div class="change" (click)="openUserPopup($event,CONST_PARTICIPANT_TYPE)"> Add</div>
                    </div>
                    <div class="users-list">
                      <div class="user-detail" *ngFor="let participant of model?.participants">
                        <div class="user-avatar">
                          <img src="{{getChipIconUsingType(participant?.type)}}" alt="">
                        </div>
                        <div class="d-flex justify-content-between">
                          <!--                          <div class="user-name">{{participant?.attachable?.first_name}} {{participant?.attachable?.last_name}}</div>-->
                          <div class="user-name" *ngIf="participant?.attachable?.first_name">
                            {{participant?.attachable?.first_name}} {{participant?.attachable?.last_name}}</div>
                          <div class="user-name" *ngIf="participant?.attachable?.company_name">
                            {{participant?.attachable?.company_name}}</div>
                          <div class="user-name" *ngIf="participant?.attachable?.name">{{participant?.attachable?.name}}
                          </div>
                          <div class="image" (click)="deleteAssociation(participant)">
                            <img src="../../../../../assets/img/general_icons/cross1.png" alt="">
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
