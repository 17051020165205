import { Injectable } from '@angular/core';
import {VendorDataService} from "./vendor.data.service";
import {VendorGroupService} from "../vendor-group/vendor-group.service";
import {first} from "rxjs/operators";
import {SharedService} from "../../shared/shared.service";
import {TenantService} from "../../tenant/tenant.service";

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  public groups: any;
  public ratios: any;
  public staffs: any;

  constructor(
    public sharedService: SharedService,
    public tenantService: TenantService,
    public vendorDataService: VendorDataService,
    public vendorGroupService: VendorGroupService
  ) {}

  getVendors = () => {
    return this.vendorDataService.list({per_page: 100});
  }

  getVendorGroups = () => {
    this.vendorGroupService.getGroups()
      .pipe(first())
      .subscribe(data => {
        this.groups = data.data
      });
  }

  getTaxAndDiscClasses = () => {
    this.sharedService.getTaxAndDiscClasses()
      .pipe(first())
      .subscribe(
        data => {
          this.ratios = data;
        });
  }

  getStaffs = () => {
    this.tenantService.getStaffs()
      .pipe(first())
      .subscribe(
        data => {
          this.staffs = data.data;
        });
  }
}
