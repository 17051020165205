import {Component, Input, OnInit} from '@angular/core';
import {ToasterService} from "../../../services/core/toaster.service";
import {Form} from "@angular/forms";
import {FileService} from "../../../services/core/file.service";

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @Input() from!: any;
  @Input() name!: string;
  @Input() formData: any;
  @Input() allowedType: any = null;
  @Input() isPreviewShow: boolean = true;
  @Input() isMultiple: boolean = false;
  @Input() isShowFileName:any = false;

  public previewUrl: any = [];
  public fileUploadProgress!: string;
  public uploadedFilePath!: string;
  public FILESIZE: any = 104857600;
  public file: any;
  public files: any = [];
  private type: any;
  public orignalFileName: any;

  constructor(
    public toasterService: ToasterService,
    public fileService: FileService,
  ) { }

  ngOnInit() {
    this.files = [];
  }

  fileProgress(event: any) {

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.file = event.target.files[i];
        if (this.canUpload()) {
          this.orignalFileName = this.file.name;
          var reader = new FileReader();
          reader.onload = ( e: any ) => {
            if (this.isMultiple) {
              this.previewUrl.push({type: this.getFileType(this.getExtension(e.target.result)), path: e.target.result});
            } else {
              this.previewUrl.push({type: this.type, path: e.target.result});
            }
          }
          if (this.isMultiple) {
            this.files.push(<File>this.file);
          } else {
            this.files[0] = <File>this.file;
          }
          reader.readAsDataURL(this.file);
          this.render();
        }
      }
    }
  }

  render = () => {
    if (!this.isMultiple) {
      this.previewUrl.splice(0, 1);
    }
    this.formData.delete(`${this.name}`);
    this.files.forEach( (item: any)  => {
      this.formData.append(`${this.name}`, item);
    });
  }
  remove = (index:any) => {
    this.files.splice(index, 1);
    this.previewUrl.splice(index, 1);
    this.formData.delete(`${this.name}`);
    this.render();
  }

  checkType = (type = null) => {

    if ( this.allowedType === this.fileService.FILE_TYPE_ALL ) {
      switch ( (type) ? type : this.file.type ) {
        case 'image/png'  :
        case 'image/gif' :
        case 'image/jpeg' :
        case 'image/pjpeg' :
          this.type = 'image';
          return true;
          break;
        // case 'text/plain':
        // case 'text/html':
        // case 'application/x-zip-compressed':
        case 'application/pdf':
          this.type = 'pdf';
          return true;
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          this.type = 'word'
          return true;
          break;
        case 'text/csv':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          this.type = 'csv';
          return true;
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          this.type = 'ppt';
          return true;
          break;
        case 'video/mp4':
        case 'video/webm':
        case '':
          this.type = 'video';
          return true;
          break;
        default:
          this.toasterService.showWarning('Unsupported file type!');
          return false;
      }
    } else if ( this.allowedType === this.fileService.FILE_TYPE_NO_VIDEO ) {
      switch ( (type) ? type : this.file.type ) {
        case 'image/png'  :
        case 'image/gif' :
        case 'image/jpeg' :
        case 'image/pjpeg' :
          this.type = 'image';
          return true;
          break;
        // case 'text/plain':
        // case 'text/html':
        // case 'application/x-zip-compressed':
        case 'application/pdf':
          this.type = 'pdf';
          return true;
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          this.type = 'word'
          return true;
          break;
        case 'text/csv':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          this.type = 'csv';
          return true;
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          this.type = 'ppt';
          return true;
          break;
        default:
          this.toasterService.showWarning('Unsupported file type!');
          return false;
      }
    } else {
      type = (type) ? type : this.file.type;

      if (type === '') {
        let splitedStr = this.file.name.split('.');
        splitedStr = splitedStr[splitedStr.length-1];
        if (splitedStr === 'csv') {
          this.type = 'csv';
          return true;
        }
        this.toasterService.showWarning('Unsupported file type!');
        return false;
      } else {
        if (( type === 'image/png' || type === 'image/gif' || type === 'image/jpeg' || type === 'image/pjpeg' ) && this.allowedType.includes(this.fileService.FILE_TYPE_IMG)) {
          this.type = 'image';
          return true;
        } else if (type === 'application/pdf' && this.allowedType.includes(this.fileService.FILE_TYPE_PDF)) {
          this.type = 'pdf';
          return true;
        } else if ( (type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) && this.allowedType.includes(this.fileService.FILE_TYPE_WORD)) {
          this.type = 'word';
          return true;
        } else if ( (type === 'application/vnd.ms-excel' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'text/csv' ) && (this.allowedType.includes(this.fileService.FILE_TYPE_CSV) || this.allowedType.includes(this.fileService.FILE_TYPE_EXCEL)
        )) {
          this.type = 'csv';
          return true;
        } else if (type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' && this.allowedType.includes(this.fileService.FILE_TYPE_PPT)) {
          this.type = 'ppt';
          return true;
        } else if ( ( type === 'video/mp4' || type === 'video/webm' || type === '' ) && this.allowedType.includes(this.fileService.FILE_TYPE_VIDEO)) {
          this.type = 'video';
          return true;
        } else {
          this.toasterService.showWarning('Unsupported file type!');
          return false;
        }
      }
    }
  }

  isAllowSize = () => {
    if (this.file.size > this.FILESIZE) { // Allowed file size is less than 5 MB (1048576)
      this.toasterService.showWarning('<b>' + this.bytesToSize() + '</b> Too big file! <br />File is too big, it should be less than 5 MB.');
      return false;
    }
    return true;
  }

  canUpload = () => {
    return this.checkType() && this.isAllowSize();
  }

  bytesToSize = () => { //function to format bites bit.ly/19yoIPO
    var bytes = parseInt(this.file.size);
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0)
      return '0 Bytes';
    var i = Math.floor( Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
  }

  getFileType = (type: any) => {
    switch (type) {
      case 'image/png'  :
      case 'image/gif' :
      case 'image/jpeg' :
      case 'image/pjpeg' :
        return 'image';
        break;
      case 'application/pdf':
        return 'pdf';
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'word';
        break;
      case 'text/csv':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'csv';
        break;
      case 'video/mp4':
      case 'video/webm':
      case 'application/octet-stream':
        return 'video';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'ppt';
        break;
      default:
        return false;
    }
  }

  getExtension = (file:any) => {
    let filePath = file;
    filePath = filePath.split(';');
    filePath = filePath[0].split('data:');
    return filePath[filePath.length-1];
  }

}
