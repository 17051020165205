import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {SideMenuService} from "../sidemenu.service";
import { SharedDataService } from '../shared.data.service';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent implements OnInit {
  public isSideBarOpen:boolean=false;
  public openSubMenu:boolean=false;
  public sideMenuLinks: any;
  public isSidebarOpen2 :any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sideMenuService: SideMenuService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    const currentUserRole = this.sharedDataService.getCurrentUserRole();
    if(currentUserRole){
      this.processSideNavigation(currentUserRole || '');
    } else {
      this.sharedDataService.currentUserRole.subscribe((roleSlug) => {
        this.processSideNavigation(roleSlug);
      });
    }

    // this.isSideBarOpen = true;
    // this.isSideBarOpen = true;
    // this.onToggaleSideBar();
    this.Onchecktoggle();
  }

  processSideNavigation(roleSlug = ''){
    const isSuperAdmin = this.sharedDataService.getIsCurrentUserSuperAdmin()
    if(roleSlug === 'ADMINISTRATOR' || isSuperAdmin){
      this.sideMenuLinks = this.sideMenuService.getMenuItems();
    } else {
      this.sideMenuLinks = this.sideMenuService.getMenuItems().map(item => {
        if (item.sublinks) {
            const filteredSublinks = item.sublinks.filter(sublink => sublink.route !== '/settings');
            return {
                ...item,
                sublinks: filteredSublinks
            };
        }
        return item;
    });
    }
  }

  Onchecktoggle = () => {
    this.isSideBarOpen = this.document.body.classList.contains('sidebar-pinned');
  }

  onMobileToggaleSideBarClose = () =>{
    this.document.body.classList.remove('sidebar-open');
  }

  onToggaleSideBar = () =>{
    if (!this.isSideBarOpen) {
      this.isSideBarOpen=true;

      this.document.body.classList.add('sidebar-pinned');
      return;
    }
    this.isSideBarOpen = false;
    this.document.body.classList.remove('sidebar-pinned');


  }


  onMobileToggaleSideBarOpen = () =>{
    this.document.body.classList.add('sidebar-open');
  }

  isOpen(id:any){
    const navBar = this.sideMenuLinks.filter( (nav: any ) => {
      if(nav.id == id){
      var isCheck = nav.isSelected == false ? true : false
        nav.isSelected = isCheck;
        console.log(id,isCheck);
        if(isCheck){
          document.getElementById(id)?.classList?.add('openSubMenu');
        }else{
          document.getElementById(id)?.classList?.remove('openSubMenu');
        }
      }
    });
  }


}
