import { TicketCommunicationService } from './../../ticket/ticket-communication.service';
import {Component, Input, OnInit} from '@angular/core';
import {DialogService} from "../../../services/core/dialog.service";
import {BackendApiService} from "../../../services/backend-api.service";
import {TicketDetailPopupComponent} from "./ticket-detail-popup/ticket-detail-popup.component";
import { ViewTicketDetailComponent } from '../../ticket/view-ticket-detail/view-ticket-detail.component';
import { AddEditTicketComponent } from '../../ticket/add-edit-ticket/add-edit-ticket.component';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { ExportPdfSharedComponent } from '../export-pdf/export-pdf.component';
import { environment } from 'src/environments/environment';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import {ToasterService} from "../../../services/core/toaster.service";


@Component({
  selector: 'app-module-tickets',
  templateUrl: './module-tickets.component.html',
  styleUrls: ['./module-tickets.component.scss']
})
export class ModuleTicketsComponent implements OnInit {

  @Input() module_id: any;
  @Input() record_id: any;
  @Input() customerID: any = '';
  @Input() vendorID: any = '';
  @Input() subcontractorID: any = '';
  @Input() wareHouseId:any=''
  customerModuleID: any = 13;
  ticketList: any = [];
  search: string='';
  private searchInput = new Subject<string>();
  private readonly debounceTimeMs = 500; // Adjust debounce time as needed
  showLoadMoreOption: boolean = false;
  param = { per_page: 10, page: 1 };
  ticketMeta: any;

  //Permissions
  @Input() createPermission = 'DONOTAPPLY';
  @Input() viewDetailPermission = 'DONOTAPPLY';
  @Input() exportPDFPermission = 'DONOTAPPLY';



  constructor(
    public dialogService: DialogService,
    public backendApiService: BackendApiService,
    public helperService: HelperService,
    public dialog: NgDialogAnimationService,
    public toasterService: ToasterService,
    private ticketCommunicationService:TicketCommunicationService
  ) { }

  ngOnInit(): void {
    this.getTickets();
    this.searching();
  }

  getTickets() {
    console.log(`module/tickets/${this.module_id}/${this.record_id}?customer_id=${this.customerID}&subcontractor_id=${this.subcontractorID}&vendor_id=${this.vendorID}`);

    let url=`module/tickets/${this.module_id}/${this.record_id}?customer_id=${this.customerID}&subcontractor_id=${this.subcontractorID}&vendor_id=${this.vendorID}&search=${this.search}`
    this.backendApiService.getApi(url,this.param)
      .subscribe((response: any) => {
        console.log('response',response);
        this.ticketList = response?.data;
        this.ticketMeta=response?.meta
        this.showLoadMoreOption= this.helperService.checkLoadMoreOptionVisibility(false,this.ticketList,this.ticketMeta,this.param);

      })
  }

  openTicketCommentDetail(id: any) {
    this.dialogService.onOpenFromRightDialog(TicketDetailPopupComponent, {
      id: id,
      module_id: this.module_id,
    }, 710);

    this.dialogService.afterClose()?.subscribe((result: any) => {
      if (result) {
        this.getTickets();
        // this.router.navigate(['services/service/' + result?.data?.id]);
      }
    });
  }

  openTicketDetail(id: any) {
    this.dialogService.onOpenFromRightDialog(ViewTicketDetailComponent,{
      id:id
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
        this.getTickets();
      }
    });
  }

  createTicket(): void {
    console.log('this.moduleID', this.module_id);
    console.log('this.referenceID', this.record_id);
    // this.dialogService.onOpenFromRightDialog(CreateTicketComponent);
    this.dialogService.onOpenFromRightDialog(AddEditTicketComponent,{
      moduleID: this.module_id,
      referenceID: this.record_id,
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
        this.getTickets();
      }
    });
  }
  onSearchTextChange() {
    this.searchInput.next(this.search);
  }

  searching() {
    // Get Updated Ticket List
    this.ticketCommunicationService.getTicketListUpdate().subscribe(response=>{
      if(response){
        this.getTickets()
      }
    })

    // Search Ticket List
    this.searchInput
      .pipe(
        debounceTime(this.debounceTimeMs),
        distinctUntilChanged(),
        switchMap(async () => {
          this.getTickets();
        })
      )
      .subscribe(); // Subscribe to the observable chain
  }

  loadMoreData() {
    this.showLoadMoreOption= this.helperService.checkLoadMoreOptionVisibility(true,this.ticketList,this.ticketMeta,this.param);
    if (this.showLoadMoreOption) {
      this.getTickets();
    }
  }

  concatString(value: any[],key:string): string {
    if (!Array.isArray(value)) return '';
    return value.slice(1).map(scope => scope[key]).join(',');
  }

  generatePDF(id:any){
    const dialogRef = this.dialog.open(ExportPdfSharedComponent, {
      width: '30%',
      // height: '80%',
      data: {
        moduleName:'ticket'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.helperService.getTemplates(environment.apiBaseUrl+'/generate-pdf',id,result);
        this.toasterService.showWarning('PDF export not available.');
      }
    });
  }

  onSearch(){
    if(!this.search){
      this.searchInput.next(this.search);
    }
  }

}
