<ng-container [formGroup]="selectBoxForm">
  <ng-select
    id="{{name}}"
    [items]="items"
    bindLabel="{{bindLabel}}"
    [bindValue]="bindValue"
    [multiple]="multiple"
    placeholder="{{placeholder}}"
    [closeOnSelect]="true"
    [maxSelectedItems]="maxSelectedItems"
    formControlName="{{name}}"
    (change)="onChangeItem($event)"
  >
  </ng-select>
  <app-validation-error [formGroup]="selectBoxForm" [controlName]="name"></app-validation-error>
</ng-container>
