import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {UserService} from "../services/core/user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private currentUser: UserService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    req = req.clone({
      setHeaders: {
        //'Content-Type' : 'application/json; charset=utf-8',
        //'Accept'       : 'application/json',
        'Authorization': `Bearer ${this.currentUser.getToken()}`,
      },
    });
    return next.handle(req);
  }
}
