import { Injectable } from '@angular/core';
import {tap, catchError, first} from 'rxjs/operators';
import {SharedDataService} from './shared.data.service';
import {TenantService} from "../tenant/tenant.service";
import {CustomerService} from "../customer/customer.service";
import {JobsiteService} from "../jobsite/jobsite.service";
import {BidDataService} from "../sale/bid/bid.data.service";
import {ProposalDataService} from "../sale/proposal/proposal.data.service";
import {VendorDataService} from "../inventory/vendor/vendor.data.service";
import {SubcontractorDataService} from "../inventory/subcontractor/subcontractor.data.service";
import {CertificateDataService} from "../setting/certificate/certificate.data.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public countries: any;
  public states: any;
  public iframeUrl: any;

  constructor(
    private sharedDataService: SharedDataService,
    public tenantService: TenantService,
    public customerService: CustomerService,
    public jobsiteService: JobsiteService,
    public bidDataService: BidDataService,
    public proposalDataService: ProposalDataService,
    public vendorDataService: VendorDataService,
    public subcontractorDataService: SubcontractorDataService,
    public certificateDataService: CertificateDataService,
    private sanitizer: DomSanitizer
  ) {
  }

  getTaxAndDiscClasses = () => {
    return this.sharedDataService.getTaxClass();
  }

  getCountries = () => {
    this.sharedDataService.getCountries()
      .pipe(first())
      .subscribe(
        data => {
          this.countries = data.data;
        });
  }

  getStateTax = (state: any) => {
    return this.sharedDataService.getStateTax(state)
  }

  updateStateTax = (state: any, tax: any = null) => {
    return this.sharedDataService.updateStateTax(state, tax);
  }

  getStates = (countryID: any =null) => {
    return this.sharedDataService.getStates(countryID)
      .pipe(first())
      .subscribe(
        data => {
          this.states = data.data;
        });
  }

  getAllStates = () => {
    return this.sharedDataService.getStates();
  }

  getStatesByCountry = (countryID: any =null) => {
    return this.sharedDataService.getStates(countryID);
  }

  getCustomerTypes = () => {
    return [
      {id: 0, name: 'Company'},
      {id: 1, name: 'Indivdual'}
    ];
  }

  getBids = (params: any = {}) => {
    return this.bidDataService.list(params);
  }

  getScopes = () => {
    return this.sharedDataService.getScopes();
  }

  getStages = () => {
    return this.sharedDataService.getStages();
  }

  getSources = () => {
    return this.sharedDataService.getSources();
  }

  getStaffs = () => {
    return this.tenantService.getStaffs();
  }

  getCustomers = (callback:any = null) => {
    return this.customerService.getCustomers();
  }

  getJobsites = () => {
    return this.jobsiteService.getJobsites();
  }

  getBidTypes = () => {
    return this.sharedDataService.getBidTypes();
  }

  getProposals = (model:any = null) => {
    return this.proposalDataService.list(model);
  }


  getVendors = () => {
    return this.vendorDataService.list();
  }

  getSubcontractors = () => {
    return this.subcontractorDataService.list();
  }

  getCertificates = () => {
    return this.certificateDataService.list();
  }

  updateMapUrl(address: any) {
    const url = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(address)}&key=AIzaSyBqFKRt68YxsyBY3d8dvDzXyjfbcGnY11M`;
    const mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url) as SafeResourceUrl;
    return mapUrl;
  }

  geServicePrice(product: any){
    let unitPrice = 0;
    unitPrice = product?.default_subcontractor?.service?.unit_price;
    unitPrice = unitPrice ? unitPrice : 0;
    unitPrice = isNaN(unitPrice) ? 0 : unitPrice;
    return unitPrice;
  }

  getProductPrice(product: any, vendor: any = ''){
    let unitPrice = 0;
    vendor = vendor ? vendor : product?.primary_vendor;
    if (vendor){
      unitPrice = (product?.is_vendor_multiplier_enable && vendor?.product?.is_price_multiplier_enable)
        ?  vendor.product?.unit_price_with_multiplier : vendor?.product?.unit_price;
    }
    return unitPrice;
  }
  getVendorPrice(vendor: any){
    let unitPrice = 0;
    if (vendor){
      unitPrice = (vendor?.product?.is_price_multiplier_enable)
        ?  vendor.product?.unit_price_with_multiplier : vendor?.product?.unit_price;
    }
    return unitPrice;
  }

  getProductPartPrice(product: any, vendor: any = ''){
    let unitPrice = 0;
    vendor = vendor ? vendor : product?.primary_vendor;
    if (vendor){
      unitPrice =  vendor?.product?.cost;
    }
    return unitPrice;
  }

  getProductPartLabourCost(product: any, vendor: any = ''){
    let labourPrice = 0;
    vendor = vendor ? vendor : product?.primary_vendor;
    if (vendor){
      labourPrice = vendor?.product?.labour_cost;
    }
    return labourPrice;
  }

}
