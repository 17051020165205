import { NgModule } from '@angular/core';
import {Routes, RouterModule, CanActivate, PreloadAllModules} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {CustomerContactModule} from "./modules/customer-contact/customer-contact.module";
import {MainLayoutComponent} from "./modules/shared/main-layout/main-layout.component";
import {AccessDeniedComponent} from "./modules/shared/access-denied/access-denied.component";


const routes: Routes = [
  {
    path: 'auth', loadChildren: () =>
      import('./modules/auth/auth.module').then(
        (module) => module.AuthModule
      ),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: 'customers',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/customer/customer.module').then(
            (module) => module.CustomerModule
          ),
      },
      {
        path: 'customer/contacts',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/customer-contact/customer-contact.module').then(
            (module) => module.CustomerContactModule
          ),
      },
      {
        path: 'bids',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/sale/bid/bid.module').then(
            (module) => module.BidModule
          ),
      },
      {
        path: 'proposals',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/sale/proposal/proposal.module').then(
            (module) => module.ProposalModule
          ),
      },
      {
        path: 'jobs',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/sale/job/job.module').then(
            (module) => module.JobModule
          ),
      },
      {
        path: 'tasks',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/sale/task/task.module').then(
            (module) => module.TaskModule
          ),
      },
      {
        path: 'tasks-templates',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/task/task-templates/task-templates.module').then(
            (module) => module.TaskTemplatesModule
          ),
      },
      {
        path: 'vendors',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/inventory/vendor/vendor.module').then(
            (module) => module.VendorModule
          ),
      },

      {
        path: 'categories',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/inventory/category/category.module').then(
            (module) => module.CategoryModule
          ),
      },

      {
        path: 'warehouses',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/inventory/warehouse/warehouse.module').then(
            (module) => module.WarehouseModule
          ),
      },

      {
        path: 'products',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/inventory/product/product.module').then(
            (module) => module.ProductModule
          ),
      },

      {
        path: 'subcontractors',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/inventory/subcontractor/subcontractor.module').then(
            (module) => module.SubcontractorModule
          ),
      },

      {
        path: 'services',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/inventory/service/service.module').then(
            (module) => module.ServiceModule
          ),
      },

      {
        path: 'jobsites',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/jobsite/jobsite.module').then(
            (module) => module.JobsiteModule
          ),
      },

      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/setting/global-setting/global-setting.module').then(
            (module) => module.GlobalSettingModule
          ),
      },

      {
        path: 'submittals',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/sale/submittal/submittal.module').then(
            (module) => module.SubmittalModule
          ),
      },
      {
        path: 'notes',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/note/note.module').then(
            (module) => module.NoteModule
          ),
      },
      {
        path: 'tickets',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/ticket/ticket.module').then(
            (module) => module.TicketModule
          ),
      },
      {
        path: 'ticket-templates',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/ticket-templates/ticket-templates.module').then(
            (module) => module.TicketTemplatesModule
          ),
      },
      {
        path: 'change-orders',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/sale/change-order/change-order.module').then(
            (module) => module.ChangeOrderModule
          ),
      },
      {
        path: 'change-order',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/awarded-jobs/change-order/change-order.module').then(
            (module) => module.ChangeOrderModule
          ),
      },
      {
        path: 'hr',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/hr/hr.module').then(
            (module) => module.HRModule
          ),
      },

      {
        path: 'access-denied',
        canActivate: [AuthGuard],
        component: AccessDeniedComponent,
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
