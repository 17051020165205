import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const TaskApiResources = {
  list:  createUrl('tasks'),
  create:  createUrl('tasks/create'),
  updateStatus:  createUrl('tasks/change-status'),
  detail:  createUrl('tasks'),
  update:  createUrl('tasks'),
  delete:  createUrl('tasks'),
  taskSearch: createUrl('tasks/search-reference-numbers'),
  taskStatuses: createUrl('statuses/task'),
};
