import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "../services/core/user.service";
import { SharedDataService } from '../modules/shared/shared.data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private currentUser:  UserService,
    private sharedDataService: SharedDataService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.isLoggedIn()) {
        if (next.url[0].path === 'settings') {
          const currentUserRole = this.sharedDataService.getCurrentUserRole();
          const isSuperAdmin = this.sharedDataService.getIsCurrentUserSuperAdmin()
          if(currentUserRole !== 'ADMINISTRATOR' && !isSuperAdmin) {
            this.router.navigate(['/access-denied']);
            return false;
          }
        }
        return true;
      }
      // navigate to login page as user is not authenticated
      this.router.navigate(['/auth/login']);
      return false;

    }
    public isLoggedIn(): boolean {
     return this.currentUser.isLoggedIn();
  }

}
