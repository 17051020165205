import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit, OnChanges {
  isShowTemplate: boolean = false;
  @Input() template: any;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.template) {
        this.isShowTemplate = true;
      }
  }

  ngOnInit(): void {
  }

}
