import {Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {BidService} from "../../../sale/bid/bid.service";
import {CoreService} from "../../../../services/core/core.service";
import {DialogService} from "../../../../services/core/dialog.service";
import {CustomerCreateEditComponent} from "../../../customer/customer-create-edit/customer-create-edit.component";
import {
  CreateEditCustomerContactComponent
} from "../../../customer-contact/create-edit-customer-contact/create-edit-customer-contact.component";
import {first, pairwise, startWith} from "rxjs/operators";
import {CustomerService} from "../../../customer/customer.service";
import {Subscription} from "rxjs";
import {FormFieldComponentsCommunicationService} from "../form-field-components-communication.service";
import {CustomerDataService} from "../../../customer/customer.data.service";

@Component({
  selector: 'app-customer-contact-selection-dropdown',
  templateUrl: './customer-contact-selection-dropdown.component.html',
  styleUrls: ['./customer-contact-selection-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerContactSelectionDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomerContactSelectionDropdownComponent),
      multi: true
    }
  ]
})
export class CustomerContactSelectionDropdownComponent implements OnInit {

  @Input() model: any = {};
  @Output() callback = new EventEmitter<any>(true);
  @Output() onCustomerChange = new EventEmitter<any>(true);
  @Output() onInitForm = new EventEmitter<any>(true);

  @Input() form: any = {};
  @Input() DefaultCustomerId: string = '';
  @Input() DefaultContactId: string = '';


  public customers:any[]=[];
  public contacts:any[]=[];
  public allCustomers:any[] = [];
  public isFormTouched = false;

  //@ts-ignore
  public customerFormSubscription: Subscription;

  public customerForm: UntypedFormGroup = new UntypedFormGroup({
    customer_id: new UntypedFormControl(null,[Validators.required]),
    contact_id: new UntypedFormControl(null,[Validators.required]),
  });

  constructor(
    public formFieldCommunicationService: FormFieldComponentsCommunicationService,
    public customerService: CustomerService,
    public customerDataService: CustomerDataService,
    public coreService: CoreService,
    public dialogService: DialogService
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    //this.model = changes?.model?.currentValue;
    console.log('inner',this.model);
  }

  ngOnInit(): void {
    this.initSubscriptions();
    // this.getCustomers();

  }

  onCreateCustomer(): void {
    this.dialogService.onOpenDialog(CustomerCreateEditComponent,{
      autoFocus: false
    });
    this.dialogService.afterClose()?.subscribe((customer: any) => {
      if (customer) {
        this.allCustomers.push(customer);
        this.customerForm?.get('customer_id')?.setValue({id: customer.id, name: customer.company_name});
        this.prepareCustomerData();
        this.onChangeCustomer({id: customer?.id});
        this.formFieldCommunicationService.setNewCreatedContactFromBidInfo(customer);
      }
    });
  }

  onCreateContact(): void {
    this.dialogService.onOpenDialog(CreateEditCustomerContactComponent,
      {
        customerDetail: this.customerForm.get('customer_id')?.value
      }
      );
    this.dialogService.afterClose()?.subscribe((contact: any) => {
      if(contact) {
        this.contacts.push({id: contact.id, name: contact.full_name});
        this.customerForm?.get('contact_id')?.setValue({id: contact.id, name: contact.full_name});
        this.updateDropdownItems();
      }
    });
  }
  updateDropdownItems() {

    this.contacts = [...this.contacts];
  }



  onChangeCustomer = (event:any,editCustomer: boolean = true) => {
    if (event?.id){
      this.customerForm.get('customer_id')?.setValue(event);
      this.customerDataService.detail(event?.id)
        .pipe(first())
        .subscribe(
          (data:any) => {
            // let customer = this.getCustomerByID(event?.id);
            let customer = data.data;
            this.onCustomerChange.emit(customer);
            this.setCustomerContacts(customer,editCustomer);

          });

    }else{
      this.contacts = [];
      this.customerForm?.get('contact_id')?.setValue(null);
    }





  }
  setCustomerContacts(customer: any, editCustomer: boolean = true){
    // console.log('customer',customer);
    if(editCustomer) {
      this.customerForm?.get('contact_id')?.setValue(customer?.primary_contact ? {
        id: customer?.primary_contact?.id,
        name: customer?.primary_contact?.full_name
      } : null);
    }else {
      this.customerForm?.get('contact_id')?.setValue(customer?.id);
    }
    this.contacts = customer?.contacts;
    this.contacts = this.contacts.map((contact:any) => {
      return {id: contact.id, name: contact.full_name};
    });
  }

  getCustomerByID = (customerID:any) => {
    let customer = this.allCustomers.filter((customer: any)=>{
      return customerID === customer.id;
    });
    return (customer.length > 0) ? customer[0] : [];
  }

  getCustomers = () => {
    this.customerService.getCustomers({per_page: 9999999999})
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.allCustomers = data.data;
          this.prepareCustomerData();
          this.setDefaultValues();

        });
  }
  setDefaultValues(){
    if (this.DefaultCustomerId){
      const customer =  this.customers.find((x:any) => x.id == this.DefaultCustomerId);
      this.customerForm?.get('customer_id')?.setValue(customer);
      this.onChangeCustomer({id:this.DefaultCustomerId},false);
    }
    if (this.DefaultContactId){
      const contact =  this.contacts.find((x:any) => x.id == this.DefaultContactId);
      this.customerForm?.get('contact_id')?.setValue(contact)
    }
    this.onInitForm.emit(this.customerForm);
  }
  prepareCustomerData = () => {
    this.customers = this.allCustomers.map((customer:any)=>{
      return {id: customer.id, name: customer.company_name};
    });
  }

  initSubscriptions() {
    if (this.form) {
      this.customerFormSubscription = this.form.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.form.touched  && !this.isFormTouched) {
            this.customerForm.get('contact_id')?.markAsTouched();
            this.customerForm.get('customer_id')?.markAsTouched();
            // console.log('customerFormSubscription after', this.customerForm.controls);
            // this.touchFormControlds();
            this.isFormTouched = true;
          }
        });
    }
  }
  touchFormControlds(){
    console.log('this.customerForm.controls', this.customerForm.controls);

    Object.keys(this.customerForm.controls).forEach(key => {
      this.customerForm.get(key)?.markAsTouched();
      console.log('touchFormControls', this.customerForm.get(key));
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.customerForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.customerForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customerForm.disable() : this.customerForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.customerForm.valid ? null : { invalidForm: {valid: false, message: "Customer Selection fields are invalid"}};
  }
  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.customerFormSubscription?.unsubscribe();
  }

}
