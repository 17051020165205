<table
  mat-table
  [dataSource]="dataSource"
  class="table table-hover table-striped"
  matSort (matSortChange)="customSort($event)"
  width="100%"
  matSortActive="customer_number"
  matSortDirection="desc">
  <tbody>
  <ng-container matColumnDef="customer_number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
    <td mat-cell *matCellDef="let element" (click)="detailPage(element)"> {{element.customer_number}} </td>
  </ng-container>

  <ng-container matColumnDef="company_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
    <td mat-cell *matCellDef="let element" (click)="detailPage(element)"> {{element.company_name}} </td>
  </ng-container>

  <ng-container matColumnDef="customer_group">
    <th mat-header-cell *matHeaderCellDef> Group </th>
    <td mat-cell *matCellDef="let element"> {{(element.group_name)?element.group_name:'-'}} </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
    <td mat-cell *matCellDef="let element"> {{(element.address)? element.address : '-'}} </td>
  </ng-container>

  <ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef> Balance </th>
    <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> phone </th>
    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
  </ng-container>

  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef> country </th>
    <td mat-cell *matCellDef="let element"> {{(element.country_name)?element.country_name:'-'}} </td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef> state </th>
    <td mat-cell *matCellDef="let element"> {{(element.state_name)?element.state_name:'-'}} </td>
  </ng-container>

  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> city </th>
    <td mat-cell *matCellDef="let element"> {{(element.city) ? element.city : '-'}} </td>
  </ng-container>

  <ng-container matColumnDef="zipcode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> zipcode </th>
    <td mat-cell *matCellDef="let element"> {{(element.zipcode)?element.zipcode:'-'}} </td>
  </ng-container>
  </tbody>
  <thead>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </thead>
</table>
<table class=" table table-hover" width="100%" *ngIf="data?.meta.total === 0">
  <tr>
    <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
      No Records Found!
    </td>
  </tr>
</table>
<mat-paginator
  *ngIf="dataSource"
  [length]="data?.meta.total"
  [pageSize]="data?.meta.per_page"
  [pageSizeOptions]="[20,50,100]"
  (page)="onPaginateChange($event)"
  showFirstLastButtons>
</mat-paginator>
