<div class="row overide-header text-end m-auto">

  <div class="checklist" [formGroup]="sectionForm">
    <div class="checklist-title">
      <h4>Checklists</h4>
    </div>
    <ng-container *ngFor="let checklist of checklists().controls; let i=index" formArrayName="checklists">
      <div class="row mb-4 w-100" >
        <ng-container [formGroupName]="i">
          <ng-container *ngIf="checklists().at(i)?.get('edit_mode')?.value == true">
            <div class="col-md-1 d-flex align-items-center w-auto text-center">
              <!--              <mat-radio-button [checked]="checklists().at(i)?.get('is_completed')?.value"></mat-radio-button>-->
              <mat-checkbox formControlName="is_completed" (change)="updateChecklistAtBackend(i)"></mat-checkbox>
            </div>
            <div class="col-md-11">
              <div class="top-bar">
                <div class="bar-tools">
                  <div class="tool-item" (click)="openObserverParticipantPopup($event,CONST_OBSERVER_TYPE,i)">
                      <span class="tool-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="12px" viewBox="0 0 18 12" version="1.1">
                        <g transform="translate(-16, -11)" fill="#767C87" stroke="none" stroke-width="1" fill-rule="evenodd">
                          <path d="M24.9163797,11 C28.1668953,11 31.1155532,12.9244467 33.7623534,16.7733401 C33.8562279,16.9098494 33.8562281,17.0901027 33.7623538,17.2266122 L33.7623538,17.2266122 L33.5209255,17.5712272 C30.9365856,21.1904091 28.0684037,23 24.9163797,23 C21.6658641,23 18.7172062,21.0755533 16.070406,17.2266599 C15.9765315,17.0901506 15.9765313,16.9098973 16.0704056,16.7733878 L16.0704056,16.7733878 L16.3118339,16.4287728 C18.8961738,12.8095909 21.7643557,11 24.9163797,11 Z M24.9163797,14 C23.2595254,14 21.9163797,15.3431458 21.9163797,17 C21.9163797,18.6568542 23.2595254,20 24.9163797,20 C26.5732339,20 27.9163797,18.6568542 27.9163797,17 C27.9163797,15.3431458 26.5732339,14 24.9163797,14 Z" id="Combined-Shape"/>
                        </g>
                        </svg>
                      </span>
                    <span class="tool-name"> + Observer</span>
                  </div>

                  <div class="tool-item" (click)="openObserverParticipantPopup($event,CONST_PARTICIPANT_TYPE,i)">
                    <span class="tool-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"><path fill="#828B95" d="M15.571 13.26c0-.602-.733-1.289-2.176-1.69a4.75 4.75 0 0 1-1.377-.672c-.093-.057-.079-.582-.079-.582l-.464-.076c0-.043-.04-.672-.04-.672.556-.201.499-1.384.499-1.384.353.21.583-.725.583-.725.417-1.3-.208-1.222-.208-1.222.11-.794.11-1.6 0-2.395-.278-2.633-4.466-1.918-3.97-1.058-1.224-.242-.944 2.746-.944 2.746l.265.774c-.521.363-.159.802-.14 1.307.025.746.45.591.45.591.026 1.23.592 1.391.592 1.391.106.773.04.642.04.642l-.503.065a2.3 2.3 0 0 1-.04.524c-.592.283-.72.45-1.308.726-1.136.534-2.37 1.229-2.59 2.165-.22.935-.3 2.285-.3 2.285h12.108l-.398-2.74z"/></svg>
                    </span>
                    <span class="tool-name"> + Participant</span>
                  </div>
                  <div class="tool-item" (click)="isImportantCheckBox(i)">
                <span class="tool-icon">
                  <img style="width: 18px;" src="../../../../../assets/img/general_icons/color-fire.png" alt="fire icon" *ngIf="checklists().at(i)?.get('is_important')?.value">
                  <img style="width: 18px;" src="../../../../../assets/img/general_icons/light-fire.png" alt="fire icon" *ngIf="!checklists().at(i)?.get('is_important')?.value">
                </span>
                    <span class="tool-name">Important</span>
                  </div>

                  <div class="tool-item" (click)="removeCheckList(i)">
                      <span class="tool-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19"><path fill="#828B95" d="M9.31 3.794V2.697h2.182v1.097h2.862a1.5 1.5 0 0 1 1.5 1.5v.694H4.948v-.694a1.5 1.5 0 0 1 1.5-1.5H9.31zM6.04 7.085h8.724l-.791 8.962a1 1 0 0 1-.997.912H7.827a1 1 0 0 1-.996-.912l-.792-8.962h.001z"/></svg>
                      </span>
                    <span class="tool-name">  </span>
                  </div>
                </div>
              </div>
              <input type="text" class="form-control" (keyup.enter)="ediViewChecklist(i,true)" formControlName="text" placeholder="Item text">
            </div>
            <div class="col-12">
              <div class="selected-users">
                <div class="observer">
                  <mat-chip-list>
                    <mat-chip *ngFor="let user of checklists().at(i).get('observers')?.value; let index=index">
                        <span class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="12px" viewBox="0 0 18 12" version="1.1">
                            <g transform="translate(-16, -11)" fill="#767C87" stroke="none" stroke-width="1" fill-rule="evenodd">
                              <path fill="#fff" d="M24.9163797,11 C28.1668953,11 31.1155532,12.9244467 33.7623534,16.7733401 C33.8562279,16.9098494 33.8562281,17.0901027 33.7623538,17.2266122 L33.7623538,17.2266122 L33.5209255,17.5712272 C30.9365856,21.1904091 28.0684037,23 24.9163797,23 C21.6658641,23 18.7172062,21.0755533 16.070406,17.2266599 C15.9765315,17.0901506 15.9765313,16.9098973 16.0704056,16.7733878 L16.0704056,16.7733878 L16.3118339,16.4287728 C18.8961738,12.8095909 21.7643557,11 24.9163797,11 Z M24.9163797,14 C23.2595254,14 21.9163797,15.3431458 21.9163797,17 C21.9163797,18.6568542 23.2595254,20 24.9163797,20 C26.5732339,20 27.9163797,18.6568542 27.9163797,17 C27.9163797,15.3431458 26.5732339,14 24.9163797,14 Z" id="Combined-Shape"/>
                            </g>
                          </svg>
                        </span>
                      <span>{{user.full_name}}</span>
                      <span class="cursor-pointer ml-2" (click)="removeUsers(index,checklists().at(i).get('observers')?.getRawValue())"><img src="assets/img/cross_icon.svg" alt=""></span>
                    </mat-chip>

                    <mat-chip *ngFor="let user of checklists().at(i).get('participants')?.value; let index=index">
                        <span class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"><path fill="#fff" d="M15.571 13.26c0-.602-.733-1.289-2.176-1.69a4.75 4.75 0 0 1-1.377-.672c-.093-.057-.079-.582-.079-.582l-.464-.076c0-.043-.04-.672-.04-.672.556-.201.499-1.384.499-1.384.353.21.583-.725.583-.725.417-1.3-.208-1.222-.208-1.222.11-.794.11-1.6 0-2.395-.278-2.633-4.466-1.918-3.97-1.058-1.224-.242-.944 2.746-.944 2.746l.265.774c-.521.363-.159.802-.14 1.307.025.746.45.591.45.591.026 1.23.592 1.391.592 1.391.106.773.04.642.04.642l-.503.065a2.3 2.3 0 0 1-.04.524c-.592.283-.72.45-1.308.726-1.136.534-2.37 1.229-2.59 2.165-.22.935-.3 2.285-.3 2.285h12.108l-.398-2.74z"/></svg>
                        </span>
                      <span>{{user.full_name}}</span>
                      <span class="cursor-pointer ml-2" (click)="removeUsers(index,checklists().at(i).get('participants')?.getRawValue())"><img src="assets/img/cross_icon.svg" alt=""></span>
                    </mat-chip>
                  </mat-chip-list>
                </div>

                <!--<div class="participant">
                  <mat-chip-list>
                    <mat-chip *ngFor="let user of checklists().at(i).get('participants')?.value; let index=index">
                      <span class="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"><path fill="#fff" d="M15.571 13.26c0-.602-.733-1.289-2.176-1.69a4.75 4.75 0 0 1-1.377-.672c-.093-.057-.079-.582-.079-.582l-.464-.076c0-.043-.04-.672-.04-.672.556-.201.499-1.384.499-1.384.353.21.583-.725.583-.725.417-1.3-.208-1.222-.208-1.222.11-.794.11-1.6 0-2.395-.278-2.633-4.466-1.918-3.97-1.058-1.224-.242-.944 2.746-.944 2.746l.265.774c-.521.363-.159.802-.14 1.307.025.746.45.591.45.591.026 1.23.592 1.391.592 1.391.106.773.04.642.04.642l-.503.065a2.3 2.3 0 0 1-.04.524c-.592.283-.72.45-1.308.726-1.136.534-2.37 1.229-2.59 2.165-.22.935-.3 2.285-.3 2.285h12.108l-.398-2.74z"/></svg>
                      </span>
                      <span>{{user.full_name}}</span>
                      <span class="cursor-pointer ml-2"><img src="assets/img/cross_icon.svg" alt=""></span>
                    </mat-chip>
                  </mat-chip-list>
                </div>-->
              </div>
            </div>
          </ng-container>

          <!-- detail display checklist -->
          <ng-container *ngIf="checklists().at(i)?.get('view_mode')?.value == true">
            <div class="col-md-1 d-flex align-items-center w-auto text-center">
              <!--              <mat-radio-button [checked]="checklists().at(i)?.get('is_completed')?.value"></mat-radio-button>-->
              <mat-checkbox formControlName="is_completed" (change)="updateChecklistAtBackend(i)"></mat-checkbox>
            </div>
            <div class="col-md-11">
              <div class="checklist-detail">
                <div class="detail" (click)="ediViewChecklist(i,false,true)">
                  <p class="text serial-no">{{i+1}}.</p>
                  <p class="text">{{checklists().at(i)?.get('text')?.value}}</p>
                </div>
                <div class="remove">
                    <span class="tool-icon" (click)="removeCheckList(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19"><path fill="#828B95" d="M9.31 3.794V2.697h2.182v1.097h2.862a1.5 1.5 0 0 1 1.5 1.5v.694H4.948v-.694a1.5 1.5 0 0 1 1.5-1.5H9.31zM6.04 7.085h8.724l-.791 8.962a1 1 0 0 1-.997.912H7.827a1 1 0 0 1-.996-.912l-.792-8.962h.001z"/></svg>
                      </span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <div class="add-checklist-button" (click)="onClickAddNewChecklist()">
      <span>+ Add a Checklist</span>
    </div>
  </div>

</div>
