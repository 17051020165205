import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {JsonService} from "../../services/core/json.service";
import {Observable, throwError as observableThrowError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {TaskTemplateApiResources} from "./task-template.constants";

@Injectable({
  providedIn: 'root'
})
export class TaskTemplateDataService {
  private baseURL:any;

  constructor(
    private http: HttpClient,
    private jsonService: JsonService,
  ) {
    this.baseURL = TaskTemplateApiResources;
  }

  list(model: any): Observable<any> {
    const endPoint = `${this.baseURL.list}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  create(model: any): Observable<any> {
    const endPoint = `${this.baseURL.create}`;
    console.log('create', endPoint);
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  detail(id:any): Observable<any> {
    const endPoint = `${this.baseURL.detail}/${id}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  update(id:any, model: any): Observable<any> {
    const endPoint = `${this.baseURL.update}/${id}`;
    return this.http.patch<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  delete(id:any): Observable<any> {
    const endPoint = `${this.baseURL.delete}/${id}`;
    return this.http.delete<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  updateStatus(id:any, model: any): Observable<any> {
    const endPoint = `${this.baseURL.updateStatus}/${id}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  searchTaskReferenceNumber(model: any) {
    const endPoint = `${this.baseURL.taskSearch}`;
    return this.http.post<any>(endPoint,model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage))
  }

  taskStatuses() {
    const endPoint = `${this.baseURL.taskStatuses}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage))
  }

  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }
}
