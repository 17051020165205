import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent implements OnInit {

  @Input() status:any = null;

  public ACTIVE_STATUS = 'Active';
  public INACTIVE_STATUS = 'Inactive';
  public DEFAULT_STATUS = 'Default';

  constructor() { }

  ngOnInit(): void {
  }

}
