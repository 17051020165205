<ng-container [formGroup]="companyContactForm">
    <div class="card-body">
      <h2>Company Contact <mat-slide-toggle formControlName="is_primary">Primary Contact?</mat-slide-toggle></h2>

      <div class="form-row" [hidden]="this.customerDetail?.id">
        <div class="form-group col-12">
          <label for="customer" class="custom_label">Customer *</label>
          <!-- <ng-select
            id="customer"
            [items]="customers"
            placeholder="Select Customer"
            [closeOnSelect]="true"
            formControlName="customer"
            bindLabel="name"
          >
          </ng-select> -->
          <!-- formControlName="customer" -->
          <app-scrollable-search-field
          class="d-flex"
          id="customer"
          [dataService]="customerService" 
          [dataServiceFunctionName]="'getCustomers'"
          [searchPlaceHolder]="'Select Customer'"
          [labelKey]="'company_name'"
          [formDataMapper]="{'id': 'id', 'name' : 'company_name'}"
          [keyNameToShowWhenSelected]="'name'"
          [markCtrlTouched]="markScrollAbleSearchSelectTouched"
          (selectedValueChange)="selectedValueChange($event)"
          >
          </app-scrollable-search-field>
<!--          <app-select-box-->
<!--            id="customer"-->
<!--            [name]="'customer'"-->
<!--            [items]="customers"-->
<!--            [isRequired]="true"-->
<!--            formControlName="customer"-->
<!--            [placeholder]="'Select Customer'"-->
<!--            [selectedItem]="this.preSelectedCustomer"-->
<!--          ></app-select-box>-->
          <!-- <app-validation-error [formGroup]="companyContactForm" [controlName]="'customer'"></app-validation-error> -->

        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="first_name" class="custom_label">First Name *</label>
          <input type="text" id="first_name" formControlName="first_name" class="form-control" placeholder="First Name" name="first_name">
          <app-validation-error [formGroup]="companyContactForm" [controlName]="'first_name'"></app-validation-error>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="last_name" class="custom_label">Last Name</label>
          <input type="text" id="last_name" formControlName="last_name" class="form-control" placeholder="Last Name" name="last_name">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="email" class="custom_label">Email *</label>
          <input type="email" id="email" formControlName="email" class="form-control" placeholder="Email" name="email" (keydown)="preventSpace($event)">
          <app-validation-error [formGroup]="companyContactForm" [controlName]="'email'"></app-validation-error>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="contact_phone" class="custom_label">Phone *</label>
          <input type="tel" id="contact_phone" formControlName="phone" phoneMask class="form-control" placeholder="Phone" name="phone">
          <app-validation-error [formGroup]="companyContactForm" [controlName]="'phone'"></app-validation-error>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="cell_phone_number" class="custom_label">Cell Phone Number</label>
          <input type="tel" id="cell_phone_number" formControlName="mobile" phoneMask class="form-control" placeholder="Cell Phone Number" name="mobile">
          <app-validation-error [formGroup]="companyContactForm" [controlName]="'mobile'"></app-validation-error>
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-12">
          <label for="contact_authority" class="custom_label">Contact Authority</label>
          <input type="text" id="contact_authority" formControlName="contact_authority" class="form-control" placeholder="Contact Authority" name="contact_authority">
        </div>
      </div>

      <mat-slide-toggle formControlName="has_portal_access">Portal Access?</mat-slide-toggle>

      <ng-container *ngIf="companyContactForm.get('has_portal_access')?.value">
        <div class="form-row">
          <div class="form-group col-12">
            <label for="username" class="custom_label">Username</label>
            <input type="text" id="username" formControlName="username" class="form-control" placeholder="Username" name="username" >
          </div>
        </div>

        <div class="form-row" *ngIf="!isEdit">
          <div class="form-group col-12 position-relative">
            <label for="password" class="custom_label">Password</label>
            <input type="password" id="password" formControlName="password" class="form-control" placeholder="Password" name="password" >
            <button type="button" class="btn btn-outline-secondary eye-icon" (click)="togglePasswordVisibility()">
              <i class="fa" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
            </button>
          </div>
        </div>
      </ng-container>
      <div class="form-row">
        <div class="form-group col-12">
          <label for="notes" class="custom_label">Notes</label>
          <textarea id="notes" class="form-control" formControlName="notes" placeholder="Notes" name="notes"></textarea>
        </div>
      </div>
    </div>
  </ng-container>
