import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendApiService} from "../../../services/backend-api.service";
import {ToasterService} from "../../../services/core/toaster.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {TaskTemplateDataService} from "../../task/task-template.data.service";
import {formatDate} from "@angular/common";
import {
  ObserverParticipantPopupComponent
} from "../../sale/task/add-task/add-checklist/observer-participant-popup/observer-participant-popup.component";
import {forkJoin} from "rxjs";
import {TicketUserPopupComponent} from "./ticket-user-popup/ticket-user-popup.component";
import { CustomerService } from '../../customer/customer.service';
import { VendorService } from '../../inventory/vendor/vendor.service';
import { SubcontractorService } from '../../inventory/subcontractor/subcontractor.service';
import {StaffService} from "../../../services/staff/staff.service";
import {CommunicationServiceService} from "../../widgets/services/communication-service.service";

@Component({
  selector: 'app-add-edit-ticket',
  templateUrl: './add-edit-ticket.component.html',
  styleUrls: ['./add-edit-ticket.component.scss']
})
export class AddEditTicketComponent implements OnInit {
  public CONST_HIGH_PRIORITY: boolean = false;
  public CONST_TIME_PLANNING: boolean = false;
  public CONST_CREATED_BY: boolean = false;
  public CONST_OBSERVER: boolean = false;
  public CONST_PARTICIPANT: boolean = false;
  public TICKET_MODULE_ID: number = 8;
  public CONST_RESPONSIBLE_PERSON_TYPE: string = 'responsible_person';
  public CONST_CREATED_BY_TYPE: string = 'created_by';
  public CONST_OBSERVER_TYPE: string = 'observer';
  public CONST_PARTICIPANT_TYPE: string = 'participant';
  moduleID: number;
  referenceID: number;
  isTicketModule:boolean = false;
  isEdit:boolean = false;
  model:any = {};
  responsibleUsersArray: any = [];
  createdByArray: any;
  observerArray: any = [];
  participantArray: any = [];
  stages: any = [];
  templates: any = [];
  selectedStage: any = {};
  searchParams = {
    type: '',
    module_id: '',
    search: ''
  };
  selectedItem: any = {};
  showSearchInput: boolean = true;
  modulesList: any = [];
  mentions: any = [];
  selectedMentionedUsers: any  = [];
  customers: any = [];
  contacts: any = [];
  vendors: any = [];
  ticketTypesList: any = [];
  subcontractor: any = [];
  TYPE_USER: any = 'Users';
  TYPE_VENDORS: any = 'Vendors';
  TYPE_CUSTOMERS: any = 'Customers';
  TYPE_SUBCONTRACTORS: any = 'Subcontractors';
  attachmentsArr = [];
  attachmentsToAdd = [];

  public ticketForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null,[Validators.required]),
    description: new UntypedFormControl(null),
    priority: new UntypedFormControl(false),
    status: new UntypedFormControl(''),
    responsible_person: new UntypedFormControl([]),
    participants: new UntypedFormControl([]),
    observers: new UntypedFormControl([]),
    is_responsible_person: new UntypedFormControl(false),
    is_participant: new UntypedFormControl(false),
    is_observer: new UntypedFormControl(false),
    is_time_planning: new UntypedFormControl(false),
    due_date: new UntypedFormControl(null),
    start_date: new UntypedFormControl(null),
    date_finished: new UntypedFormControl(null),
    is_created_by: new UntypedFormControl(false),
    created_by: new UntypedFormControl(null),
    ticket_type_id: new UntypedFormControl(null),
    duration: new UntypedFormControl(null),
    checklists: new UntypedFormControl(null),
    is_template: new UntypedFormControl(false),
    // stage_id: new UntypedFormControl(null),
    module_id: new UntypedFormControl(null),
    reference_id: new UntypedFormControl(null),
    vendor: new UntypedFormControl(null),
    customer: new UntypedFormControl(null),
    contact: new UntypedFormControl(null),
    subcontractor: new UntypedFormControl(null),
  })
  constructor(
    public dialogRef: MatDialogRef<AddEditTicketComponent>,
    public backendApiService: BackendApiService,
    public toasterService: ToasterService,
    public dialog: NgDialogAnimationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public taskDataService: TaskTemplateDataService,
    public customerService: CustomerService,
    public vendorService: VendorService,
    public subcontractorService: SubcontractorService,
    public scrumCommunication: CommunicationServiceService,
  public staffService: StaffService,
  ) {
    this.moduleID = this.data?.moduleID;
    this.referenceID = this.data?.referenceID;
    this.isTicketModule = this.data?.isTicketModule;
    this.isEdit = this.data?.isEdit;
    this.model = this.data?.model;
  }

  ngOnInit(): void {
    console.log('this.moduleID', this.moduleID);
    console.log('this.referenceID', this.referenceID);
    this.ticketForm.get('module_id')?.setValue(this.moduleID);
    this.ticketForm.get('reference_id')?.setValue(this.referenceID);
    this.getModules();
    this.getAllDropdownData();
    // this.getStagesOfModule(this.moduleID);
    // this.getTaskTemplates();
  }

  ngAfterViewInit() {
    if(this.isEdit) {
      this.setDataOnEdit();
    }
  }

  setDataOnEdit() {
    this.selectedMentionedUsers = this.model.mentions.map((mention: any) =>  {
      return {
        user: mention,
        type:'Users'
      }
    });
    const filesData = this.model.relations.attach_files.map((file: any)=>{
      return {
        attach_file: file,
        attachment_id: file.id
      }
    });
    if (this.model?.priority == 3){
      this.changePriority();
    }
    this.attachmentsArr = filesData;
    this.ticketForm.setValue({
      title: this.model?.name,
      description: this.model?.description,
      priority: this.model?.priority == 3 ? true : false,
      status: this.model?.status,
      responsible_person: new UntypedFormControl([]),
      participants: new UntypedFormControl([]),
      observers: new UntypedFormControl([]),
      is_responsible_person: this.model?.is_responsible_person,
      is_participant: this.model?.is_participant,
      is_observer: this.model?.is_observer,
      is_time_planning: this.model?.is_time_planning,
      due_date: this.model?.due_date,
      start_date: this.model?.start_date,
      date_finished: this.model?.date_finished,
      is_created_by: this.model?.is_created_by,
      created_by: this.model?.created_by ? this.model?.created_by?.id : null,
      ticket_type_id: {id:this.model?.ticket_type?.id, name:this.model?.ticket_type?.name},
      duration: this.model?.duration,
      checklists: this.model?.check_list,
      // is_template: this.model?.is_template,
      is_template: 0,
      // stage_id: this.model?.stage?.id,
      module_id: this.model?.module?.id,
      reference_id: this.model?.reference?.id,
      vendor: {id:this.model?.vendor?.id,name:this.model?.vendor?.name},
      customer: {id:this.model?.customer?.id,company_name:this.model?.customer?.company_name},
      subcontractor: {id:this.model?.subcontractor?.id,name:this.model?.subcontractor?.name},
      contact: {id:this.model?.contact?.id,full_name:this.model?.contact?.full_name},
    })

    // this.selectedStage = this.model?.stage;

    this.createdByArray = this.model?.created_by;

    this.model?.responsible_persons?.map((data: any) => {
      this.responsibleUsersArray.push({user:{id:data?.id,full_name:data?.full_name != " " ? data?.full_name : data?.attachable?.name},type:data?.type})
    })

    this.model?.observers?.map((data: any) => {
      this.observerArray.push({user:{id:data?.id,full_name:data?.full_name != " " ? data?.full_name : data?.attachable?.name},type:data?.type})
    })

    this.model?.participants?.map((data: any) => {
      this.participantArray.push({user:{id:data?.id,full_name:data?.full_name != " " ? data?.full_name : data?.attachable?.name},type:data?.type})
    })
  }

  getStagesOfModule(id:any) {
    this.backendApiService.getApi('stages?module_id='+id, false)
      .subscribe((response: any) => {
        this.stages = response.data;
      })
  }

  getTaskTemplates() {
    this.backendApiService.getApi('task-template')
      .subscribe((response: any) => {
        this.templates = response.data;
      })
  }
  getModules() {
    this.backendApiService.getApi('modules/type?type=ticket', {} , false)
      .subscribe((response: any) => {
        this.modulesList = response?.data
      })
  }

  addStage(stage: any) {
    // console.log('stage',stage);
    this.selectedStage = stage;
    this.ticketForm.get('stage_id')?.setValue(stage?.id);
  }

  changePriority(event: any = '') {
    this.CONST_HIGH_PRIORITY = !this.CONST_HIGH_PRIORITY;
  }

  hideShowTimePlanning() {
    this.CONST_TIME_PLANNING = !this.CONST_TIME_PLANNING;
  }

  hideShowCreatedBy() {
    this.CONST_CREATED_BY = !this.CONST_CREATED_BY;
  }

  hideShowObserver() {
    this.CONST_OBSERVER = !this.CONST_OBSERVER;
  }

  hideShowParticipant() {
    this.CONST_PARTICIPANT = !this.CONST_PARTICIPANT;
  }
  getFileID(event: any) {
    const data  = event?.attachment;
    if(Array.isArray(data)){
      this.attachmentsArr = data as any;
      this.attachmentsToAdd = this.attachmentsArr.map((file: any) => file.attachment_id) as any;
    }
  }

  getFormData() {
    const form = this.ticketForm.value;
    return {
      parent_id: this.referenceID,
      module_id: form.module_id,
      reference_id: form.reference_id,
      name: form.title,
      description: form.description,
      priority: form.priority ? 3 : 1,
      status: form.status,
      // responsible_person: this.responsibleUsersArray?.map((x: any) => x.id),
      responsible_person: this.responsibleUsersArray?.map((x: any) => this.MapDataOfArray(x)),
      participants: this.participantArray?.map((x: any) => this.MapDataOfArray(x)),
      observers:this.observerArray.map((x: any) => this.MapDataOfArray(x)),
      mentions:this.selectedMentionedUsers?.map((x: any) => this.MapDataOfArray(x)),
      is_participant: this.CONST_PARTICIPANT,
      is_observer: this.CONST_OBSERVER,
      is_time_planning: this.CONST_TIME_PLANNING,
      due_date: form?.due_date ? formatDate(form?.due_date,'yyyy-MM-dd','en_US') : '',
      start_date: form?.start_date ? formatDate(form?.start_date,'yyyy-MM-dd','en_US') : '',
      date_finished:  form?.date_finished ? formatDate(form?.date_finished,'yyyy-MM-dd','en_US') : '',
      is_created_by: this.CONST_CREATED_BY,
      created_by: form.created_by,
      ticket_type_id: form?.ticket_type_id?.id,
      stage_id: form?.stage_id,
      duration: form?.duration,
      check_list: form?.checklists,
      is_template: 1,
      reminder: 'reminder',
      vendor_id: form?.vendor?.id,
      customer_id: form?.customer?.id,
      contact_id: form?.contact?.id,
      subcontractor_id: form?.subcontractor?.id,
      attachment_ids: this.attachmentsToAdd
    }
  }

  save() {

    const form = this.ticketForm.value;
    let isInvalid = false;

    if (!form.title){
      isInvalid = true;
      this.toasterService.showWarning('Name', 'Name field is required.');
    }

    if (!form.reference_id){
      isInvalid = true;
      this.toasterService.showWarning('Reference', 'Reference Number field is required.');
    }

    if (!form.module_id){
      isInvalid = true;
      this.toasterService.showWarning('Module', 'Module Field is Required.');
    }

    if (isInvalid){
      return true;
    }
    const service = this.model ? this.backendApiService.patchApi(`ticket/${this.model?.id}`,this.getFormData()) :
      this.backendApiService.postApi('ticket/create',this.getFormData())

    service.subscribe((resposne: any) => {
      this.toasterService.showSuccessMessage(resposne.data?.message);
      this.close(true);
    })
    return true;
  }

  getChecklists(event: any) {
    console.log('event',event);
    this.ticketForm.get('checklists')?.setValue(event);
  }


  openUsersPopup(event: any,type: any, attachAbleUser:any = ['Users','Vendors','Customers','Subcontractors']) {
    const position = event.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(TicketUserPopupComponent,{
      width: '400px',
      height: '400px',
      // position: {top:`${position.top}px`, left: `${position.left}px`},
      data: {
        attachAbleUser,
        type: type,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result',result);
      if(result) {
        if(result.type == this.CONST_RESPONSIBLE_PERSON_TYPE) {
          this.addUser( this.responsibleUsersArray, {user:result.user,type:result?.selectedTab});
          // this.responsibleUsersArray.push({user:result.user,type:result?.selectedTab});
        } else if(result.type == this.CONST_CREATED_BY_TYPE) {
          this.ticketForm.get('created_by')?.setValue(result.user?.id);
          this.createdByArray = result.user
          // this.createdByArray.push(result.user)
        } else if(result.type == this.CONST_OBSERVER_TYPE) {
          this.addUser( this.observerArray, {user:result.user,type:result?.selectedTab});
          // this.observerArray.push({user:result.user,type:result?.selectedTab})
        } else if(result.type == this.CONST_PARTICIPANT_TYPE) {
          this.addUser( this.participantArray, {user:result.user,type:result?.selectedTab});
          // this.participantArray.push({user:result.user,type:result?.selectedTab})
        }
      }
    })
  }

  addUser(usersList: any, userObj: any){
    const alreadyExistCheck = usersList.find((x:any) => x.user.id == userObj.user.id && x.type == userObj.type);
    if (!alreadyExistCheck){
      usersList.push(userObj);
    }
  }

  remove(index: any,dataArray: any) {
    dataArray.splice(index,1);
  }

  selectedModule(event: any) {
    if (event){
      this.searchParams.type = event?.slug;
      this.searchParams.module_id = event?.id;
      this.searchParams.search = '';
      this.ticketForm.get('module_id')?.setValue(event?.id);
      // this.getStagesOfModule(event?.id);
    }else{
      this.ticketForm.get('module_id')?.setValue('');
    }
    this.ticketForm.get('reference_id')?.setValue('');
    this.showSearchInput = true;
    this.selectedItem = '';

  }

  onItemSelect(event: any) {
    this.selectedItem = event;
    this.ticketForm.get('reference_id')?.setValue(event?.id);
    this.showSearchInput = false;
  }

  updateSearchInputFlag() {
    this.showSearchInput = true;
    this.ticketForm.get('reference_id')?.setValue(null);
  }


  close = (data: any = null) => {
    if (data){
      this.scrumCommunication.setScrumBoardData(data);
    }
    this.dialogRef.close(data);
  }

  getUsers(event: any){
    const search = event;
    this.backendApiService.getApi(`staff/search?search=${search}`, [], false)
      .subscribe((response: any) => {
        this.mentions = this.staffService.processStaff(response.data);
      })
  }

  getSelectedUser(event: any){
    const index = this.selectedMentionedUsers.findIndex((x: any) => x.id == event?.id);
    if(index == -1) {
      this.selectedMentionedUsers.push({user:event,type:'Users'});
    }

  }

  getAllDropdownData(){
    // forkJoin(
    //   this.backendApiService.postApi('customers','', false),
    //   this.backendApiService.postApi('vendors','', false),
    //   this.backendApiService.postApi('subcontractors','', false),
    //   this.backendApiService.getApi('ticket-types', {}, false)
    // ).subscribe((response: any) => {
    //     console.log('resposne',response);
    //     this.customers = response[0]?.data;
    //     this.vendors = response[1]?.data;
    //     this.subcontractor = response[2]?.data;
    //     this.ticketTypesList = response[3];
    // })

    this.backendApiService.getApi('ticket-types', {}, false).subscribe((response: any) => {
      this.ticketTypesList = response;
  })
  }

  getCustomerContacts(event: any){
    console.log('event', event);
    if(event) {
      this.contacts = event?.contacts || [];
      this.ticketForm.get('contact')?.setValue(event?.primary_contact);
    } else {
      this.contacts = [];
      this.ticketForm.get('contact')?.setValue(null);
    }
    this.ticketForm.get('customer')?.setValue(event);
  }

  setVendor(event: any) {
    this.ticketForm.get('vendor')?.setValue(event);
  }

  setSubContractor(event: any) {
    this.ticketForm.get('subcontractor')?.setValue(event);
  }

  MapDataOfArray(item: any) {
    return {id: item?.user.id,type:item?.type}
  }

  getChipIconUsingType(type: any) {
    let image = '';
    switch (type) {
      case (this.TYPE_USER) :
        image = '../../../../assets/img/general_icons/user-light.png';
        break;
      case (this.TYPE_CUSTOMERS):
        image = '../../../../assets/img/general_icons/cust-light.png';
        break;
      case (this.TYPE_VENDORS):
        image = '../../../../assets/img/general_icons/vendor-light.png';
        break;
      case (this.TYPE_SUBCONTRACTORS):
        image = '../../../../assets/img/general_icons/subcon-light.png';
        break;
      default:
        image = '../../../../assets/img/general_icons/admin-light.png';
    }
    return image;
  }
  getChipNameUsingType( user: any, type:any) {
    let name = '';
    const cUser = user?.user;
    switch (type) {
      case (this.TYPE_USER) :
        name = cUser?.full_name ??  cUser?.full_name;
        break;
      case (this.TYPE_CUSTOMERS):
        name = cUser?.full_name ??  cUser?.company_name;
        break;
      case (this.TYPE_VENDORS):
        name = cUser?.full_name ??  cUser?.name;
        break;
      case (this.TYPE_SUBCONTRACTORS):
        name = cUser?.full_name ?? cUser?.name;
        break;
      default:
        name = cUser.full_name;
    }
    return name;
  }
}
