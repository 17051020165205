import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, tap,throwError as observableThrowError } from 'rxjs';
import { JsonService } from 'src/app/services/core/json.service';
import { VendorQuotesApiResources } from './vendor-quotes.constant';

@Injectable({
  providedIn: 'root'
})
export class VendorQuoteDataService {
  private vendorQuoteListUpdate = new Subject<any>();
  
  private baseURL;

  constructor(
    private http: HttpClient,
    private jsonService: JsonService) {
    this.baseURL = VendorQuotesApiResources;
  }
  setVendorQuoteUpdate(data: any) {
    this.vendorQuoteListUpdate.next(data);
  }

  getVendorQuoteUpdateList(): Observable<any> {
    return this.vendorQuoteListUpdate.asObservable();
  }

  search(model: any): Observable<any> {
    const endPoint = `${this.baseURL.search}/${model.moduleId}/${model.referenceId}`;
    return this.http.get<any>(endPoint, model)
                    .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

}
