import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendApiService} from "../../../../../../services/backend-api.service";
import {StaffService} from "../../../../../../services/staff/staff.service";

@Component({
  selector: 'app-observer-participant-popup',
  templateUrl: './observer-participant-popup.component.html',
  styleUrls: ['./observer-participant-popup.component.scss']
})
export class ObserverParticipantPopupComponent implements OnInit {

  public type: any;
  public index: any;
  usersList: any = [];
  vendorsList: any = [];
  customersList: any = [];
  subcontractorsList: any = [];
  selectedIndex: any = 0;
  TYPE_USER: any = 'Users';
  TYPE_VENDORS: any = 'Vendors';
  TYPE_CUSTOMERS: any = 'Customers';
  TYPE_SUBCONTRACTORS: any = 'Subcontractors';
  constructor(
    public dialogRef: MatDialogRef<ObserverParticipantPopupComponent>,
    public backendApiService: BackendApiService,
    public staffService: StaffService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.type = this.data?.type;
    this.index = this.data?.index
  }

  ngOnInit(): void {

  }

  getUsersList(event: any) {

    this.backendApiService.getApi(`staff/search?search=${event?.target?.value}`)
      .subscribe((response: any) => {
        this.usersList = this.staffService.processStaff(response.data);
      })
  }

  getVendorsList(event: any) {
    this.backendApiService.postApi(`vendors?search=${event?.target?.value}`,'')
      .subscribe((response: any) => {
        console.log('response:',response);
        this.vendorsList = response.data;
      })
  }

  getCustomersList(event: any) {
    this.backendApiService.postApi(`customers?search=${event?.target?.value}`,'')
      .subscribe((response: any) => {
        console.log('response:',response);
        this.customersList = response.data;
      })
  }

  getSubcontractorsList(event: any) {
    this.backendApiService.postApi(`subcontractors?search=${event?.target?.value}&is_related_view=true`,'')
      .subscribe((response: any) => {
        console.log('response:',response);
        this.subcontractorsList = response.data;
      })
  }


  close(data: any = '') {
    this.dialogRef.close(data);
  }

  getActiveTab(event: any){
    console.log('hlele',event);
    this.selectedIndex = event;
  }

  getTabNameUsingTabIndex(index: any) {
    let value = '';
    switch (index) {
      case 0:
        value = this.TYPE_USER;
        break;
      case 1:
        value = this.TYPE_VENDORS;
        break;
      case 2:
        value = this.TYPE_CUSTOMERS
        break;
      case 3:
        value = this.TYPE_SUBCONTRACTORS;
        break;
      default:
    }
    return value;
  }
}
