<div class="preview_wrapper">
    <p class="title">Company Contact
        <span class="green_circle_tick">
            <img src="../../../../assets/img/white_tick.svg" alt="">
        </span>
    </p>
    <div class="row">
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">First Name</p>
                <p class="value">{{contact?.first_name}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Last Name</p>
                <p class="value">{{contact?.last_name}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Email Address</p>
                <p class="value">{{contact?.email}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Phone</p>
                <p class="value">{{contact?.phone}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Cell Phone Number</p>
                <p class="value">{{contact?.mobile}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Contact Authority</p>
                <p class="value">{{contact?.contact_authority}}</p>
            </div>
        </div>
       <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Portal Access</p>
          <p class="value">{{contact?.has_portal_access ? 'Yes' : 'No'}}</p>
        </div>
      </div>
      <ng-container *ngIf="contact?.has_portal_access == true">
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Username</p>
                <p class="value">{{contact?.username}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Password</p>
              <p class="value">{{contact?.password}}</p>
            </div>
        </div>
      </ng-container>
        <div class="col-md-12">
            <div class="wrap">
                <p class="heading">Notes</p>
                <p class="value">{{contact?.notes}}</p>
            </div>
        </div>

    </div>
</div>
