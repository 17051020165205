

<header class="admin-header">
  <!--<a href="javascript:void(0);" class="sidebar-toggle"  (click)="onMobileToggaleSideBarOpen()" data-toggleclass="sidebar-open" data-target="body">001 </a>
  <nav class=" mr-auto my-auto">
    <ul class="nav align-items-center">
      <li class="nav-item">
        <a class="nav-link sidebar-toggle sidebar"  (click)="onToggaleSideBar()" data-target="javascript:void(0);siteSearchModal" data-toggle="modal" href="javascript:void(0);">
        </a>
      </li>
    </ul>
  </nav>-->
  <nav class=" ml-auto">
    <ul class="nav align-items-center">
      <li class="nav-item">
        <div class="img_wrap active">
          <img src="../../../../assets/img/plus_circle_icon.svg" alt="" class="icon">
        </div>

        </li>
        <li class="nav-item">
          <div class="img_wrap">
          <img src="../../../../assets/img/calender_icon.svg" alt="" class="icon">
        </div>
          </li>
          <li class="nav-item">
            <div class="img_wrap">
            <img src="../../../../assets/img/insights_icon.svg" alt="" class="icon">
          </div>
            </li>
            <li class="nav-item">
              <div class="img_wrap">
              <img src="../../../../assets/img/tasks_icons.svg" alt="" class="icon">
            </div>
              </li>

      <li class="nav-item">
        <div class="dropdown">
          <a href="javascript:void(0);" class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="../../../../assets/img/bell_icon.png" alt="" class="icon">
            <span class="notification-counter">8</span>
          </a>

          <div class="dropdown-menu notification-container dropdown-menu-right">
            <div class="d-flex p-all-15 bg-white justify-content-between border-bottom ">
              <a href="javascript:void(0);" class="mdi mdi-18px mdi-settings text-muted"></a>
              <span class="h5 m-0">Notifications</span>
              <a href="javascript:void(0);" class="mdi mdi-18px mdi-notification-clear-all text-muted"></a>
            </div>
            <div class="notification-events bg-gray-300">
              <div class="text-overline m-b-5">today</div>
              <a href="javascript:void(0);" class="d-block m-b-10">
                <div class="card">
                  <div class="card-body"> <i class="mdi mdi-circle text-success"></i> All systems operational.</div>
                </div>
              </a>
              <a href="javascript:void(0);" class="d-block m-b-10">
                <div class="card">
                  <div class="card-body"> <i class="mdi mdi-upload-multiple "></i> File upload successful.</div>
                </div>
              </a>
              <a href="javascript:void(0);" class="d-block m-b-10">
                <div class="card">
                  <div class="card-body">
                    <i class="mdi mdi-cancel text-danger"></i> Your holiday has been denied
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown ">

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          <div class="avatar_wrap" matTooltip="{{user.getFullName() | titlecase }}">
            <img src="../../../../assets/img/general_icons/account-circle_24dp_white.svg" alt="" class="avatar_img">
            <span class="avatar_title"> {{user.getFullName() | titlecase}}</span>
          </div>
        </a>
<!--        {{user.getNameIntial()}}-->
        <div class="dropdown-menu  dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0);">  Add Account
          </a>
          <a class="dropdown-item" href="javascript:void(0);">  Reset Password</a>
          <a class="dropdown-item" href="javascript:void(0);">  Help </a>
          <div class="dropdown-divider"></div>
          <a
            [routerLink]="[ '/auth/logout' ]" routerLinkActive="active"
            class="dropdown-item"
            href="javascript:void(0);"
          > Logout</a>
        </div>
      </li>

    </ul>

  </nav>
</header>
