import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { SidebarNavigationComponent } from './sidebar-navigation/sidebar-navigation.component';
import { HeaderComponent } from './header/header.component';
import {RouterModule} from '@angular/router';
import {ModalPopupComponent} from './modal-popup/modal-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import { CountryComponent } from './country/country.component';
import { StateComponent } from './state/state.component';
import {AngularMaterialModule} from "../../material.module";
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { BalanceComponent } from './balance/balance.component';
import { ListItemComponent } from './list-item/list-item.component';
import { UploaderComponent } from './uploader/uploader.component';
import { UploaderPreviewComponent } from './uploader-preview/uploader-preview.component';
import { MaticonComponent } from './maticon/maticon.component';
import { FontAwesomeIconComponent } from './font-awesome-icon/font-awesome-icon.component';
import { IconComponent } from './icon/icon.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { PhoneMaskDirective } from './directives/phone-mask/phone-mask.directive';
import { ZipcodeMaskDirective } from './directives/zipcode/zipcode-mask.directive';
import {NgSelectModule} from "@ng-select/ng-select";
import { MatTableComponent } from './mat-table/mat-table.component';
import {AddressComponent} from "./form-field/address/address.component";
import { SelectBoxComponent } from './form-field/select-box/select-box.component';
import { DevextremeDataGridComponent } from './devextreme-data-grid/devextreme-data-grid.component';
import {
    DxButtonModule, DxCheckBoxModule, DxContextMenuModule,
    DxDataGridModule,
    DxDropDownBoxModule, DxFileUploaderModule, DxProgressBarModule,
    DxSelectBoxModule,
    DxTabsModule, DxTemplateModule
} from "devextreme-angular";
import { LoadDataGridDirective } from './directives/load-data-grid.directive';
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { CustomerContactSelectionDropdownComponent } from './form-field/customer-contact-selection-dropdown/customer-contact-selection-dropdown.component';
import { CurrencyMaskDirective } from './directives/currency-mask/currency-mask.directive';
import { DecimalMaskDirective } from "./directives/decimal-mask/decimal-mask.directive";
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SearchFieldComponent } from './input-components/search-field/search-field.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { AttachmentComponent } from './attachment/attachment.component';
import { TemplateViewComponent } from './template-view/template-view.component';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import { SearchPipe } from './pipe/search.pipe';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PermissionsDirective } from './directives/permissions/permissions.directive';
import { ModuleTicketsComponent } from './module-tickets/module-tickets.component';
import { TicketDetailPopupComponent } from './module-tickets/ticket-detail-popup/ticket-detail-popup.component';
import { ScrollableSearchFieldComponent } from './input-components/scrollable-search-field/scrollable-search-field.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { ExportPdfSharedComponent } from './export-pdf/export-pdf.component';
import { PDFDisplayOptionsComponent } from './pdf-display-option/pdf-display-option.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgForFilterPipe } from './pipe/filters/ng-for-filter.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PositiveValueMaskDirective } from './directives/positive-value-mask/positive-value-mask.directive';
import { TruncatePipe } from './pipe/truncate.pipe';
import { AddEditVendorQuotesComponent } from './vendor-quotes/add-edit-vendor-quotes/add-edit-vendor-quotes.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
    declarations: [
        LayoutComponent,
        SidebarNavigationComponent,
        HeaderComponent,
        ModalPopupComponent,
        CountryComponent,
        StateComponent,
        ConfirmationPopupComponent,
        StatusBadgeComponent,
        BalanceComponent,
        ListItemComponent,
        UploaderComponent,
        UploaderPreviewComponent,
        MaticonComponent,
        FontAwesomeIconComponent,
        IconComponent,
        DateTimePickerComponent,
        ValidationErrorComponent,
        SvgIconComponent,
        SafeHtmlPipe,
        PhoneMaskDirective,
        PositiveValueMaskDirective,
        ZipcodeMaskDirective,
        AddressComponent,
        MatTableComponent,
        SelectBoxComponent,
        DevextremeDataGridComponent,
        LoadDataGridDirective,
        ActionDropdownComponent,
        CustomerContactSelectionDropdownComponent,
        CurrencyMaskDirective,
        DecimalMaskDirective,
        MainLayoutComponent,
        SearchFieldComponent,
        AttachmentComponent,
        TemplateViewComponent,
        SearchPipe,
        AccessDeniedComponent,
        PermissionsDirective,
        ModuleTicketsComponent,
        TicketDetailPopupComponent,
        ScrollableSearchFieldComponent,
        ExportPdfSharedComponent,
        PDFDisplayOptionsComponent,
        NgForFilterPipe,
        PositiveValueMaskDirective,
        AddEditVendorQuotesComponent,
    ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    AngularMaterialModule,
    MatAutocompleteModule,
    // for dateTime Start
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    // for dateTime End
    NgSelectModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxButtonModule,
    DxTabsModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxProgressBarModule,
    GooglePlaceModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    MatExpansionModule,
    CKEditorModule,
    TruncatePipe,
    MatButtonToggleModule,
    CurrencyMaskModule

  ],
  exports: [
    LayoutComponent,
    SidebarNavigationComponent,
    HeaderComponent,
    ModalPopupComponent,
    CountryComponent,
    StateComponent,
    ConfirmationPopupComponent,
    StatusBadgeComponent,
    BalanceComponent,
    ListItemComponent,
    UploaderComponent,
    UploaderPreviewComponent,
    IconComponent,
    DateTimePickerComponent,
    ValidationErrorComponent,
    AddressComponent,
    MatTableComponent,
    PhoneMaskDirective,
    PositiveValueMaskDirective,
    ZipcodeMaskDirective,
    SelectBoxComponent,
    DevextremeDataGridComponent,
    LoadDataGridDirective,
    ActionDropdownComponent,
    CustomerContactSelectionDropdownComponent,
    CurrencyMaskDirective,
    DecimalMaskDirective,
    SearchFieldComponent,
    AttachmentComponent,
    TemplateViewComponent,
    SearchPipe,
    NgForFilterPipe,
    PermissionsDirective,
    ModuleTicketsComponent,
    ModuleTicketsComponent,
    ScrollableSearchFieldComponent,
    PDFDisplayOptionsComponent,
    CKEditorModule,
  ]
})
export class SharedModule { }
