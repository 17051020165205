import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BackendApiService } from '../../../services/backend-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedDataService } from '../../shared/shared.data.service';

@Component({
  selector: 'app-export-pdf-shared',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss'],
})
export class ExportPdfSharedComponent implements OnInit {
  templates: any = [];
  moduleName: any;

  public sectionForm: UntypedFormGroup = new UntypedFormGroup({
    templates: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    public backendApiService: BackendApiService,
    public sharedDataService: SharedDataService,
    public dialogRef: MatDialogRef<ExportPdfSharedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.moduleName = this.data.moduleName || 'Change Order';
    this.getProposalTemplates();
  }

  getProposalTemplates() {
    this.sharedDataService
      .getTemplateByModule(this.moduleName)
      .subscribe((response: any) => {
        console.log(this.moduleName + 'templates', response.data);
        this.templates = response?.data;
        const defaultTemplate = this.templates?.find(
          (x: any) => x.is_default == 1
        );
        if (defaultTemplate) {
          this.sectionForm.get('templates')?.setValue([defaultTemplate]);
        }
      });
  }

  downloadPdf() {
    if (this.sectionForm.invalid) {
      this.touchFormControls();
      return;
    }
    const formData = this.sectionForm
      .get('templates')
      ?.getRawValue()
      ?.map((item: any) => {
        return item?.id;
      });
    this.close({ templates: formData, type: 1 });
  }

  previewPdf() {
    if (this.sectionForm.invalid) {
      this.touchFormControls();
      return;
    }
    const formData = this.sectionForm
      .get('templates')
      ?.getRawValue()
      .map((item: any) => {
        return item?.id;
      });
    this.close({ templates: formData, type: 2 });
  }

  close = (data: any = null) => {
    this.dialogRef.close(data);
  };

  touchFormControls() {
    Object.keys(this.sectionForm.controls).forEach((key) => {
      this.sectionForm.get(key)?.markAsTouched();
    });
  }
}
