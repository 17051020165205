import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, FormControlName, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  host: { class: 'invalid-feedback' },
})
export class ValidationErrorComponent implements OnInit, OnChanges {

  @Input() message: String = 'This field is required';

  @Input() messagePattern: String = 'Invalid Pattern';
  @Input() messageEmail: String = 'Invalid Email Address';
  @Input() messageWebsiteUrl:string='Invalid Website URL';
  @Input() messagePhoneNumber :string='Invalid Phone Number';
  @Input () messageZipcode :string='Invalid Zip Code';
  @Input () minValueMessage :string='Value is below minimum allowed value';
  @Input () maxValueMessage :string='Value is over max allowed value';

  @Input() formGroup!:UntypedFormGroup;
  @Input() controlName!:any;


  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  isInvalid = () => {
    return this.formGroup?.get(this.controlName)?.invalid &&
      this.formGroup?.get(this.controlName)?.errors &&
    (this.formGroup?.get(this.controlName)?.dirty || this.formGroup?.get(this.controlName)?.touched)
  }

  hasRequiredError = () => {
   return this.formGroup?.get(this.controlName)?.hasError('required');
  }

  hasErrorEmptyInput = () => {
   return this.formGroup?.get(this.controlName)?.hasError('emptyInput');
  }
  hasErrorPattern  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('pattern');
  }
  hasErrorWebsitePattern  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('invalidWebsiteURL');
  }
  hasErrorPhoneNumber  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('invalidPhoneNumber');
  }
  hasErrorZipcode  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('invalidZipCode');
  }
  hasErrorEmail  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('invalidEmail');
  }
  hasErrorMin  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('min');
  }
  hasErrorMax  = () => {
    return this.formGroup?.get(this.controlName)?.hasError('max');
  }
}
