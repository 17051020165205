<div class="confirmation_popup_wrapper">
    <h4 mat-dialog-title>
      {{title}}
    </h4>

  <div mat-dialog-content>
    <p>{{message}}</p>
    <label  class="description postfix">
      <img class="prefix" src="../../../../../../assets/img/inventory/products/detail/open-warning.svg">
      {{description}}</label>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button cdkFocusInitial (click)="onDismiss()">No</button>
    <button mat-raised-button (click)="onConfirm()">Yes</button>
  </div>
</div>


