import { Injectable } from '@angular/core';
import {first} from "rxjs/operators";
import {JobsiteDataService} from "./jobsite.data.service";

@Injectable({
  providedIn: 'root'
})
export class JobsiteService {

  constructor(
    private jobsiteDataService: JobsiteDataService
  ) {}

  getJobsites = () => {
    return this.jobsiteDataService.list({per_page: 100});
  }
}
