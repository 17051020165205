<aside class="admin-sidebar">
<!--     <a href="javascript:void(0);" class="sidebar-toggle"  (click)="onMobileToggaleSideBarOpen()" data-toggleclass="sidebar-open" data-target="body"> </a> -->

    <div class="admin-sidebar-brand">
        <img class="admin-brand-logo" src="../../../../assets/img/company_logos/company_logo.svg" alt="Logo">
        <div class="ml-auto">
          <a href="javascript:void(0);"
             class="sidebar-toggle"
             (click)="onMobileToggaleSideBarOpen()"
             data-toggleclass="sidebar-open"
             data-target="body">
          </a>
          <nav class=" mr-auto my-auto">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a class="nav-link sidebar-toggle sidebar"
                   (click)="onToggaleSideBar()"
                   data-target="javascript:void(0);siteSearchModal"
                   data-toggle="modal"
                   href="javascript:void(0);">
                   <img  src="../../../../assets/img/hamburger_icon.svg" alt="Logo">
                </a>
              </li>
            </ul>
          </nav>
        </div>

    </div>
    <div class="admin-sidebar-wrapper js-scrollbar">
        <ul class="menu" >
            <ng-container *ngFor="let nav of sideMenuLinks; let i = index">
            <li routerLinkActive="active" id="left-menu-parent-{{i}}" class="menu-item openSubMenu" (click)="isOpen(nav.id)">
                <a routerLink="{{nav.route}}" *ngIf="nav.route" class="open-dropdown menu-link">
                    <span class="menu-icon">
                      <div [innerHTML]="nav.icon | safeHtml"></div>
                    </span>
                    <span class="menu-label"><span class="menu-name">{{nav.title}}</span></span>
                    <span class="menu-icon" *ngIf="!isSideBarOpen">
                        <div [innerHTML]="nav.icon | safeHtml"></div>
                    </span>
                </a>

                <a *ngIf="!nav.route" class="open-dropdown menu-link">
                    <span class="menu-icon">
                        <div [innerHTML]="nav.icon | safeHtml"></div>
                    </span>
                    <span class="menu-label">
                        <span class="menu-name">
                            {{nav.title}}
                            <span class="menu-arrow" *ngIf="nav?.sublinks"></span>
                        </span>

                    </span>
                    <span class="menu-icon" *ngIf="!isSideBarOpen">
                        <div [innerHTML]="nav.icon | safeHtml"></div>
                    </span>
                </a>

                <ul  class="sub-menu" id={{nav.id}} *ngIf="nav?.sublinks && isSideBarOpen">
                    <li  routerLinkActive="active" id="left-menu-child-{{i}}" class="menu-item " *ngFor="let sub of nav?.sublinks; let j = index">
                        <a routerLink={{sub.route}} class=" menu-link">
                            <span class="menu-label">
                                <span class="menu-name">{{sub.title}}</span>
                            </span>

                        </a>
                    </li>

                </ul>

            </li>
            </ng-container>
        </ul>

    </div>
</aside>
