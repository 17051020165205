import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngForFilter',
  pure: false
})
export class NgForFilterPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    Object.keys(filter)[0]
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    // return items.filter(item => item[filter?.key] == filter?.value);
    return items;
  }

}
