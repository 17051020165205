import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const VendorQuotesApiResources = {
  search:  createUrl('module/vendor_quote'),
 
};
