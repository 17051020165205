import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ZipCodeValidators(): ValidatorFn {
  const zipCodePattern = /^\d{5}(?:-\d{4})?$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isValid = zipCodePattern.test(value);

    if (!isValid) {
      return { 'invalidZipCode': true };
    }

    return null;
  };
}

