import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,

} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {CustomerService} from "../../customer.service";
import {first, pairwise, startWith} from "rxjs/operators";
import {Subscription} from "rxjs";
import {HelperService} from "../../../../services/helper.service";

import { NotOnlySpaces } from '../../../shared/CustomValidators/CustomNotOnlySpacesValidator';
import { EmailValidator } from '../../../shared/CustomValidators/CustomInvalidEmailValidator';
import {WebsiteURLValidator} from '../../../shared/CustomValidators/CustomWebsiteUrlValidator';
import {PhoneNumberValidator} from '../../../shared/CustomValidators/CustomInvalidPhoneNumberValidator';
import {FaxNumberValidator} from '../../../shared/CustomValidators/CustomInvalidFaxNumberValidator';

import {FormBuilder} from "@angular/forms";



@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyDetailComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyDetailComponent),
      multi: true
    }
  ]
})

export class CompanyDetailComponent implements OnInit, OnChanges {

  @Input() model: any = {};
  @Input() form: any = {};
  @Input() company_name: any;
  @Output() callback = new EventEmitter<any>(true);
  @Output() onInitForm = new EventEmitter<any>(true);
  urlPrefix = 'https://';
  public onModelValueChanged:any = {};
  public groups: string[] = [];
  //@ts-ignore
  public companyDetailFormSubscription: Subscription;
  panelOpenState = false;
  company_address: any = '';
  billing_address: any = '';
  shipping_address: any = '';

  billingAddressChangesFlag: boolean = false;
  shippingAddressChangesFlag: boolean = false;


  public companyDetailForm: UntypedFormGroup = this.initBasicForm();

  constructor(
    private customerService: CustomerService,
    private helperService: HelperService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder

  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
    this.onModelValueChanged = this.model?.model?.detail;
    this.companyDetailForm.get('company_title')?.setValue(this.onModelValueChanged ? this.onModelValueChanged?.company_title : this.company_name);
    // this.company_address = this.changeAddressObject(this.model?.model?.detail.company_address);
    //
    // setTimeout(()=>{
    //   this.billing_address = this.changeAddressObject(this.model?.model?.detail.billing_address);
    // },1000);

    // console.log('this.company_address',this.company_address);
    // console.log('this.billing_address',this.billing_address);

  }
  omit_special_char(event: KeyboardEvent): boolean {
    // const k = event.keyCode || event.which;
    // return (k >= 48 && k <= 57) || k == 8 || k == 32; // Allow numbers, backspace, and space
    return true;
  }
  ngOnInit(): void {
    this.initSubscriptions();
    this.getGroups();
    this.initFormValues();
  }
  preventSpace(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
  initBasicForm(value: any = ''){
    return new UntypedFormGroup({

      company_title:new UntypedFormControl(null, [Validators.required,NotOnlySpaces()]),
      company_email:new UntypedFormControl(null,[Validators.required,EmailValidator()]),
      is_jobsite:new UntypedFormControl(0),
      phone: new UntypedFormControl(null, [Validators.required,PhoneNumberValidator()]),
      secondary_phone:new UntypedFormControl(null, [PhoneNumberValidator()]),
      fax:new UntypedFormControl(null,[PhoneNumberValidator()]),
      website:new UntypedFormControl(null, [WebsiteURLValidator()]),
      group:new UntypedFormControl(null,[Validators.required]),
      main_address: new UntypedFormControl(null),
      billing_address: new UntypedFormControl(null),
      shipping_address: new UntypedFormControl(null),
    });
  }



  handleInput(event: KeyboardEvent): void{
    event.stopPropagation();
  }
  initFormValues(){
    console.log('this.model', this.model);
    this.companyDetailForm.get('is_jobsite')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.is_jobsite : '');
    this.companyDetailForm.get('company_title')?.setValue(this.onModelValueChanged ? this.onModelValueChanged?.company_title : this.company_name);
    this.companyDetailForm.get('company_email')?.setValue(this.onModelValueChanged? this.model?.model?.company_email : '');
    this.companyDetailForm.get('main_address')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.detail?.company_address : '');
    this.companyDetailForm.get('phone')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.phone : '');
    this.companyDetailForm.get('secondary_phone')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.secondary_phone : '');
    this.companyDetailForm.get('fax')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.fax : '');
    this.companyDetailForm.get('group')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.group : null);
    this.companyDetailForm.get('website')?.setValue(this.onModelValueChanged? this.onModelValueChanged?.website : '');
    // this.companyDetailForm.get('billing_address')?.setValue(this.onModelValueChanged?.billing_address);
    // this.companyDetailForm.get('shipping_address')?.setValue(this.onModelValueChanged?.shipping_address);

    this.company_address = this.changeAddressObject(this.model?.model?.detail.company_address);
    this.billing_address = this.changeAddressObject(this.model?.model?.detail.billing_address);
    this.shipping_address = this.changeAddressObject(this.model?.model?.detail.shipping_address);
  }

  getGroups = () => {
    this.customerService.getGroups()
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.groups = data.data.map((group:any)=>{
            return {id: group.id, name: group.name};
          });
        });
  }

  public changeAddressObject = (value:any) => {
    const obj = {
      address_line_1:value?.address_line_1,
      address_line_2:value?.address_line_2,
      city:value?.city,
      relations:{state:value?.state ? value?.state : value?.state_id},
      zipcode:value?.zipcode,
    }
    return obj;
  }
  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    // console.log('writeValue', val);
    val && this.companyDetailForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.companyDetailForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.companyDetailForm.disable() : this.companyDetailForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.companyDetailForm.valid ? null : { invalidForm: {valid: false, message: "mainInfoForm fields are invalid"}};
  }

  copyCompanyAddressToBillingAddress(){
    this.billingAddressChangesFlag = true;
    const address = this.companyDetailForm.get('main_address')?.value;
    this.billing_address = this.changeAddressObject(address);
    this.cd.detectChanges();
    this.billingAddressChangesFlag = false;
    // this.companyDetailForm.get('billing_address')?.setValue(address);
  }

  copyCompanyAddressToShippingAddress(){
    this.shippingAddressChangesFlag = true;
    const address = this.companyDetailForm.get('main_address')?.value;
    this.shipping_address = this.changeAddressObject(address);
    this.cd.detectChanges();
    this.shippingAddressChangesFlag = false;
  }

  onBlurWebsiteUrl(){
    const url = this.helperService.removeHttp(this.companyDetailForm.get('website')?.value)
    this.companyDetailForm.get('website')?.setValue(url);
  }

  initChildForm( formControlName: string, value: any){
    this.companyDetailForm.get(formControlName)?.setValue(value);
    // this.companyDetailForm.get('billing_address')?.setValue(value);
    this.onInitForm.emit(this.companyDetailForm);
  }

  initSubscriptions(){
    if (this.form){
      this.companyDetailFormSubscription = this.form.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.form.touched){
            this.touchFormControls();
          }
        });
    }

  }
  touchFormControls(){
    Object.keys(this.companyDetailForm.controls).forEach(key => {
      this.companyDetailForm.get(key)?.markAsTouched();
    });
  }
  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.companyDetailFormSubscription?.unsubscribe();
  }
}
