import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProposalComponentsCommunicationService {

  private proposalMainInfo = new Subject<any>();
  private proposalMainInfoCustomer = new Subject<any>();
  private proposalMainStatusChange = new Subject<any>();
  private proposalJobSiteStateChange = new Subject<any>();
  private proposalDetailStateChange = new Subject<any>();
  private proposalOverrideValueChange = new Subject<any>();
  private scopeVATValueChange = new Subject<boolean>();
  private displayOptions = new Subject<any>();
  // proposal main info changes
  setProposalMainInfo(data: any) {
    this.proposalMainInfo.next(data);
  }

  getProposalMainInfo(): Observable<any> {
    return this.proposalMainInfo.asObservable();
  }

  // proposal main info changes
  setProposalMainInfoCustomer(data: any) {
    this.proposalMainInfoCustomer.next(data);
  }

  getProposalMainInfoCustomer(): Observable<any> {
    return this.proposalMainInfoCustomer.asObservable();
  }

  // proposal main status changes
  setProposalMainStatusChange(data: any) {
    this.proposalMainStatusChange.next(data);
  }

  getProposalMainStatusChange(): Observable<any> {
    return this.proposalMainStatusChange.asObservable();
  }

  // proposal jobSite address state changes
  setBillingStateChange(data: any) {
    this.proposalJobSiteStateChange.next(data);
  }

  getBillingStateChange(): Observable<any> {
    return this.proposalJobSiteStateChange.asObservable();
  }

  setProposalDetailStateChange(data: any) {
    this.proposalDetailStateChange.next(data);
  }

  getProposalDetailStateChange(): Observable<any> {
    return this.proposalDetailStateChange.asObservable();
  }

  setProposalOverrideValueChange(data: any) {
    return this.proposalOverrideValueChange.next(data);
  }
  getProposalOverrideValueChange(): Observable<any> {
    return this.proposalOverrideValueChange.asObservable();
  }

  setScopeVATValueChange(data: boolean) {
    return this.scopeVATValueChange.next(data);
  }
  getScopeVATValueChange(): Observable<boolean> {
    return this.scopeVATValueChange.asObservable();
  }

  setDisplayOptions(data: any) {
    return this.displayOptions.next(data);
  }
  getDisplayOptions(): Observable<any> {
    return this.displayOptions.asObservable();
  }

}
