<mat-accordion>
  <mat-expansion-panel [expanded]="this.step?.event?.selectedIndex-1 === 0" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title_wrap">
          <span class="number">1</span>
          <span class="title">Company Name</span>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="wrapper">
      <img src="../../../../assets/img/company_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Company Name*</span>
        <span class="value">{{company?.name}}</span>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="this.step?.event?.selectedIndex-1 === 1" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title_wrap">
          <span class="number">2</span>
          <span class="title">Company Detail</span>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="wrapper">
      <img src="../../../../assets/img/company_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Company Name*</span>
        <span class="value">{{detail?.company_title}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/email_2_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Company Email*</span>
        <span class="value" style="word-wrap:break-word" >{{detail?.company_email}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/location_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Address 1*</span>
        <span class="value">{{detail?.main_address?.address_line_1}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/home_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Address 2</span>
        <span class="value">{{detail?.main_address?.address_line_2}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/city_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">City*</span>
        <span class="value">{{detail?.main_address?.city}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/state_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">State*</span>
        <!-- <span class="value">{{detail?.main_address?.state?.state_id_selected?.name}}</span> -->
        <span class="value">{{detail?.main_address?.state_id?.name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/zipcode_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Zip Code*</span>
        <span class="value">{{detail?.main_address?.zipcode}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/phone_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Phone Number*</span>
        <span class="value">{{detail?.phone}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/secondary_phone_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Secondary Phone Number</span>
        <span class="value">{{detail?.secondary_phone}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/fax_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Fax Number</span>
        <span class="value">{{detail?.fax}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/url_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Website URL</span>
        <span class="value" style="word-wrap:break-word" >{{detail?.website ? 'https://' + detail?.website : ''}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/company_type_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Company Type(Group)*</span>
        <span class="value">{{detail?.group?.name}}</span>
        <!-- <span class="value">{{detail?.group?.group_id_selected?.name}}</span> -->

      </div>
    </div>

    <h2>Billing Address</h2>
    <div class="wrapper">
      <img src="../../../../assets/img/location_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Address 1*</span>
        <!-- <span class="value">{{detail?.billing_address?.address_line_1}}</span> -->
        <span class="value">{{detail?.billing_address?.address_line_1}}</span>

      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/home_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Address 2</span>
        <span class="value">{{detail?.billing_address?.address_line_2}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/city_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">City*</span>
        <span class="value">{{detail?.billing_address?.city}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/state_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">State*</span>
        <span class="value">{{detail?.billing_address?.state_id?.name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/zipcode_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Zip Code*</span>
        <span class="value">{{detail?.billing_address?.zipcode}}</span>
      </div>
    </div>

    <h2>Shipping Address</h2>
    <div class="wrapper">
      <img src="../../../../assets/img/location_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Address 1</span>
        <span class="value">{{detail?.shipping_address?.address_line_1}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/home_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Address 2</span>
        <span class="value">{{detail?.shipping_address?.address_line_2}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/city_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">City</span>
        <span class="value">{{detail?.shipping_address?.city}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/state_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">State</span>
        <span class="value">{{detail?.shipping_address?.state_id?.name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/zipcode_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Zip Code</span>
        <span class="value">{{detail?.shipping_address?.zipcode}}</span>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="this.step?.event?.selectedIndex-1 === 2" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title_wrap">
          <span class="number">3</span>
          <span class="title">Company Contact</span>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">First Name*</span>
        <span class="value" style="word-wrap:break-word">{{contact?.first_name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/lname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Last Name</span>
        <span class="value"style="word-wrap:break-word">{{contact?.last_name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/email_2_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Email Address*</span>
        <span class="value"style="word-wrap:break-word">{{contact?.email}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/phone_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Phone*</span>
        <span class="value">{{contact?.phone}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/contact_auth_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Contact Authority</span>
        <span class="value">{{contact?.contact_authority}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/secondary_phone_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Cell Phone Number*</span>
        <span class="value">{{contact?.mobile}}</span>
      </div>
    </div>

    <div class="wrapper">
      <img src="../../../../assets/img/portal_address_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Primary Contact?</span>
        <span class="value" >{{contact?.is_primary ? 'Yes' : 'No'}}</span>
      </div>
    </div>

    <div class="wrapper">
      <img src="../../../../assets/img/portal_address_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Portal Access?</span>
        <span class="value" >{{contact?.has_portal_access ? 'Yes' : 'No'}}</span>
      </div>
    </div>
    <ng-container *ngIf="contact?.has_portal_access">
      <div class="wrapper">
        <img src="../../../../assets/img/username_icon.svg" alt="icon" class="icon">
        <div class="wrap">
          <span class="label"  >Username</span>
          <span class="value" style="word-wrap:break-word">{{contact?.username}}</span>
        </div>
      </div>
      <div class="wrapper position-relative">
        <img src="../../../../assets/img/password_icon.svg" alt="icon" class="icon">
        <div class="wrap">
          <span class="label">Password</span>
          <div class="password-field">
            <span class="value" style="word-wrap: break-word">{{ showPassword ? contact.password : '********' }}</span>
            <button type="button" class="btn btn-outline-secondary eye-icon" (click)="togglePasswordVisibility2()" tabindex="0">
              <i class="fa" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
            </button>
          </div>
        </div>
      </div>

    </ng-container>
    <div class="wrapper">
      <img src="../../../../assets/img/secondary_phone_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Notes</span>
        <span class="value">{{contact?.notes}}</span>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel  [expanded]="this.step?.event?.selectedIndex-1 === 3" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="title_wrap">
          <span class="number">4</span>
          <span class="title">Admin Information</span>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>
      <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Tax Exempt</span>
        <p class="value" >{{adminInfo?.tax_exempt ? 'Yes' : 'No'}}</p>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Payment Methods</span>
        <!--                 Using this condition because getting different data in case of create and edit-->

<!--        <span class="value">{{adminInfo?.payment_method ? adminInfo?.payment_method?.name : adminInfo?.payment_method?.name}}</span>-->
        <span class="value">{{adminInfo?.payment_method?.name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Preferred Mark Up</span>
        <span class="value">{{adminInfo?.markup}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Lien Period</span>
        <span class="value">{{adminInfo?.lien_period}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Source</span>
<!--        <span class="value">{{adminInfo?.source?.source_id_selected?.name ? adminInfo?.source?.source_id_selected?.name : adminInfo?.source?.name}}</span>-->
        <span class="value">{{adminInfo?.source?.name}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../../../assets/img/fname_icon.svg" alt="icon" class="icon">
      <div class="wrap">
        <span class="label">Risk Level</span>
        <span class="value">{{adminInfo?.risk_level | currency}}</span>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
