<div class="overlap_panel_wrapper">
  <div class="overlap_panel_sidebar">
    <div class="side_header">
      <div class="cross_wrap">
        <a href="javascript:void(0);" (click)="close()">
          <img src="../../../../assets/img/cross_icon.svg" alt="" class="icon">
        </a>
      </div>
      <p class="company_name">Company Name</p>
      <div class="back_wrap" >
        <a (click)="goBack(stepper)" *ngIf="stepper.selectedIndex > 0" class="back-button">
          <img src="../../../../assets/img/left_orange_arrow.svg" alt="Back" class="arrow">
          <span>Back</span>
        </a>
       </div>

    </div>
    <div class="sidebar_accordion">
    <app-step-preview [model]="model" [step]="step" [form]="customerForm"></app-step-preview>
  </div>
  </div>
<div class="overlap_right_panel">
    <form [formGroup]="customerForm" novalidate>
      <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="onStepChanged($event, stepper)">
        <mat-step formGroupName="step01" [stepControl]="customerForm.get('step01')!">
          <ng-template matStepLabel>Company Name</ng-template>
          <app-company-name (callback)="changedHandler($event)" [form]="customerForm.get('step01')" formControlName="company" (onInitForm)="initChildForm('step01', 'company',$event.value)" [model]="model"></app-company-name>
          <div class="controls">
            <button type="button" mat-button class="btn_transparent" (click)="close()">Cancel</button>
            <button  mat-button matStepperNext (click)="touchFormControls('step01')" class="btn_solid_color">Next  <img src="../../../../assets/img/btn_arrow.png" alt="icon" class="icon"></button>
          </div>
        </mat-step>
        <mat-step formGroupName="step02" [stepControl]="customerForm.get('step02')!">
          <ng-template matStepLabel>Company Detail</ng-template>
          <app-company-detail (callback)="changedHandler($event)" [company_name]="company_name" formControlName="detail" [form]="customerForm.get('step02')" (onInitForm)="initChildForm('step02', 'detail',$event.value)" [model]="model"></app-company-detail>
          <div class="controls">
          <button mat-button matStepperPrevious class="btn_transparent">Back</button>
          <button type="button" mat-button matStepperNext class="btn_solid_color" (click)="touchFormControls('step02')">Next <img src="../../../../assets/img/btn_arrow.png" alt="icon" class="icon"></button>
        </div>
        </mat-step>
        <mat-step formGroupName="step03" [stepControl]="customerForm.get('step03')!">
          <ng-template matStepLabel>Company Contact</ng-template>
          <app-company-contact (callback)="changedHandler($event)" formControlName="contact" [model]="model" [form]="customerForm.get('step03')" (onInitForm)="initChildForm('step03', 'contact',$event.value)"></app-company-contact>
          <div class="controls">
          <button mat-button matStepperPrevious class="btn_transparent">Back</button>
          <button type="button" mat-button matStepperNext class="btn_solid_color" (click)="touchFormControls('step03')">Next  <img src="../../../../assets/img/btn_arrow.png" alt="icon" class="icon"></button>
        </div>
        </mat-step>
        <mat-step formGroupName="step04" [stepControl]="customerForm.get('step04')!">
          <ng-template matStepLabel>Admin Information</ng-template>
          <app-admin-information (callback)="changedHandler($event)" formControlName="adminInformation" [form]="customerForm.get('step04')" [model]="model" (onInitForm)="initChildForm('step04', 'adminInformation',$event.value)"></app-admin-information>
          <div class="controls">
          <button mat-button matStepperPrevious class="btn_transparent">Back</button>
          <button type="button" mat-button matStepperNext class="btn_solid_color">Next  <img src="../../../../assets/img/btn_arrow.png" alt="icon" class="icon"></button>
        </div>
        </mat-step>

        <mat-step formGroupName="step05" [stepControl]="customerForm.get('step05')!">
          <ng-template matStepLabel>Preview</ng-template>
            <app-final-preview [model]="model" [form]="customerForm"></app-final-preview>
          <div class="controls">
            <button mat-button matStepperPrevious class="btn_transparent">Back</button>
            <button mat-button [disabled]="customerForm.invalid" type="button" class="btn_solid_color" (click)="onSubmit()">{{id ? 'Update' : 'Create'}}</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</div>
