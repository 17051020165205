import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-maticon',
  templateUrl: './maticon.component.html',
  styleUrls: ['./maticon.component.scss']
})
export class MaticonComponent implements OnInit {

  @Input() icon:any = null;
  
  constructor() { }

  ngOnInit(): void {
  }

}
