import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NotOnlySpaces(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value !== 'string') {
      return null;
    }

    const isNullOrWhiteSpace = !control.value.trim();
    return isNullOrWhiteSpace ? { 'emptyInput': true } : null;
  };
}
