import { Component, ElementRef, Inject } from '@angular/core';
import {Router} from "@angular/router";
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-app';

  public constructor( private router: Router,private element: ElementRef,
    @Inject(DOCUMENT) private document: Document) {
     // this.document.body.classList.add('sidebar-pinned');
  //  this.router.navigate(['auth/login']);
  }
}
