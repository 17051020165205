<!-- <mat-select formControlName="country_id" class="form-control no-boarder-field" (selectionChange)="onChangeCountry($event)" placeholder="{{placeholder}}">
  <mat-option [value]="country.id" *ngFor="let country of sharedService.countries">
    {{country.name}}
  </mat-option>
</mat-select> -->



<mat-form-field appearance="fill">
    <mat-label>Select Country</mat-label>
    <mat-select formControlName="country_id" class="form-control no-boarder-field" (selectionChange)="onChangeCountry($event)" placeholder="{{placeholder}}">
        <mat-option [value]="country.id" *ngFor="let country of sharedService.countries">
            {{country.name}}
        </mat-option>
    </mat-select>
  </mat-form-field>