import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {DatePipe} from '@angular/common';
import {SharedDataService} from "../../modules/shared/shared.data.service";
import {TenantService} from "../../modules/tenant/tenant.service";
import {CustomerService} from "../../modules/customer/customer.service";
import {JobsiteService} from "../../modules/jobsite/jobsite.service";
import {BidDataService} from "../../modules/sale/bid/bid.data.service";
import {ProposalDataService} from "../../modules/sale/proposal/proposal.data.service";


@Injectable({
  providedIn: 'root'
})
export class CoreService {


  private dateTimeFormat = 'Y-M-d H:m:s';
  public ngSelectBindLabel = 'name';
  public ngSelectBindStaffLabel = 'full_name';
  public ngSelectBindValue = 'id';

  constructor(
    public datepipe: DatePipe,
  ) {
  }

  isObject = (value: any) => {
   return typeof value === 'object' && value !== null;
  }

  isString = (value: any) => {
   return  typeof value === 'string' && value !== null;
  }

  isArray = (value: any) => {
    return value instanceof Array;
  }

  isObjectEmpty = (obj:object) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  generateApiUrl(actionName: string): string {
    return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
  }

  toMomentDate = (date: any, format: any = null) => {
    if (!format) {
      format = this.dateTimeFormat;
    }
    return this.datepipe.transform(date, format);
  }

  toDatePicker = (date: any, format: any = null, isTimeEnable:boolean=false) => {
    return this.datepipe.transform(date, (isTimeEnable)?'M/d/Y, H:m:s':'M/d/Y');
  }

   getRandomInteger = (min:number=0, max:number=1000000) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getPreviewData = (model:any, field:any, value:any) => {
    return model[field] = value;
  }

  arrayObjectToString = (objects:any[]=[]) => {
    return objects?.map((object)=>{
      return object.name;
    })?.join(', ');
  }

  arrayObjectId = (objects:any[]=[]) => {
    return objects?.map((object)=>{
      return object.id;
    });
  }

  toDateParse = (date: any, format: any = 'M/d/Y') => {
    return new Date(Date.parse(date));
  }


}
