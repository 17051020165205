import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { DialogService } from 'src/app/services/core/dialog.service';
import { ProductDataService } from '../../inventory/product/product.data.service';
import { AddEditTicketComponent } from '../../ticket/add-edit-ticket/add-edit-ticket.component';
import { AddTaskComponent } from '../../sale/task/add-task/add-task.component';
import { CreateNoteComponent } from '../../note/create-note/create-note.component';
import { AddEditVendorQuotesComponent } from '../vendor-quotes/add-edit-vendor-quotes/add-edit-vendor-quotes.component';

@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss']
})
export class ActionDropdownComponent implements OnInit {
  @Input() actions: any[] = [];
  @Input() moduleID:any
  @Input() ID:any
  @Output() callback = new EventEmitter<any>(true);


   constructor(
    public dialogService: DialogService,
    public productDataService: ProductDataService,
    public dialog: NgDialogAnimationService,
  ) { }

  ngOnInit(): void {


  }
  onclickManuitems(callback :any){
    this.callback.emit(callback);
  }

  addTicket(): void {
    this.dialogService.onOpenFromRightDialog(AddEditTicketComponent,{
      moduleID: this.moduleID,
      referenceID: this.ID,
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
        this.callback.emit('Add-Ticket');
      }
    });
  }

  createTask(): void {
    this.dialogService.onOpenFromRightDialog(AddTaskComponent,{
      moduleID: this.moduleID,
      referenceID: this.ID,
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
        this.callback.emit('Add-Task');
      }
    });
  }

  createNote(){
    this.dialogService.onOpenFromRightDialog(CreateNoteComponent,{
      moduleID: this.moduleID,
      referenceID: this.ID,
      isNoteModule:false
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
        this.callback.emit('Add-Note');
      }
    });
  }

  addVendorQuotes(): void {
    const dialogRef = this.dialog.open(AddEditVendorQuotesComponent, {
      width: '900px',
      minHeight:'500px',
      data: {
        moduleId:this.moduleID,
        referenceId: this.ID,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }


}

