import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const TaskTemplateApiResources = {
  list:  createUrl('task-template'),
  create:  createUrl('task-template/create'),
  updateStatus:  createUrl('task-template/change-status'),
  detail:  createUrl('task-template'),
  update:  createUrl('task-template'),
  delete:  createUrl('task-template'),
  taskSearch: createUrl('task-template/search-reference-numbers'),
  taskStatuses: createUrl('statuses/task'),
};
