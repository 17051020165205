<div class="task-history" [formGroup]="sectionForm">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Created By</th>
      <th scope="col">Time Elapsed</th>
      <th scope="col">Comment</th>
    </tr>
    </thead>
    <tbody>
    <ng-container formArrayName="timeElapsed">
      <ng-container *ngFor="let value of timeElapsed().controls; let index=index" [formArrayName]="index">
        <ng-container *ngIf="!timeElapsed().at(index).get('is_saved')?.value">
          <tr>
            <td class="date" style="width: 20%">
              <input type="date" formControlName="date" class="form-control">
            </td>
            <td style="width: 20%">
              <!--              <a href="#">hamiii478@gmail.com	</a>-->
              <a href="#"></a>
            </td>
            <td style="width: 20%">
              <div class="d-flex time-elapsed">
                <div class="hour">
                  <input type="number" appPositiveValueMask formControlName="hour" class="form-control" type="number" (keypress)="integerNumberOnly($event)" min="0"  (input)="integerNumberOnly($event)"> h
                </div>
                <div class="minute">
                  <input type="number"  appPositiveValueMask formControlName="minute" class="form-control" type="number" (keypress)="integerNumberOnly($event, 60)" min="0" max="60" (input)="integerNumberOnly($event, 60)"> m
                </div>
                <div class="second">
                  <input type="number" appPositiveValueMask formControlName="second" class="form-control" type="number" (keypress)="integerNumberOnly($event, 60)" min="0" max="60" (input)="integerNumberOnly($event, 60)"> s
                </div>
              </div>
            </td>
            <td>
              <div class="elapsed-comment">
                <div class="row">
                  <div class="col-10">
                    <input type="text" formControlName="comment" class="form-control" >
                  </div>
                  <div class="col-2">
                    <div class="buttons">
                      <button type="button"> <img src="../../../../../../assets/img/general_icons/tick1.png" alt="" (click)="saveValue(index)"> </button>
                      <button type="button"> <img src="../../../../../../assets/img/general_icons/cross1.png" alt="" (click)="deleteTimeElapsed(index)"> </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="timeElapsed().at(index).get('is_saved')?.value">
          <tr>
            <td class="date" style="width: 20%">
              {{this.time_elapsed[index]?.date}}
            </td>
            <td style="width: 20%">
              <!--                <a href="#">Usman</a>-->
              <a href="#">{{ value?.value?.created_by?.first_name }} {{ value?.value?.created_by?.last_name }}</a>
            </td>
            <td style="width: 20%">
              <div class="d-flex time-elapsed">
                {{ checkNumberHasTwoDigits(value?.value?.hour) }} : {{ checkNumberHasTwoDigits(value?.value?.minute) }} : {{ checkNumberHasTwoDigits(value?.value?.second) }}
              </div>
            </td>
            <td>
              <div class="elapsed-comment">
                <div class="row">
                  <div class="col-10">
                    {{ value?.value?.comment }}
                  </div>
                  <div class="col-2">
                    <div class="buttons">
                      <button type="button" *ngIf="!timeElapsed().at(index).get('is_saved')?.value" (click)="saveValue(index)"> <img src="../../../../../../assets/img/general_icons/tick1.png" alt=""> </button>
                      <button type="button" *ngIf="timeElapsed().at(index).get('is_saved')?.value"> <img src="../../../../../../assets/img/general_icons/edit-01.png" alt="" (click)="editTimeElapsed(index)"> </button>
                      <button type="button"> <img src="../../../../../../assets/img/general_icons/cross1.png" alt="" (click)="deleteTimeElapsed(index)"> </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    <!-- button start -->
    <tr>
      <td colspan="4">
        <div class="add-button">
          <button class="add-time-elapsed" (click)="addNewTimeElapsed()">Add</button>
        </div>
      </td>
    </tr>
    <!-- button end -->
    </tbody>
  </table>
</div>
