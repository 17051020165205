import { Injectable } from '@angular/core';
import {SharedService} from "../../shared/shared.service";
import {TenantService} from "../../tenant/tenant.service";
import {first} from "rxjs/operators";
import {PaymentTermDataService} from "./payment-term.data.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentTermService {

  constructor(
    public sharedService: SharedService,
    public tenantService: TenantService,
    public paymentTermDataService: PaymentTermDataService,
  ) {}


  getPaymentTerms = () => {
    return this.paymentTermDataService.list();
  }
}
