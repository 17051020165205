import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const PaymentTermApiResources = {
  list:  createUrl('payment-terms'),
  create:  createUrl('payment-terms/create'),
  detail:  createUrl('payment-terms'),
  update:  createUrl('payment-terms'),
  delete:  createUrl('payment-terms'),
};
