import { Injectable } from '@angular/core';
import {SharedService} from "../../shared/shared.service";
import {TenantService} from "../../tenant/tenant.service";
import {SkillDataService} from "./skill.data.service";
import {first} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(
    public sharedService: SharedService,
    public tenantService: TenantService,
    public skillDataService: SkillDataService,
  ) {}


  getSkills = () => {
    return this.skillDataService.list();
  }
}
