<ng-container [formGroup]="addressForm">
<div class="form-row">
    <div class="form-group col-12">
      <label for="{{randomID}}_address_line_1" class="custom_label">Address Line 1 {{staric}}</label>
      <input type="text" id="{{randomID}}_address_line_1" formControlName="address_line_1"
      class="form-control" placeholder="Address Line 1" name="address_line_1" [options]="mapOptions"  ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event,'address_line_1')">
      <app-validation-error *ngIf="isRequired"  [formGroup]="addressForm"  [controlName]="'address_line_1'"></app-validation-error>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label for="{{randomID}}_address_line_2" class="custom_label">Address Line 2  </label>
      <input type="text" id="{{randomID}}_address_line_2" formControlName="address_line_2"
      class="form-control" placeholder="Address Line 2" name="address_line_2" >
      <app-validation-error *ngIf="isRequired" [formGroup]="addressForm" [controlName]="'address_line_2'"></app-validation-error>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label for="{{randomID}}_city" class="custom_label">City  {{staric}}</label>
      <input type="text" id="{{randomID}}_city" formControlName="city" class="form-control" placeholder="City" name="city" >
      <app-validation-error *ngIf="isRequired" [formGroup]="addressForm" [controlName]="'city'"></app-validation-error>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12">
      <label for="{{randomID}}_state_id" class="custom_label">State  {{staric}}</label>
      <!--<app-select-box
        id="{{randomID}}_state_id"
        [name]="'state_id'"
        [items]="sharedService.states"
        [isRequired]="true"
        formControlName="state"
        [placeholder]="'Select State'"
        [selectedItem]="this.model?.state?.id"
      ></app-select-box>-->
      <ng-select
        id="state_id"
        [items]="sharedService.states"
        placeholder="Select State"
        [closeOnSelect]="true"
        formControlName="state_id"
        bindLabel="{{coreService.ngSelectBindLabel}}"
      >
      </ng-select>
      <app-validation-error *ngIf="isRequired" [formGroup]="addressForm" [controlName]="'state_id'"></app-validation-error>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12">
      <label for="{{randomID}}_zipcode" class="custom_label">Zip Code  {{staric}}</label>
      <input type="text"  id="{{randomID}}_zipcode" ZipcodeMask  formControlName="zipcode"  class="form-control"  placeholder="Zip Code" name="zipcode" (keydown)="preventSpace($event)">
      <app-validation-error  [formGroup]="addressForm" [controlName]="'zipcode'"></app-validation-error>
    </div>
  </div>
</ng-container>
