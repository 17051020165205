<div class="task-" [formGroup]="taskForm">
  <div class="task-wrapper">
    <div class="task-header">
      <div class="row">
        <div class="col-md-1 p-17">
          <div class="close-btn" (click)="close()">
            <img src="assets/img/cross_icon.svg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="title">
            <h3>{{taskID ? 'Update' : 'New'}} Task</h3>
          </div>
        </div>
        <div class="col-md-5">
          <div class="title-menu">
<!--            <div class="setting-icon">-->
<!--              <mat-icon>settings</mat-icon>-->
<!--            </div>-->

            <div class="templates" [matMenuTriggerFor]="taskTemplates">
              Task Templates
            </div>

            <mat-menu #taskTemplates="matMenu">
<!--              <button mat-menu-item>Template 1</button>-->
              <button mat-menu-item (click)="populateTemplate(template)" *ngFor="let template of templates">{{template?.name}}</button>
            </mat-menu>

            <div class="templates" [matMenuTriggerFor]="stage">
              {{ selectedStage?.name ?? 'Stages' }}
            </div>

            <mat-menu #stage="matMenu">
              <button mat-menu-item *ngFor="let stage of stages" (click)="addStage(stage)">{{ stage.name }}</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="task-body">
      <div class="card task-card">
        <div class="card-header task-card-header">
          <div class="task-info-title">
            <div class="row">
              <ng-container *ngIf="isTaskModule">
                <div class="col-md-12 mb-4">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group col-12">
                        <!--<label  class="custom_label"> Module *</label>-->
                        <ng-select
                          id="scope"
                          [items]="modulesList"
                          placeholder="Select Module"
                          [closeOnSelect]="true"
                          bindLabel="name"
                          (change)="selectedModule($event)"
                          appendTo="body"
                        >
                        </ng-select>
                        <!--<app-validation-error [formGroup]="taskInfoForm" [controlName]="'scope'"></app-validation-error>-->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <ng-container *ngIf="!showSearchInput">
                        <div class="cross-icon">
                          <span class="search-text"> {{this.selectedItem?.data ?? 'Search'}}</span>
                          <span class="cross" (click)="updateSearchInputFlag()">&#10005;</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="showSearchInput">
                        <app-search-field
                          [clearResults]="this.taskForm.get('module_id')?.value"
                          [searchAbleDataService]="taskDataService"
                          [searchAbleFunctionName]="'searchTaskReferenceNumber'"
                          [searchAbleParams]="searchParams"
                          [labelKey]="'data'"
                          (onSelectValue)="onItemSelect($event)"
                          [searchPlaceHolder]="'Search reference number'"
                        >
                        </app-search-field>
<!--                        <div class="cross-icon" style="min-height: 32px;"  *ngIf="!this.taskForm.get('module_id')?.value">-->
<!--                          <span class="search-text"> </span>-->
<!--                        </div>-->
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="col-md-9">
                <input type="text" formControlName="title" class="form-control" placeholder="Enter Task Name" >
              </div>
              <div class="col-md-3">
                <div class="info-panel-important">
                  <mat-checkbox formControlName="priority" (change)="changePriority($event)"></mat-checkbox>
                  <p>High Priority</p>
                  <img src="../../../../../assets/img/general_icons/color-fire.png" alt="fire icon" *ngIf="CONST_HIGH_PRIORITY">
                  <img src="../../../../../assets/img/general_icons/light-fire.png" alt="fire icon" *ngIf="!CONST_HIGH_PRIORITY">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body task-card-body">
          <div class="description-editor">
            <textarea class="text-area" formControlName="description" placeholder="Type here...." [mentionConfig]="{labelKey:'full_name'}"
            (itemSelected)="getSelectedUser($event)" (searchTerm)="getUsers($event)" [mention]="mentions" labe></textarea>
          </div>

          <div class="tabs bid_tabs_cards">
            <mat-tab-group>
              <mat-tab label="File" class="nav-link">
                <ng-template matTabContent>
                  <div class="tabs-bg">
                    <!-- <app-attachment [dropZoneId]="'task_files_drop'" [module_id]="TASK_MODULE_ID" (callback)="getFileID($event)"></app-attachment> -->
                    <app-attachment [dropZoneId]="'task_files_drop'" [multipleUpload]="true" [multipleUploadData]="attachmentsArr"  (callback)="getFileID($event)" [module_id]="TASK_MODULE_ID"></app-attachment>
                  </div>
                </ng-template>
              </mat-tab>

              <!--<mat-tab label="Comment" class="nav-link">
                <ng-template matTabContent>
                  <div class="tabs-bg">
                    <app-comments></app-comments>
                  </div>
                </ng-template>
              </mat-tab>-->

              <mat-tab label="@ Mention" class="nav-link">
                <ng-template matTabContent>
                  <div class="tabs-bg">
                    <mat-chip-list>
                      <mat-chip *ngFor="let user of selectedMentionedUsers; let index=index"><span>{{user.full_name}}</span><span class="cursor-pointer ml-2" (click)="remove(index,selectedMentionedUsers)"><img src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>
                    </mat-chip-list>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="Check list" class="nav-link">
                  <div class="tabs-bg">
                    <app-add-checklist (callback)="getChecklists($event)" [checkLists]="model?.check_list"></app-add-checklist>
                  </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="task-options">
            <!-- Responsible person item start -->
            <div class="task-options-item">
              <div class="row">
                <div class="col-md-2">
                  <p class="item-name">Responsible Person</p>
                </div>
                <div class="col-md-10">
                  <div class="row task-item-field-inner">
                    <div class="col-md-7 task-item-field">
                     <div class="added-persons">
                       <mat-chip-list>
                         <mat-chip *ngFor="let user of responsibleUsersArray; let index=index"><span>{{user.full_name}}</span><span class="cursor-pointer ml-2" (click)="remove(index,responsibleUsersArray)"><img src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>
                       </mat-chip-list>
                     </div>
                      <div class="add-more-btn" (click)="openUsersPopup($event,CONST_RESPONSIBLE_PERSON_TYPE)">+ Add more</div>
                    </div>
                    <div class="col-md-5">
                      <span class="task-dashed-items">
                        <span class="task-dashed-link-inner" (click)="hideShowCreatedBy()">Created by</span>
                        <span class="task-dashed-link-inner" (click)="hideShowObserver()">Observer</span>
                        <span class="task-dashed-link-inner" (click)="hideShowParticipant()">Participant</span>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- Responsible person item end -->

            <!-- Created by item start -->
            <ng-container *ngIf="CONST_CREATED_BY">
              <div class="task-options-item">
                <div class="row">
                  <div class="col-md-2">
                    <p class="item-name">Created by</p>
                  </div>
                  <div class="col-md-10">
                    <div class="row task-item-field-inner">
                      <div class="col-md-11 task-item-field">
                        <div class="added-persons">
                          <mat-chip-list>
                            <mat-chip *ngIf="createdByArray"><span >{{createdByArray.full_name}}</span> <!--<span class="cursor-pointer ml-2"><img src="assets/img/cross_icon.svg" alt=""></span>--> </mat-chip>
                          </mat-chip-list>
                        </div>
                        <div class="add-more-btn" (click)="openUsersPopup($event,CONST_CREATED_BY_TYPE)">Change</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Created by item end -->

            <!-- Observer item start -->
            <ng-container *ngIf="CONST_OBSERVER">
              <div class="task-options-item">
                <div class="row">
                  <div class="col-md-2">
                    <p class="item-name">Observer</p>
                  </div>
                  <div class="col-md-10">
                    <div class="row task-item-field-inner">
                      <div class="col-md-11 task-item-field">
                        <div class="added-persons">
                          <mat-chip-list>
                            <mat-chip *ngFor="let user of observerArray; let index=index"><span>{{user.full_name}}</span><span class="cursor-pointer ml-2" (click)="remove(index,observerArray)"><img src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>
                          </mat-chip-list>
                        </div>
                        <div class="add-more-btn" (click)="openUsersPopup($event,CONST_OBSERVER_TYPE)">+ Add more</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Observer item end -->

            <!-- participant item start -->
            <ng-container *ngIf="CONST_PARTICIPANT">
              <div class="task-options-item">
                <div class="row">
                  <div class="col-md-2">
                    <p class="item-name">Participant</p>
                  </div>
                  <div class="col-md-10">
                    <div class="row task-item-field-inner">
                      <div class="col-md-11 task-item-field">
                        <div class="added-persons">
                          <mat-chip-list>
                            <mat-chip *ngFor="let user of participantArray; let index=index"><span>{{user.full_name}}</span><span class="cursor-pointer ml-2" (click)="remove(index,participantArray)"><img src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>                          </mat-chip-list>
                        </div>
                        <div class="add-more-btn" (click)="openUsersPopup($event,CONST_PARTICIPANT_TYPE)">+ Add more</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
            <!-- participant item end -->

            <!-- deadline item start -->
            <div class="task-options-item">
              <div class="row">
                <div class="col-md-2">
                  <p class="item-name">Deadline</p>
                </div>
                <div class="col-md-10">
                  <div class=" task-item-field-inner">
                    <div class="row" [ngClass]="{'deadline':CONST_TIME_PLANNING}">
                      <div class="col-md-4 task-item-field">
                        <input matInput formControlName="due_date" [matDatepicker]="picker" (click)="picker.open()" class="form-control">
                        <div class="calender-icon">
                          <mat-datepicker-toggle matIconSuffix [for]="picker">
                            <img src="assets/img/calendar.png" matDatepickerToggleIcon alt="">
                            <!--                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <!--                          <img src="assets/img/calendar.png" alt="">-->
                        </div>
                      </div>
                      <div class="col-md-3">
                      <span class="task-dashed-items">
                        <span class="task-dashed-link-inner" (click)="hideShowTimePlanning()">Time plannings</span>
                      </span>
                      </div>
                    </div>
                  </div>

                  <!-- time planning section start -->
                  <ng-container *ngIf="CONST_TIME_PLANNING">
                    <div class="row time-planning task-item-field-inner">
                      <div class="col-md-3">
                        <label class="task-field-label">Start task on</label>
                        <div class=" task-item-field">
                          <input formControlName="start_date" matInput [matDatepicker]="picker1" (click)="picker1.open()" class="form-control">
                          <div class="calender-icon">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1">
                              <img src="assets/img/calendar.png" matDatepickerToggleIcon alt="">
                              <!--                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <!--                          <img src="assets/img/calendar.png" alt="">-->
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <label class="task-field-label">Duration</label>
                        <div class=" task-item-field">
                          <input formControlName="duration" matInput class="form-control">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <label class="task-field-label">Finish</label>
                        <div class=" task-item-field">
                          <input formControlName="date_finished" matInput [matDatepicker]="finish" (click)="finish.open()" class="form-control">
                          <div class="calender-icon">
                            <mat-datepicker-toggle matIconSuffix [for]="finish">
                              <img src="assets/img/calendar.png" matDatepickerToggleIcon alt="">
                            </mat-datepicker-toggle>
                            <mat-datepicker #finish></mat-datepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- time planning section end -->

                </div>
              </div>
            </div>
            <!-- deadline item end -->

            <!-- mark task as template section start -->
           <!-- <div class="task-options-item">
              <div class="row">
                <div class="col-md-2">
                  <p class="item-name">Task template status</p>
                </div>
                <div class="col-md-10">
                  <div class="row task-item-field-inner">
                    <div class="template-status">
                      <mat-checkbox formControlName="is_template">Mark this task template</mat-checkbox>
                    </div>
                  </div>

                </div>
              </div>
            </div>-->
            <!-- mark task as template section end -->
          </div>
        </div>
      </div>
    </div>
    <!--<div class="close-btn" (click)="close()">
      <img src="assets/img/cross_icon.svg">
    </div>-->
  </div>
  <div class="task-footer">
    <div class="task-buttons">
      <button type="button" class="btn add-task-button" (click)="save()">{{taskID ? 'UPDATE' : 'ADD'}} TASK</button>
      <button type="button" class="btn cancel-btn" (click)="close()">Cancel</button>
    </div>
    <div class="save-template" *ngIf="!taskID">
      <mat-checkbox formControlName="is_template">Save as Template</mat-checkbox>
    </div>
  </div>
</div>
