import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, finalize } from 'rxjs/operators';
import { ToasterService } from '../services/core/toaster.service';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {CoreService} from '../services/core/core.service';
import {UserService} from "../services/core/user.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  showSpinner: any = [];
  constructor(
    private toasterService: ToasterService,
    private spinner: NgxSpinnerService,
    private coreService: CoreService,
    private currentUser: UserService,
    private router: Router,
  ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

     //console.log('request', request?.body?.get('loadingService'));
      if(request.method == 'PATCH' || request.method == 'POST') {
        // @ts-ignore
        if ( (request?.body?.loadingService == undefined || request?.body?.loadingService)) {
          this.showSpinner.push('loading in progress');
          if (this.showSpinner.length > 0) {
            this.spinner.show();
          }
        }
      }

      if(request.method == 'GET' && !request.params.has('loadingService') || request.params.has('loadingService') &&  request.params.get('loadingService') == 'true') {
        this.showSpinner.push('loading in progress');

        if(this.showSpinner.length > 0) {
          this.spinner.show();
        }
      }

      if (request.body && request.body.loadingService !== undefined) {
        // Clone the request and remove the 'loadingService' key from the body
        const updatedBody = { ...request.body };
        delete updatedBody.loadingService;

        const updatedRequest = request.clone({
          body: updatedBody,
        });

        // Continue with the updated request
        request = updatedRequest;
      }

      if (request.method == 'GET' && request.params.get('loadingService') !== undefined) {
        // Clone the request and remove the 'loadingService' key from the body

        const updateRequest = request.clone({
          params: request.params.delete('loadingService'),
        });

        // Continue with the updated request
        request = updateRequest;
      }

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {

                  if (error.status == 500){
                    this.toasterService.showError("Error", 'Internal Server Error!');
                  }
                  console.log('handle', error);
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.message}`;
                        this.toasterService.showError("Error", errorMessage);
                    } else {
                      let displayErrorMessages = error.error.errors;

                      if(displayErrorMessages == undefined) {
                        displayErrorMessages = error.error.data.errors;
                      }

                      console.log('displayErrorMessages 1', displayErrorMessages);

                        // server-side error
                        const isArray = Array.isArray(displayErrorMessages);
                      console.log('displayErrorMessages isArray', isArray);
                        const objectKeys = displayErrorMessages ? Object.keys(displayErrorMessages) : [];
                      console.log('displayErrorMessages objectKeys', objectKeys);
                        const isObject = objectKeys.length;
                      console.log('displayErrorMessages objectKeys', objectKeys);
                        if (isArray) {
                            const errorsLen = Object.keys(displayErrorMessages).length;
                            if (errorsLen) {
                                Object.keys(displayErrorMessages).forEach((item, i) => {
                                    if (i < 2) {
                                        this.toasterService.showError(`Error in : ${item}`, `${displayErrorMessages[item][0]}`);
                                    }
                                });
                            }
                        } else if(this.coreService.isString(displayErrorMessages)) {
                            if(displayErrorMessages === 'Access denied') {
                              this.currentUser.flushAll();
                              this.router.navigate(['/auth/login']);
                            }
                            console.log('displayErrorMessages', displayErrorMessages);
                            this.toasterService.showError('Opps!', displayErrorMessages);
                        }
                        else if(isObject) {
                            Object.keys(displayErrorMessages).forEach((item, i) => {
                                if (i < 2) {
                                    this.toasterService.showWarning(`${item}`, `${displayErrorMessages[item][0]}`);
                                }
                            });
                        }
                        else {
                            if (error.error.errors) {
                                const errors = displayErrorMessages.toString().slice(0, 70);
                                this.toasterService.showError(`Error Code: ${error.status}`, errors);
                            } else  if (error.error.data.message) {
                                this.toasterService.showWarning(error.error.data.message);
                            }
                            else {
                                this.toasterService.showError(`Error Code: ${error.status}`, `${error.error.message}`);
                            }
                        }
                    }
                    return throwError(error);
                }),
                finalize(() =>{


                  if(request.method == 'PATCH' || request.method == 'POST') {
                    // @ts-ignore
                    if ( (request?.body?.loadingService == undefined || request?.body?.loadingService)) {
                      this.showSpinner.pop();
                    }
                  }

                  if(request.method == 'GET' && !request.params.has('loadingService') || request.params.has('loadingService') &&  request.params.get('loadingService') == 'true') {
                    this.showSpinner.pop();
                  }




                  // if(request?.body?.loadingService == undefined ||  request?.body?.loadingService) {
                  //   console.log('request?.body?.loadingService pop 1', this.showSpinner);
                  //   this.showSpinner.pop();
                  // }
                  //
                  // if(request?.params?.get('loadingService') == undefined ||  request?.params?.has('loadingService')) {
                  //   console.log('request?.body?.loadingService pop 2', this.showSpinner);
                  //   this.showSpinner.pop();
                  // }
                  //

                  // console.log('request?.body?.loadingService length', this.showSpinner);
                  if(this.showSpinner.length == 0) {

                    this.spinner.hide();
                  }
                })
            )
    }
}
