import {Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {first, pairwise, startWith} from "rxjs/operators";
import {CustomerService} from "../../customer.service";
import {MatSliderChange} from "@angular/material/slider";
import {formatCurrency} from "@angular/common";
import {Subscription} from "rxjs";
import {SharedService} from "../../../shared/shared.service";
import {CurrencyMaskOptions} from "../../../shared/shared.constants";
import { NotOnlySpaces } from '../../../shared/CustomValidators/CustomNotOnlySpacesValidator';
@Component({
  selector: 'app-admin-information',
  templateUrl: './admin-information.component.html',
  styleUrls: ['./admin-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdminInformationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AdminInformationComponent),
      multi: true
    }
  ]
})
export class AdminInformationComponent implements OnInit {

  @Input() model: any = {};
  @Input() form: any = {};
  @Output() onInitForm = new EventEmitter<any>(true);
  @Output() callback = new EventEmitter<any>(true);

  public risk_level: any = 0;
  public paymentMethods: string[] = [];
  public taxAndDiscountClasses: string[] = [];
  public sources: object[] = [{id:1, name: 'Source 001'}, {id:2, name: 'Source 002'}];
  //@ts-ignore
  public adminInformationSubscription: Subscription;
  public currencyMaskOptions = CurrencyMaskOptions;

  public adminInformation: UntypedFormGroup = new UntypedFormGroup({
    tax_exempt:new UntypedFormControl(0),
    payment_method:new UntypedFormControl(null),
    markup:new UntypedFormControl(null),
   // tax_class:new FormControl(null),
  //  disc_class:new FormControl(null),
    source:new UntypedFormControl(null),
    lien_period:new UntypedFormControl(null),
    risk:new UntypedFormControl(null),
    risk_level:new UntypedFormControl(null),
  });

  constructor(
    private customerService:CustomerService,
    public sharedService: SharedService,
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
    this.onModelValueChanged  = this.model?.model?.admin_info;

  }

  ngOnInit(): void {
    this.initSubscriptions()
    this.getPaymentMethods();
    this.getTaxAndDiscountClasses();
    this.getSources();
    this.initFormValues();
  }
  initFormValues(){
    this.adminInformation.get('tax_exempt')?.setValue(this.onModelValueChanged?.tax_exempt ? true : false);
    this.adminInformation.get('markup')?.setValue(this.onModelValueChanged?.markup ? this.onModelValueChanged?.markup : '');
    this.adminInformation.get('lien_period')?.setValue(this.onModelValueChanged?.lien_period ?  this.onModelValueChanged?.lien_period : 0);
    this.adminInformation.get('risk_level')?.setValue(this.onModelValueChanged?.risk ?   this.onModelValueChanged?.risk : 0);
    this.adminInformation.get('risk')?.setValue(this.onModelValueChanged?.risk ?   this.onModelValueChanged?.risk : 0);
    this.adminInformation.get('source')?.setValue(this.onModelValueChanged?.source ?    this.onModelValueChanged?.source : null);
    this.onInitForm.emit(this.adminInformation);
  }
  setPaymentMethodValue(){
    const paymentMethod = this.paymentMethods.find((x:any) => x.id == this.onModelValueChanged?.default_payment_method);
    if(paymentMethod){
      this.adminInformation.get('payment_method')?.setValue(paymentMethod);
      this.onInitForm.emit(this.adminInformation);
    }

  }
  public onModelValueChanged:any = {};



  onInputChange(event: MatSliderChange) {
    // const value = formatCurrency(event.value != null ? event.value : 0,'en-US','$','USD','1.2-2');
    this.adminInformation.controls['risk_level'].setValue(event.value);
    // console.log(2, this.adminInformation?.get('risk_level')?.value);
  }

  onValueInput(event: any){
    const risk_level = this.adminInformation.controls['risk_level']?.value;
    // this.adminInformation.controls['risk'].setValue(risk_level.toString().replace(',',''));
    this.adminInformation.controls['risk'].setValue(risk_level);
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  getPaymentMethods = () => {
    this.customerService.getPaymentMethods()
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.paymentMethods = data.data.map((group:any)=>{
            return {id: group.id, name: group.name};
          });
          this.setPaymentMethodValue();
        });
  }

  getTaxAndDiscountClasses = () => {
    this.customerService.getPaymentMethods()
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.taxAndDiscountClasses = data.data.map((group:any)=>{
            return {id: group.id, name: group.name};
          });
        });
  }

  getSources = () => {
    this.sharedService.getSources()
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.sources = data.data.map((source:any)=>{
            return {id: source.id, name: source.name};
          });
        });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.adminInformation.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.adminInformation.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.adminInformation.disable() : this.adminInformation.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.adminInformation.valid ? null : { invalidForm: {valid: false, message: "Admin Information fields are invalid"}};
  }

  initSubscriptions(){
    if (this.form){
      this.adminInformationSubscription = this.form.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.form.touched){
            this.touchFormControls();
          }
        });
    }

  }
  touchFormControls(){
    Object.keys(this.adminInformation.controls).forEach(key => {
      this.adminInformation.get(key)?.markAsTouched();
    });
  }
  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.adminInformationSubscription?.unsubscribe();
  }
}
