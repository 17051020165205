import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {ToasterService} from "../services/core/toaster.service";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toasterService: ToasterService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if(error instanceof HttpErrorResponse && error.status == 403) {
          this.router.navigate(['/access-denied']);
          const errorMessage = `${error.error.message}`;
          this.toasterService.showErrorMessage(errorMessage);
        }
        return throwError(error);
      })
    );
  }
}
