export function getBackgroundColor(backgroundColor: string): string {
  if (!backgroundColor) {
    return '';
  }

  let r, g, b;

  if (backgroundColor.startsWith('#')) {
    // Hex format
    const hex = backgroundColor.replace('#', '');
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else if (backgroundColor.startsWith('rgb')) {
    // RGB format
    const rgbValues = backgroundColor.match(/\d+/g);
    if (rgbValues && (rgbValues.length === 3 || rgbValues.length === 5)) {
      r = parseInt(rgbValues[0]);
      g = parseInt(rgbValues[1]);
      b = parseInt(rgbValues[2]);
    } else {
      return '';
    }
  } else {
    return ''; // Return empty string if format is unrecognized
  }

  // Calculate the relative luminance (using sRGB values)
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return black for light colors and white for dark colors
  return luminance > 220 ? '#808080' : '#FFFFFF';
}
