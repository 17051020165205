import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const JobsiteApiResources = {
  list:  createUrl('jobsites'),
  create:  createUrl('jobsites/create'),
  detail:  createUrl('jobsites'),
  update:  createUrl('jobsites'),
  updateStatus:  createUrl('jobsites/update-status'),
  delete:  createUrl('jobsites'),
  types:  createUrl('jobsites/contact-types'),
};
