import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { pairwise, startWith } from 'rxjs';

@Component({
  selector: 'app-final-preview-contact',
  templateUrl: './final-preview-contact.component.html',
  styleUrls: ['./final-preview-contact.component.scss']
})
export class FinalPreviewContactComponent implements OnInit, OnChanges {

  @Input() model: any = {};
  @Input() form: any = {};
  @Input() isEdit : boolean = false;
  @Output() callback = new EventEmitter<any>(true);

  public contact: any = {};

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.model = changes?.model?.currentValue;
    this.isEdit = changes?.isEdit?.currentValue;
    //this.contact = this.model;
    this.form.get('step01').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.contact = next?.contact;
      });
  }

  ngOnInit(): void {

  }

}
