<div class="content-block dx-card responsive-paddings">
  <dx-data-grid
    #dxDataGridComponent
    class="d-grid-no-bacth-actions-filter custom_table_style"
    id="gridContainer"
    [showColumnLines]="showColumnLines"
    [showRowLines]="showRowLines"
    [allowColumnResizing]="allowColumnResizing"
    [allowColumnReordering]="allowColumnReordering"
    [dataSource]="dataSource"
    [showBorders]="showBorders"
    [remoteOperations]="true"
    [columns]="columns"
    (onCellClick)="onCellClick($event)"
  >
    <dxo-editing mode="row" [allowAdding]="false"></dxo-editing>
    <dxo-editing mode="row" [allowAdding]="false"></dxo-editing>
<!--    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>-->
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="{{module}}" ></dxo-state-storing>
    <dxo-paging [pageSize]="10" ></dxo-paging>
    <dxo-pager
      [visible]="visible"
      [allowedPageSizes]="allowedPageSizes"
      [displayMode]="displayMode"
      [showPageSizeSelector]="showPageSizeSelector"
      [showInfo]="showInfo"
      [showNavigationButtons]="showNavButtons">
    </dxo-pager>
    <dxo-filter-row [visible]="visible"></dxo-filter-row>
    <dxo-header-filter [visible]="visible"></dxo-header-filter>
    <dxo-selection *ngIf="selectMulitple" mode="multiple" selectAllMode="page" showCheckBoxesMode="always"></dxo-selection>
    <!--<dxo-toolbar>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" (onClick)="refreshDataGrid()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
    </dxo-toolbar>-->
  </dx-data-grid>
</div>
