<div class="row overide-header text-end m-auto" [formGroup]="sectionForm">
  <div class="tkts-popup-body">
    <div class="notes-wrap" id="noteWrap">
      <!--<div class="">
        <h6>Notes</h6>
        <p>{{model?.notes}}</p>
        <div class="row">
          <div class="col-md-8">

          </div>
          <div class="col-md-4 text-right">
            <div class="name-user-tkt mb-1"><span class="mr-1">{{model?.user?.full_name}}</span>
              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none">
                                    <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                            </span>
            </div>
            <div class="time-date-tkt"><span>12-22-2021 | 10:28pm</span></div>
          </div>
        </div>
      </div>-->

      <ng-container *ngFor="let note of comments">
        <div class="">
          <p>{{note?.comment}}</p>
          <div class="row">
            <!-- Files Attached With note section start -->
            <div class="col-md-8">
              <ng-container *ngIf="note?.attach_files.length > 0">
                <ng-container *ngFor="let file of note?.attach_files">
                  <a [href]="file?.url" class="cloud-box" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none">
                      <path d="M12 9.5V15.5M12 15.5L10 13.5M12 15.5L14 13.5M8.4 19C5.41766 19 3 16.6044 3 13.6493C3 11.2001 4.8 8.9375 7.5 8.5C8.34694 6.48637 10.3514 5 12.6893 5C15.684 5 18.1317 7.32251 18.3 10.25C19.8893 10.9449 21 12.6503 21 14.4969C21 16.9839 18.9853 19 16.5 19L8.4 19Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </ng-container>
              </ng-container>
            </div>
            <!-- Files Attached With note section end -->

            <!-- User name and note sent Date Time section start -->
            <div class="col-md-4 text-right">
              <div class="name-user-tkt mb-1"><span class="mr-1">{{note?.creator_id?.first_name + ' ' +note?.creator_id?.last_name}}</span>
                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none">
                                    <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                            </span>
              </div>
              <div class="time-date-tkt"><span>{{note?.created_at | date}} | {{note?.created_at | date:'h:m a'}}</span></div>
            </div>
            <!-- User name and note sent Date Time end -->
          </div>
        </div>
      </ng-container>

    </div>

    <div class="textarea-tkt">
      <div class="row mb-4">
        <div class="col-md-1">
          <span class="user-img-tkt">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                            <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </span>
        </div>
        <div class="col-md-11" [formGroup]="sectionForm">
          <mat-form-field class="example-full-width1">
            <textarea matInput formControlName="note" placeholder="Type note here....." (keydown)="handleKeydown($event)" ></textarea>
            <!--            <app-validation-error [formGroup]="sectionForm" [controlName]="'note'"></app-validation-error>-->
          </mat-form-field>
          <div class="textarea-handles">
            <span class="user-attach-" (click)="fileUpload()">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="16px" height="16px" viewBox="0 0 28 28" version="1.1">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                    <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-258.000000, -154.000000)" fill="#fff">
                                        <path d="M284.562,164.181 L270.325,178.26 C267.966,180.593 264.141,180.593 261.782,178.26 C259.423,175.928 259.423,172.146 261.782,169.813 L274.596,157.141 C276.168,155.586 278.718,155.586 280.291,157.141 C281.863,158.696 281.863,161.218 280.291,162.772 L267.477,175.444 C266.691,176.222 265.416,176.222 264.629,175.444 C263.843,174.667 263.843,173.406 264.629,172.628 L276.02,161.365 L274.596,159.957 L263.206,171.221 C261.633,172.775 261.633,175.297 263.206,176.853 C264.778,178.407 267.328,178.407 268.901,176.852 L281.714,164.181 C284.073,161.849 284.074,158.065 281.715,155.733 C279.355,153.4 275.531,153.4 273.172,155.733 L259.646,169.108 L259.696,169.157 C257.238,172.281 257.455,176.797 260.358,179.668 C263.262,182.539 267.828,182.754 270.987,180.323 L271.036,180.372 L285.986,165.589 L284.562,164.181" id="attachment-2" sketch:type="MSShapeGroup"></path>
                                    </g>
                                </g>
                            </svg>
            </span>
            <span class="user-edit-" (click)="save()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"><path d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696L175.168 732.8zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336L104.32 708.8zm384 254.272v-64h448v64h-448z" fill="#fff"/></svg>
            </span>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="allowUploadFile">
      <app-attachment
        [fileTypes]="allowedFileTypes"
        [multipleUpload]="multipleUpload"
        [multipleUploadData]="attachmentsArr"
        (callback)="attachment($event)"
        [module_id]="module_id"></app-attachment>
      <!-- <app-attachment [module_id]="module_id" [multipleUpload]="multipleUpload" [fileTypes]="allowedFileTypes" (callback)="attachment($event)"></app-attachment> -->
    </ng-container>

  </div>
</div>
