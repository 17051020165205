import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const CustomerContactApiResources = {
  list:  createUrl('contacts'),
  search: createUrl('jobs/contact-search'),
  create:  createUrl('contacts'),
  detail:  createUrl('contacts'),
  update:  createUrl('contacts'),
  delete:  createUrl('contacts'),
};
