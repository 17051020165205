import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor() { }

  //The set method is use for encrypt the value.
  set(keys: any, value: any){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  encryptObject(keys: any, value: any){
    var cip = CryptoJS.AES.encrypt(JSON.stringify(value), keys).toString();
    return cip;
  }


  decryptObject(keys: any, value: any){
    const decrypted = CryptoJS.AES.decrypt(value, keys);
    const data = CryptoJS.enc.Utf8.stringify(decrypted);
    return JSON.parse(data);
  }

  encrypt(keys: any, value: any){
    var cip = CryptoJS.AES.encrypt(value, keys).toString();
    return cip;
  }


  decrypt(keys: any, value: any){
    const decrypted = CryptoJS.AES.decrypt(value, keys);
    const data = CryptoJS.enc.Utf8.stringify(decrypted);
    return data;
  }

  //The get method is use for decrypt the value.
  get(keys: any, value: any){
   // console.log('Hash', keys, value);
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
