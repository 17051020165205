import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const CertificateApiResources = {
  list:  createUrl('certificates'),
  create:  createUrl('certificates/create'),
  detail:  createUrl('certificates'),
  update:  createUrl('certificates'),
  delete:  createUrl('certificates'),
  types:  createUrl('certificates/certificate-types'),
};
