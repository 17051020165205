import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RightPanelHideShowService {

  isHide: boolean = true;
  isSideBarOpen: boolean = false;
  constructor() { }

  toggleRightSidePanel() {
    this.isHide = !this.isHide;

    if(!this.isHide) {
      this.showRightSidePanel();
    } else {
      this.hideRightSidePanel();
    }
  }

  hideRightSidePanel() {
    // this.isHide = true;
    // const panel_xl4 = document.getElementsByClassName('col-xl-4');
    // const panel_xxl3 = document.getElementsByClassName('col-xxl-3');
    //
    // const panel_xl8 = document.getElementsByClassName('col-xl-8');
    // const panel_xxl9 = document.getElementsByClassName('col-xxl-9');
    //
    //
    // if(panel_xl8.length > 0) {
    //   //@ts-ignore
    //   panel_xl4[0].style.display = 'none';
    //   //@ts-ignore
    //   panel_xl8[0].classList.add('col-xl-12');
    // }
    //
    // if(panel_xxl9.length > 0) {
    //   //@ts-ignore
    //   panel_xxl3[0].style.display = 'none';
    //   //@ts-ignore
    //   panel_xxl9[0].classList.add('col-xxl-12');
    // }
  }

  showRightSidePanel() {
  //   const panel_xl4 = document.getElementsByClassName('col-xl-4');
  //   const panel_xxl3 = document.getElementsByClassName('col-xxl-3');
  //   const panel_xl8 = document.getElementsByClassName('col-xl-8');
  //   const panel_xxl9 = document.getElementsByClassName('col-xxl-9');
  //
  //
  //   if(panel_xl8.length > 0) {
  //     //@ts-ignore
  //     panel_xl8[0].classList.remove('col-xl-12');
  //     //@ts-ignore
  //     panel_xl4[0].style.display = 'block';
  //   }
  //   if(panel_xxl9.length > 0) {
  //     //@ts-ignore
  //     panel_xxl9[0].classList.remove('col-xxl-12');
  //     //@ts-ignore
  //     panel_xxl3[0].style.display = 'block';
  //   }
  }
}
