import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HashService} from './services/core/hash.service';
import {LocalStorageService} from './services/core/local-storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {AuthInterceptor} from './interceptors/auth.interceptor';
//import {ModalPopupService} from "./services/core/modal-popup.service";
import {DatePipe} from "@angular/common";
import {CalculationService} from "./services/calculation.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {SharedModule} from "./modules/shared/shared.module";
import { RouterModule } from '@angular/router';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {UnauthorizedInterceptor} from "./interceptors/unauthorized.interceptor";



@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    SharedModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot([]),
    // ToastrModule added,
  ],

  providers: [
    HashService,
    LocalStorageService,
    MatDatepickerModule,
    //ModalPopupService,
    DatePipe,
    NgDialogAnimationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },

  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
