<div class="dropdown generic_dropdown_with_plus">
  <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown">
    <span class="text">+</span>
  </button>
  <ul class="dropdown-menu" style="cursor: pointer;">
    <li *ngFor="let action of actions; let i = index">


      <a *ngIf="action?.route" [routerLink]="[action?.route]" routerLinkActive="active" class="dropdown-item" href="javascript:void(0);">{{action?.title}}</a>
      <a *ngIf="action?.callback" [appPermission]="action?.permission || 'DONOTAPPLY'" class="dropdown-item" (click)="onclickManuitems(action?.callback)">{{action?.title}}</a>
    </li>

    <li (click)="addTicket()"><p class="dropdown-item">Add Ticket</p></li>
    <li (click)="createTask()"><p class="dropdown-item">Add Task</p></li>
    <li (click)="createNote()"><p class="dropdown-item">Add Note</p></li>
    <li (click)="addVendorQuotes()"><p class="dropdown-item">Add Vendor Quotes</p></li>

   <!--<li><a class="dropdown-item" href="javavscript:void(0);">Proposals</a></li>
   <li><a class="dropdown-item" href="javavscript:void(0);">Jobs</a></li>

    <li><a class="dropdown-item" href="javavscript:void(0);">Bids</a></li>
    <li><a class="dropdown-item" href="javavscript:void(0);">Notes</a></li>
    <li><a class="dropdown-item" href="javavscript:void(0);">Documents</a></li>
    <li><a class="dropdown-item" href="javavscript:void(0);">Tickets</a></li>
    <li><a class="dropdown-item" href="javavscript:void(0);">Reminders</a></li>
    <li><a class="dropdown-item" href="javavscript:void(0);">Tasks</a></li> -->
  </ul>
</div>
