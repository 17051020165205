import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const SubcontractorApiResources = {
  list:  createUrl('subcontractors'),
  create:  createUrl('subcontractors/create'),
  detail:  createUrl('subcontractors'),
  update:  createUrl('subcontractors'),
  delete:  createUrl('subcontractors'),
  attachSkill:  createUrl('subcontractors/skill/attach'),
  detachSkill:  createUrl('subcontractors/skill/detach'),
};
