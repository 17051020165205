import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-font-awesome-icon',
  templateUrl: './font-awesome-icon.component.html',
  styleUrls: ['./font-awesome-icon.component.scss']
})
export class FontAwesomeIconComponent implements OnInit {

  @Input() fontSet:any = 'fas';
  @Input() icon:any = null;
  @Input() prefix:any = 'fa';

  constructor() { }

  ngOnInit(): void {
  }

}
