import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const VendorApiResources = {
  list:  createUrl('vendors'),
  create:  createUrl('vendors/create'),
  detail:  createUrl('vendors'),
  update:  createUrl('vendors'),
  delete:  createUrl('vendors'),
  changeStatus: createUrl('/vendors/change/status'),
};
