<mat-form-field class="search-mat-form-field">
  <input matInput placeholder="Search " (blur)="onBlurSelectionInput()" (focus)="onFocusSelectionInput()" (keydown.enter)="$event.preventDefault()"[(ngModel)]="selectedSearchResult" [matAutocomplete]="auto"
         [formControl]="searchCtrl" placeholder="{{searchPlaceHolder}}">
  <button *ngIf="selectedSearchResult" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onSelected()"
                     [displayWith]="displayWith">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let item of filteredResult" [value]="item">{{item[labelKey]}} </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
<br>
<ng-template #elseTemplate>
  <div class="movie-details" *ngIf="selectedSearchResult?.Title">
    <div>
      <img [src]="selectedSearchResult?.Poster" style="height: 250px;" />
    </div>
    <div>
      <h3>Selected Value: {{selectedSearchResult?.Title}}</h3>
      <ul>
        <li>Year: {{selectedSearchResult?.Year}}</li>
      </ul>
    </div>
  </div>
</ng-template>
