import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const ContactTypeApiResources = {
  list:  createUrl('contact-types'),
  create:  createUrl('contact-types/create'),
  detail:  createUrl('contact-types'),
  update:  createUrl('contact-types'),
  delete:  createUrl('contact-types'),
};
