<!-- <mat-select formControlName="state_id" class="form-control no-boarder-field" (selectionChange)="onChangeState($event)" placeholder="{{placeholder}}">
  <mat-option [value]="state.id" *ngFor="let state of sharedService.states">
    {{state.name}}
  </mat-option>
</mat-select>
 -->


<mat-form-field appearance="fill">
    <mat-label>Select State</mat-label>
    <mat-select formControlName="state_id" class="form-control no-boarder-field" (selectionChange)="onChangeState($event)" placeholder="{{placeholder}}">
        <mat-option [value]="state.id" *ngFor="let state of sharedService.states">
          {{state.name}}
        </mat-option>
      </mat-select>
  </mat-form-field>