<div class="user-search-wrapper">

  <div class="search-ui">
    <div class="search-ui-container">
      <div class="search-ui-inner-container">
        <div class="search-ui-items w-100">
          <input type="text" class="search-ui-item w-100" placeholder="Press enter to search" style="width:100%  !important" (change)="getUsersList($event)">
        </div>
      </div>
    </div>
  </div>
  <div class="tabs-ui">
    <div class="tabs-ui-contents">
      <div class="tabs-ui-content tabs-ui-content-active">
        <div class="ui-items">
          <ng-container *ngFor="let user of usersList">
            <div class="ui-item-box" (click)="close({type:type,user:user,index:index})">
              <div class="ui-selector-item">
                <div class="ui-item-avatar"></div>
                <div class="ui-item-titles">
                  <div class="item-title-box">
                    <div class="item-title">{{user.full_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
















  <!--<mat-tab-group (selectedIndexChange)="getActiveTab($event)">
    <mat-tab label="Users">
      <div class="search-ui">
        <div class="search-ui-container">
          <div class="search-ui-inner-container">
            <div class="search-ui-items w-100">
              <input type="text" class="search-ui-item w-100" placeholder="Press enter to search" style="width:100%  !important" (change)="getUsersList($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-ui">
        <div class="tabs-ui-contents">
          <div class="tabs-ui-content tabs-ui-content-active">
            <div class="ui-items">
              <ng-container *ngFor="let user of usersList">
                <div class="ui-item-box" (click)="close({type:type,user:user,index:index,selectedTab:getTabNameUsingTabIndex(selectedIndex)})">
                  <div class="ui-selector-item">
                    <div class="ui-item-avatar"></div>
                    <div class="ui-item-titles">
                      <div class="item-title-box">
                        <div class="item-title">{{user.full_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Vendors">
      <div class="search-ui">
        <div class="search-ui-container">
          <div class="search-ui-inner-container">
            <div class="search-ui-items w-100">
              <input type="text" class="search-ui-item w-100" placeholder="Press enter to search" style="width:100%  !important" (change)="getVendorsList($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-ui">
        <div class="tabs-ui-contents">
          <div class="tabs-ui-content tabs-ui-content-active">
            <div class="ui-items">
              <ng-container *ngFor="let user of vendorsList">
                <div class="ui-item-box" (click)="close({type:type,user:user,index:index,selectedTab:getTabNameUsingTabIndex(selectedIndex)})">
                  <div class="ui-selector-item">
                    <div class="ui-item-avatar"></div>
                    <div class="ui-item-titles">
                      <div class="item-title-box">
                        <div class="item-title">{{user.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Customers">
      <div class="search-ui">
        <div class="search-ui-container">
          <div class="search-ui-inner-container">
            <div class="search-ui-items w-100">
              <input type="text" class="search-ui-item w-100" placeholder="Press enter to search" style="width:100%  !important" (change)="getCustomersList($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-ui">
        <div class="tabs-ui-contents">
          <div class="tabs-ui-content tabs-ui-content-active">
            <div class="ui-items">
              <ng-container *ngFor="let user of customersList">
                <div class="ui-item-box" (click)="close({type:type,user:user,index:index,selectedTab:getTabNameUsingTabIndex(selectedIndex)})">
                  <div class="ui-selector-item">
                    <div class="ui-item-avatar"></div>
                    <div class="ui-item-titles">
                      <div class="item-title-box">
                        <div class="item-title">{{user.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Subcontractors">
      <div class="search-ui">
        <div class="search-ui-container">
          <div class="search-ui-inner-container">
            <div class="search-ui-items w-100">
              <input type="text" class="search-ui-item w-100" placeholder="Press enter to search" style="width:100%  !important" (change)="getSubcontractorsList($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-ui">
        <div class="tabs-ui-contents">
          <div class="tabs-ui-content tabs-ui-content-active">
            <div class="ui-items">
              <ng-container *ngFor="let user of subcontractorsList">
                <div class="ui-item-box" (click)="close({type:type,user:user,index:index,selectedTab:getTabNameUsingTabIndex(selectedIndex)})">
                  <div class="ui-selector-item">
                    <div class="ui-item-avatar"></div>
                    <div class="ui-item-titles">
                      <div class="item-title-box">
                        <div class="item-title">{{user.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>-->
</div>
