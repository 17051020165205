import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FileService {

  public FILE_TYPE_IMG = 'image';
  public FILE_TYPE_PDF = 'pdf';
  public FILE_TYPE_WORD = 'word';
  public FILE_TYPE_EXCEL = 'excel';
  public FILE_TYPE_CSV = 'csv';
  public FILE_TYPE_VIDEO = 'video';
  public FILE_TYPE_PPT = 'ppt';
  public FILE_TYPE_NO_VIDEO = 'withoutVideo';
  public FILE_TYPE_ALL = 'all';

  //private  message = 'only gif | jpg | png | jpeg | pdf | docx | csv | xls | ppt files are allowed. Max file size 2MB.'

  bytesToSize = (file: any) => { //function to format bites bit.ly/19yoIPO
    var bytes = parseInt(file.size);
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0)
      return '0 Bytes';
    var i = Math.floor( Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
  }

  getFileType = (type: any) => {
    switch (type) {
      case 'image/png'  :
      case 'image/gif' :
      case 'image/jpeg' :
      case 'image/pjpeg' :
        return 'image';
        break;
      case 'application/pdf':
        return 'pdf';
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'word';
        break;
      case 'text/csv':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'csv';
        break;
      case 'video/mp4':
      case 'video/webm':
      case 'application/octet-stream':
        return 'video';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'ppt';
        break;
      default:
        return false;
    }
  }
}
