import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders} from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import {map, catchError, finalize, tap, last} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import {Router} from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  constructor(
    private http: HttpClient,
    private router: Router,

  ) {
  }

  postApi(url: string, data: any, loading= true) {
    if(!data){
      data = [];
    }
    data.loadingService = loading;
    return this.http.post(this.createUrl(url) , data)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.errorHandlerMessage),
        finalize(() => {

        })
      );
  }
  deleteApi(url: string, params: any = []) {
    return this.http.delete(this.createUrl(url),{
      params
    })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.errorHandlerMessage),
        finalize(() => {

        })
      );
  }
  getApi(url: string, params: any = [],  loading= true) {
    params.loadingService = loading;
    return this.http.get(this.createUrl(url),{
      params
    })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.errorHandlerMessage),
        finalize(() => {

        })
      );
  }
  patchApi(url: string, data: any, loading= true) {
    if(!data){
      data = [];
    }
    data.loadingService = loading;
    return this.http.patch(this.createUrl(url) , data)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.errorHandlerMessage),
        finalize(() => {

        })
      );
  }
  createUrl(actionName: string): string {
    return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return observableThrowError(errMessage);
      // Use the following instead if using lite-server
      // return Observable.throw(err.text() || 'backend server error');
    }
    return observableThrowError(error || 'Node.js server error');
  }
  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }


}
