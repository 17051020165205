import {Directive, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[formControlName][ZipcodeMask]'
})
export class ZipcodeMaskDirective {

  constructor(public ngcontrol: NgControl) {
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: KeyboardEvent) {
    return this.omit_special_char(event);

  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: KeyboardEvent) {
    return this.omit_special_char(event);
  }

  @HostListener('keypress', ['$event'])
  keypress(event: KeyboardEvent) {
    return this.omit_special_char(event);
  }
  @HostListener('input', ['$event'])
  @HostListener('paste', ['$event'])
  onInput(event: KeyboardEvent | ClipboardEvent): void {


    this.omit_special_char(event);
  }


  omit_special_char(event: KeyboardEvent | ClipboardEvent): boolean {
    console.log('zip --');
    const inputElement = event.target as HTMLInputElement;

    if (event instanceof ClipboardEvent) {
      const clipboardData = event.clipboardData;
      const pastedText = clipboardData?.getData('text');
      const isNumeric = /^\d+$/.test(pastedText || '');
      if (!isNumeric) {
        event.preventDefault();
        return false;
      }
      return true;
    } else if (event instanceof KeyboardEvent) {
      const inputValue = inputElement.value;
      if (inputValue.length >= 10 && event.keyCode !== 8) {
        event.preventDefault();
        return false;
      }

      const k = event.keyCode || event.which;
      return (k >= 48 && k <= 57) || k == 8 || k == 32 || k == 45;
    }
    return true;
  }



}


