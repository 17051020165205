import {AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {first} from "rxjs/operators";
import {SharedDataService} from '../shared.data.service';
import {StateComponent} from "../state/state.component";
import {SharedService} from "../shared.service";

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit, AfterViewInit, AfterContentInit {

  @Input() fromGroup : any;
  @Input() field : any;
  @Input() placeholder : any;
  @Input() stateEnable = true;
  @Input() isEdit = false;
  @Input() selectedID = null;


  constructor(
    private sharedDataService: SharedDataService,
    public sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.sharedService.getCountries();
  }

  onChangeCountry = (event: any) => {
    this.fromGroup.get(this.field).setValue(event.value);
    if(this.stateEnable) {
      this.sharedService.getStates(event.value)
    }
  }

  ngAfterViewInit() {
    /*if (this.isEdit) {
      console.log(this.selectedID);
      this.fromGroup.get(this.field).setValue(this.selectedID);
    }*/
  }

  ngAfterContentInit() {
    /*if (this.isEdit) {
      console.log(this.selectedID);
      this.fromGroup.get(this.field).setValue(this.selectedID);
    }*/
  }

}
