import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  public title: string = 'Confirm Action';
  public message: string = 'Are you sure you want to delete this?';
  public description: string = 'This cannot be undone';
  model: any;

  @Output() callback = new EventEmitter<any>(true);

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationPopupComponent
  ) {
      this.title = (data.title) ? data.title : this.title;
      this.message = (data.message) ? data.message : this.message;
      this.description = (data.description) ? data.description : this.description;
      this.model = data['model'] || {};
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
  close = (data: any = null) => {
    this.dialogRef.close(data);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
